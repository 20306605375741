import { Button, Typography, MorganTheme, useTheme } from '@mui/material';
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import JsonPrizes from "../Assets/prizes.json";
import axiosCall from "../Services/axios";
import {
  ColorButton,
  ColorSelection,
  OptionButton,
  QuantityButton,
  SlotMachineContainer,
  SlotMachineBody,
  PlayAndExit,
  MessageDisplay,
  MessageDisplayHolder
} from "../Styles/SlotMachineStyle";
import { setUserPlayedToday } from "@storage/userStorage";
import { sendWorkflow } from "@common/WorkflowsUtils";
import SlotMachineNoPrizeDisplay from '@components/SlotMachineNoPrizeDisplay';
import { startConfettiInner, stopConfettiInner } from '@common/confetti';
import { IErrand } from '@interfaces/Conversation';
import useControllerCallback from '@common/hooks/useControllerCallback';
import { AccessType } from '@common/AccessType';

const selectedButtonColor = "#f5bb84";
const defaultOrangeColor = "#ffdebf";

const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';
const CoffeeGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Coffee.gif';
const CapGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Cap.gif';
const ShirtOneGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/ShirtOne.gif';
const ShirtTwoGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/ShirtTwo.gif';
const GiftBoxGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/GiftBox.gif';
const LeverGifSrc = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Lever.gif';
const LeverSVG = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Lever.svg';
const soundEffect = process.env.REACT_APP_MORGAN_CDN + '/sound/slotMachineWin.wav';

// Map for easy selectino of gif source
const PrizeGifs = {
  "No Prize": null,
  Coffee: CoffeeGif,
  "T-Shirt": ShirtOneGif,
  "Polo Shirt": ShirtTwoGif,
  Cap: CapGif,
  "Gift Box": GiftBoxGif,
};

interface SlotMachineProps {
  errand?: IErrand;
  chatId?: string;
  userActionId?: string;
  userId?: string;
  exitSlotMachine?: (chatId: string, userAction: string, resolved: boolean) => void;
}

// Required props: exitSlotMachine function so that SlotMachine can exit itself
const SlotMachine = (props: SlotMachineProps) => {
  const { t } = useTranslation();
  const theme: MorganTheme = useTheme();
  // User chosen item quantity
  const [itemQuantity, setItemQuantity] = useState(1);
  // Control for size option choice
  const [selectedSize, setSelectedSize] = useState("S");
  // Control for color option choice
  const [selectedColor, setSelectedColor] = useState("Blue");
  // Gif of prize that plays upon win
  const [prizeGif, setPrizeGif] = useState("");
  // Boolean for triggering gif displaying lever pull
  const [playLeverGif, setPlayLeverGif] = useState(false);
  // Prize information, set when game is won
  const [prize, setPrize] = useState({ name: "", type: "", sizeSelection: true, colorSelection: true });
  // Variable governing game state, 0 = to start, 1 = playing, 2 = ended
  const [playState, setPlayState] = useState(0);
  // Message to be displayed for prizes that do not allow size or color selections
  const [optionBlockingMessage, setOptionBlockingMessage] = useState(`${t("optionsAfterPrize")}`);
  // Boolean governing whether or not to block options
  const [blockOptions, setBlockOptions] = useState(false);
  // Text on play button
  const [playButtonText, setPlayButtonText] = useState("playButton");
  // Array of prizes used for picking prize
  const [prizeArray, setPrizeArray] = useState([]);
  // Integer tracking which quantity/prize/color slot machine is on
  const [choiceStep, setChoiceStep] = useState(0);
  // Boolean to trigger useEffect to regenerate prize
  const [retry, setRetry] = useState(true);

  // On load, load the prize rates from db
  const loadAndSetPrizes = useCallback( async (abortController: AbortController) => {
    //Retrieve current prize chances from database
    async function setPrizes() {
      const loadPrizeRateData = async () => {
        const lenderName = theme.system.name;
        try {
          const result = await axiosCall({
            url: `system/?lender=${lenderName}`,
            method: 'GET',
            data: null
          },
          { 
            signal: abortController.signal
          });
          if (result) {
            console.log("System Settings Fetched. Results Below...", result);
            let prizeRates = result?.prizeRates;
            // Grab and convert default prize data and incoporate it into the db prize rate data
            JsonPrizes.forEach(function (item) {
              item.rate = prizeRates[prizeRates.map(r => r.name).indexOf(item.name)].rate || 0;
              item.gif = PrizeGifs[item.name];
            });
            setPrizeArray(JsonPrizes);
          }
        } catch (error) {
          console.log("Error retrieving Prize Data", error);
        }
      };
      await loadPrizeRateData();
    }
    setPrizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry]);

  useControllerCallback(loadAndSetPrizes)

  const restart = () => {
    // User chosen item quantity
    setItemQuantity(1);
    // Control for size option choice
    setSelectedSize("S");
    // Control for color option choice
    setSelectedColor("Blue");
    // Gif of prize that plays upon win
    setPrizeGif("");
    // Boolean for triggering gif displaying lever pull
    setPlayLeverGif(false);
    // Variable governing game state, 0 = to start, 1 = playing, 2 = ended
    setPlayState(0);
    // Message to be displayed for prizes that do not allow size or color selections
    setOptionBlockingMessage(`${t("optionsAfterPrize")}`);
    // Boolean governing whether or not to block options
    setBlockOptions(false);
    // Text on play button
    setPlayButtonText("playButton");
    // Array of prizes used for picking prize
    setPrizeArray([]);
    // Integer tracking which quantity/prize/color slot machine is on
    setChoiceStep(0);
    // Boolean to trigger useEffect to regenerate prize
    setRetry((prev) => !prev);
  }

  // Function for generating and choosing prize for user
  const SpinForPrize = () => {
    // Get sum of rates
    const totalWeight = prizeArray.reduce(function (sum, current) {
      return sum + current["rate"];
    }, 0);
    // Scale to ensure randomNum covers entire range
    const randomNum = totalWeight * Math.random();
    let prizeIndex = 0;
    let currPercentageSum = prizeArray[prizeIndex]["rate"];
    // Starting at index 0
    // Sum the chances of each prize and compare to randomNum
    // This is a shortcut to creating a range for each Prize
    while (randomNum > currPercentageSum) {
      prizeIndex = prizeIndex + 1;
      currPercentageSum = currPercentageSum + prizeArray[prizeIndex]["rate"];
    }
    let prizeResult = prizeArray[prizeIndex]
    console.log("Winning Prize: ", prizeResult);
    return prizeResult;
  };
  // Function which starts the game
  const triggerGame = async () => {
    const prizeGifDuration = 5500;
    const unblockOptionsDuration = 1000;
    const tempPrize = SpinForPrize();

    // Immediately set slot machine user action to resolved to prevent users from quickly
    // exiting to play again
    setSlotMachineToResolved();
    updateSlotMachineMessage(tempPrize?.name !== 'No Prize', tempPrize?.name);

    setPrize(tempPrize);
    setPlayLeverGif(true);
    // Default Size and color
    setSelectedSize('M');
    setSelectedColor('Blue');
    // Way to get the gif to replay if SlotMachine is shown more than once without a page reload
    // Without appending the unique string, the gif renders on its final frame after the first render
    setPrizeGif(tempPrize["gif"] + "?a=" + Date.now());
    setPlayState(1);
    // Wait for the lever and play gif to play
    setTimeout(() => {
      if (tempPrize["name"] === "No Prize") {
        setOptionBlockingMessage(t("unluckyText"));
        setPlayButtonText("exitButton");
        setPlayState(2);
      } else {
        new Audio(soundEffect).play();
        startConfettiInner();
        setTimeout(() => {
          stopConfettiInner();
        }, 2000)
        setBlockOptions(true);
        setOptionBlockingMessage(`${t("prizeMessage")} ${tempPrize["name"]}!`);
        // Wait for the unblock options to go away
        setTimeout(() => {
          setBlockOptions(false);
          setPlayButtonText("confirmButton");
          setPlayState(2);
          if (tempPrize.quantitySelection) {
            setChoiceStep(1);
          } else if (!tempPrize.quantitySelection && tempPrize.sizeSelection) {
            setChoiceStep(2);
          } else if (!tempPrize.quantitySelection && !tempPrize.sizeSelection && tempPrize.colorSelection) {
            setChoiceStep(3);
          }
        }, unblockOptionsDuration);
      }
    }, prizeGifDuration);
  };

  const handlePlusButtonClick = () => {
    if (itemQuantity === prize['maxQuantity']) {
      return;
    }
    let newQuantity = itemQuantity + 1
    setItemQuantity(newQuantity);
  };

  const handleMinusButtonClick = () => {
    if (itemQuantity === 1) {
      return;
    }
    let newQuantity = itemQuantity - 1
    setItemQuantity(newQuantity);
  };

  const handleMinButtonClick = () => {
    setItemQuantity(1);
  };

  const handleMaxButtonClick = () => {
    setItemQuantity(prize["maxQuantity"]);
  };

  const handleSizeButtonClick = (event) => {
    const sizeButtonValue = event.currentTarget.value;
    setSelectedSize(sizeButtonValue);
  };

  const handleColorButtonClick = (event) => {
    const colorButtonValue = event.currentTarget.value;
    setSelectedColor(colorButtonValue);
  };

  // Function for displaying message text within slot machine panel inside JSX
  const displayMessage = () => {
    if (choiceStep === 4) {
      return t('slotMachineUnavailable');
    }
    if (playState === 0 || playState === 1) {
      return t('slotMachineThrill');
    } else if (prize.name === 'No Prize') {
      return t('slotMachineNoPrize');
    } else {
      return t('slotMachinePrize').replace(/prize/, prize.name);
    }
  }

  // Switch component for conditionally rendering each choice panel
  const renderChoiceComponent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <MessageDisplayHolder>
            <MessageDisplay>
              <Typography>
                {displayMessage()}
              </Typography>
            </MessageDisplay>
          </MessageDisplayHolder>
        );
      case 1:
        // Quantity Selection
        return (
          <div className='quantityButtonGrid'>
            <OptionButton onClick={handleMinButtonClick} limitQuantity={itemQuantity === 1}>
              <span className='minButton' data-yeah='ok'>
                <Typography>Min</Typography>
              </span>
            </OptionButton>
            <div className='countSelector'>
              <span className='countSelectorFlex'>
                <QuantityButton onClick={handleMinusButtonClick}>-</QuantityButton>
                <span>
                  <Typography>{itemQuantity}</Typography>
                </span>
                <QuantityButton onClick={handlePlusButtonClick}>+</QuantityButton>
              </span>
            </div>
            <OptionButton onClick={handleMaxButtonClick} limitQuantity={itemQuantity === prize['maxQuantity']}>
              <span className='maxButton'>
                <Typography>Max</Typography>
              </span>
            </OptionButton>
          </div>
        );
      case 2:
        // Size Selection
        return (
          <div className='sizeSelection'>
            <OptionButton
              value="S"
              onClick={handleSizeButtonClick}
              style={{ backgroundColor: "S" === selectedSize ? selectedButtonColor : defaultOrangeColor }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>S</Typography>
              </span>
            </OptionButton>
            <OptionButton
              value="M"
              onClick={handleSizeButtonClick}
              style={{ backgroundColor: "M" === selectedSize ? selectedButtonColor : defaultOrangeColor }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>M</Typography>
              </span>
            </OptionButton>
            <OptionButton
              value="L"
              onClick={handleSizeButtonClick}
              style={{ backgroundColor: "L" === selectedSize ? selectedButtonColor : defaultOrangeColor }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>L</Typography>
              </span>
            </OptionButton>
            <OptionButton
              value="XL"
              onClick={handleSizeButtonClick}
              style={{ backgroundColor: "XL" === selectedSize ? selectedButtonColor : defaultOrangeColor }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>XL</Typography>
              </span>
            </OptionButton>
          </div>
        );
      case 3:
        // Color selection
        return (
          <ColorSelection>

            <ColorButton onClick={handleColorButtonClick} value="Blue" style={{ backgroundColor: "#263472" }}>
              {selectedColor === "Blue" && (
                <img
                  className='colorButton'
                  src={AngelAi}
                  alt='Morgan Logo'
                ></img>
              )}
              <div className='innerColorButton'></div>
            </ColorButton>
            <ColorButton onClick={handleColorButtonClick} value="Black" style={{ backgroundColor: "#000000" }}>
              {selectedColor === "Black" && (
                <img
                  className='colorButton'
                  src={AngelAi}
                  alt='Morgan Logo'
                ></img>
              )}
              <div className='innerColorButton'></div>
            </ColorButton>
            <ColorButton onClick={handleColorButtonClick} value="Red" style={{ backgroundColor: "#F84A25" }}>
              {selectedColor === "Red" && (
                <img
                  className='colorButton'
                  src={AngelAi}
                  alt='Morgan Logo'
                ></img>
              )}
              <div className='innerColorButton'></div>
            </ColorButton>
            <ColorButton onClick={handleColorButtonClick} value="White" style={{ backgroundColor: "#FFFFFF" }}>
              {selectedColor === "White" && (
                <img
                  className='colorButton'
                  src={AngelAi}
                  alt='Morgan Logo'
                ></img>
              )}
              <div className='innerColorButton'></div>
            </ColorButton>
          </ColorSelection>
        );
      default:
        return (
          <MessageDisplayHolder>
            <MessageDisplay>
              <Typography>
                {displayMessage()}
              </Typography>
            </MessageDisplay>
          </MessageDisplayHolder>
        );
    }
  }

  const handleNextButton = () => {
    switch (choiceStep) {
      case 0:
        return handlePlayButtonClick();
      case 1:
        if (prize.sizeSelection) {
          setChoiceStep(2);
        } else if (!prize.sizeSelection && prize.colorSelection) {
          setChoiceStep(3);
        } else if (!prize.sizeSelection && !prize.colorSelection) {
          handlePlayButtonClick();
        }
        return;
      case 2:
        if (prize.colorSelection) {
          setChoiceStep(3);
        } else {
          handlePlayButtonClick();
        }
        return;
      case 4:
        return restart();
      default:
        return handlePlayButtonClick();
    }
  }

  const createProductOrder = async () => {
    try {
      // Find the product that was won, with the chosen options
      const productId = await axiosCall({
        url: "product/db/find",
        method: "POST",
        data: {
          type: prize.type,
          unitSize: prize.sizeSelection ? selectedSize : "",
          unitColor: prize.colorSelection ? selectedColor : ""
        }
      });

      if (!productId.length) {
        return console.log('Could not create an order. Could not find a valid product');
      }
      // Create a new order with the found product, to be updated with the workflow in chat
      let order = await axiosCall({
        url: 'order',
        method: "POST",
        data: {
          customerId: props.userId,
          productId: productId[0]._id,
          chat: props.chatId,
          size: prize.sizeSelection ? selectedSize : '',
          color: prize.colorSelection ? selectedColor : '',
          quantity: itemQuantity,
          shippingAddressLine1: '',
          shippingAddressLine2: '',
          shippingCity: '',
          shippingState: '',
          shippingZip: '',
          status: 'open',
          trackingNumber: '',
          carrier: '',
          active: false
        }
      });
      return order;
    } catch (e) {
      console.error(`Error creating order: ${e}`);
      return false;
    }
  }

  async function createOrderAndBeginWorkflow() {
    if (prize.name === 'No Prize') {
      return props.exitSlotMachine(props.chatId, props.userActionId, true);
    } else {
      if (props.chatId && props.chatId !== '') {
        let recipients = [props.userId];
        let createdOrder = await createProductOrder();
        if (createdOrder) {
          props.exitSlotMachine(props.chatId, props.userActionId, true);
          await sendWorkflow('', "Create Slot Machine Order", props.chatId, recipients, AccessType.public, props.userId, false, false);
        } else {
          setChoiceStep(4);
          setPlayButtonText('tRestart');
        }
      } else {
        console.log(`No conversation selected. Cannot send workflow. chat: ${props.chatId}`);
      }
    }
  }

  const setSlotMachineToResolved = () => {
    // sets the corresponding slot machine useraction status to resolved
    const payload = {
      url: `useraction/${props.userActionId}`,
      method: 'put',
      data: {
        status: 'resolved'
      },
    };
    axiosCall(payload);
  };

  const updateSlotMachineMessage = (win: boolean, prize: string) => {
    // Because dynamic message needs, we hit the DB to change the message text.
    const payload = {
      url: `slotmachine/update?chat=${props.chatId}&userAction=${props.userActionId}`,
      method: 'put',
      data: {
        win: win,
        prize: prize
      }
    };
    axiosCall(payload);
  }

  const handlePlayButtonClick = async () => {
    if (prizeArray.length === 0) {
      return;
    }
    const tempPlayState = playState;
    // Game has not started
    if (tempPlayState === 0) {
      setUserPlayedToday();
      triggerGame();
      setPlayState(1);
    } // Game is in Progress
    else if (tempPlayState === 1) {
      return;
    } // Game is finished
    else if (tempPlayState === 2) {
      await createOrderAndBeginWorkflow();
    }
  };

  return (
    <SlotMachineContainer playState={playState}>
      <div className="slotMachineShadow"></div>
      <div className="slotMachine">
        <div className="slotMachineWindow">
          {/* Lever svg and gif, when game is active, svg is swapped out for animated gif */}
          {playLeverGif ?
            (<img className="activeLever" src={LeverGifSrc} alt='Lever Gif'></img>)
            :
            (<img
              className="staticLever"
              onClick={handlePlayButtonClick}
              src={LeverSVG}
              alt="Lever"
            ></img>)}
          {/* Component responsible for the animation */}
          <div className='slotMachineAnimationHolder'>
            {playState > 0 ?
              prize.name === 'No Prize' ?
                <SlotMachineNoPrizeDisplay />
                :
                <img src={prizeGif} alt="prize Gif" />
              :
              <div className='slotMachineSpotlight' />
            }
          </div>
          <div className="slotMachineScreenBackground">
          </div>
          <SlotMachineBody>
            <div className="bodyDropShadowBorder">
              <div className="bodyBackground">
                {/* Option blocking box with text */}
                {blockOptions && (
                  <div className='blockButtonBox'>
                    <Typography className='blockMessage'>{t(`${optionBlockingMessage}`)}</Typography>
                  </div>
                )}
                {renderChoiceComponent(choiceStep)}
                {/* Play and Exit Buttons */}
                <PlayAndExit>
                  {
                    choiceStep > 1 ?
                      <div className='exitBackground' >
                        <Button
                          className='exitButton'
                          onClick={() => setChoiceStep(choiceStep - 1)}
                        >
                          <Typography style={{ color: "#ffffff", fontWeight: "bold" }}>{t("goBack")}</Typography>
                        </Button>
                      </div>
                      :
                      playState === 0 ?
                        <div className='exitBackground' >
                          <Button
                            className='exitButton'
                            onClick={() => props.exitSlotMachine(props.chatId, props.userActionId, false)}
                          >
                            <Typography style={{ color: "#ffffff", fontWeight: "bold" }}>{t("exitButton")}</Typography>
                          </Button>
                        </div>
                        :
                        null
                  }
                  <div className='playBackground'>
                    <Button
                      onClick={handleNextButton}
                      className='playButton'
                    >
                      <Typography style={{ color: "#000000", fontWeight: "bold" }}>{t(playButtonText)}</Typography>
                    </Button>
                  </div>
                </PlayAndExit>
              </div>
            </div>
          </SlotMachineBody>
        </div>
      </div>
    </SlotMachineContainer>
  );
};

export default SlotMachine;
