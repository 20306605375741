import { Stack } from '@mui/material';
import React, { useCallback, useRef, useState, PropsWithChildren } from 'react';
import ErrorBoundary from '@components/ErrorBoundary';
import ReactPlayer from 'react-player/youtube';
import styles from '@styles/SongOfTheDay.module.css';
import { Box } from '@mui/system';
import useInitialMount from '@common/hooks/useInitialMount';
import useDebounce from '@common/hooks/useDebounce';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';

const SongPlayerViewBox: React.FC<PropsWithChildren<any>> = (props) => {
    const errandContext = useErrandContext();
    // For tracking YouTube time within our state
    const [playerTime, setPlayerTime] = useState(0);
    // Bool ref (to prevent rerenders) for preventing progress bar resets when seeking
    const seekingRef = useRef(false);
    // Variable to monitoring if the player is ready to render the progress bar
    const [playerIsReady, setPlayerIsReady] = useState(false);
    // Variable to prevent youtube window from mounting immediately, to smooth out css transition
    const [viewBoxReady, setViewBoxReady] = useState(false);
    // Full time of a YouTube video
    const playerDuration = useRef(0);
    // Options object passed into react-youtube Component
    const miniPlayerOptions = {
        height: '200px',
        width: '200px',
        borderRadius: '300px',
        frameBorder: '0',
        allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
        allowFullScreen: 'allowFullScreen',
        mozallowfullscreen: 'mozallowfullscreen',
        msallowfullscreen: 'msallowfullscreen',
        oallowfullscreen: 'oallowfullscreen',
        webkitallowfullscreen: 'webkitallowfullscreen',
    };

    const closePlayer = (e) => {
        errandContext.setSongPlayerData((prev) => { return { ...prev, closing: true } });
        setPlayerIsReady(false);
    }

    useDebounce(() => {
        if (errandContext.songPlayerData?.closing) {
            setPlayerIsReady(false);
            errandContext.setSongPlayerData({
                id: '',
                artist: '',
                title: '',
                thumbnail: '',
                url: '',
                pause: false,
                mute: false,
                show: false,
                closing: false,
            });
            errandContext.setMorphType((prev) => {
              if (prev === MorphType.SongOfTheDay) {
                // if current MorphType is SongOfTheDay then close it
                return MorphType.None;
              } else {
                // if above 2 condition doesn't match no changes needed
                return prev;
              }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 225, [errandContext.songPlayerData?.closing]);

    const staggerState = useCallback(() => {
        const viewBoxTimeout = setTimeout(() => {
            setViewBoxReady(true);
        }, 300);

        const autoplayTimeout = setTimeout(() => {
            errandContext.setSongPlayerData((prev) => {
              return { ...prev, pause: false };
            });
        }, 500);

        return () => {
            clearTimeout(viewBoxTimeout);
            clearTimeout(autoplayTimeout);
        }
    }, []);

    useInitialMount(staggerState)

    return (
        <ErrorBoundary debug={`SongPlayerViewBox: ${JSON.stringify(props)}`}>
            <Stack
                className={`${styles.songPlayerViewBox} ${errandContext.songPlayerData?.closing ? styles.closeButtons : ''}`}
                style={{ justifyContent: 'column', alignItems: 'flex-end' }}
            >
                <Stack
                    className={`${styles.songPlayerViewBoxButtons} ${errandContext.songPlayerData?.closing ? styles.closeButtons : ''}`}
                >
                    <Stack className={styles.onOffButtonContainer}>
                        {errandContext.songPlayerData?.closing ?
                            <div className={styles.offButton}>
                                <span>
                                    Off
                                </span>
                            </div>
                            :
                            <div className={styles.onButton} onClick={closePlayer}>
                                <span>
                                    On
                                </span>
                            </div>
                        }
                    </Stack>
                </Stack>
                <Stack className={`${styles.viewBoxWrapper} ${errandContext.songPlayerData?.closing ? styles.closingSongPlayer : ''}`}>
                    {viewBoxReady &&
                        <ReactPlayer
                            ref={errandContext.playerRef}
                            height='calc(100% - 20px)'
                            width='calc(100% - 20px)'
                            onProgress={(e) => {
                                if (!seekingRef.current) {
                                    setPlayerTime(e.playedSeconds)
                                }
                            }}
                            onDuration={(e) => playerDuration.current = e}
                            progressInterval={500}
                            playing={!errandContext.songPlayerData?.pause}
                            muted={errandContext.songPlayerData?.mute}
                            playsinline={true}
                            id={`ytplayer-song-of-the-day`}
                            title={errandContext.songPlayerData?.title}
                            url={`https://www.youtube.com/watch?v=${errandContext.songPlayerData?.id}`}
                            style={miniPlayerOptions}
                            onReady={() => {
                                setPlayerIsReady(true);
                            }}
                            onPlay={() => {
                                errandContext.setSongPlayerData((prev) => {
                                  return { ...prev, pause: false };
                                });
                            }}
                            onPause={() => {
                                errandContext.setSongPlayerData((prev) => {
                                  return { ...prev, pause: true };
                                });
                            }}
                            onEnded={() => {
                                errandContext.setSongPlayerData((prev) => {
                                  return { ...prev, pause: true };
                                });
                            }}
                            onError={() => {
                                errandContext.setSongPlayerData((prev) => {
                                  return { ...prev, show: false };
                                });
                                errandContext.setMorphType((prev) => {
                                  if (prev === MorphType.SongOfTheDay) {
                                    // if current MorphType is SongOfTheDay then close it
                                    return MorphType.None;
                                  } else {
                                    // if above 2 condition doesn't match no changes needed
                                    return prev;
                                  }
                                });
                            }}
                            config={{
                                playerVars: {
                                    autoplay: 1,
                                    enablejsapi: 1,
                                    fs: 1,
                                    modestbranding: 1,
                                }
                            }}
                        />
                    }
                    {!playerIsReady && <div className={styles.loadingBackground} />}
                    <Stack className={styles.progressBar} height={errandContext.songPlayerData?.show ? '7px' : '0px'}>
                        <Box className={styles.progressBarPlayed}
                            height={errandContext.songPlayerData?.show ? '7px' : '0px'}
                            width={`${(playerTime / playerDuration.current) * 100}%`}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </ErrorBoundary>
    );
};

export default SongPlayerViewBox;
