import React from 'react';
import Styles from '../../Styles/FooterTypingAnimationWrapper.module.css';

const FooterTypingAnimationWrapper = (props) => {

  if (!props.isShown) return null;
  return (
    <div className={Styles.wrapper}>
      {props.children}
    </div>
  );
};

export { FooterTypingAnimationWrapper };
