import { Badge, Tooltip } from '@mui/material';
import { differenceInDays, format, isSameDay } from 'date-fns';
import { enUS, es, ko } from 'date-fns/locale';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Avatar from './Avatar';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '@components/ErrorBoundary';
import { TConversationBadgeProps } from '@mTypes/TConversationBadge';
import { Style } from '@styles/ConversationBadgeStyles';
import { handlePreviews } from '@common/loadPreview';

const PREVIEW_DEFAULT = '...';

/*
 *  This component makes up the individual conversation badges that are visible
 * in all tabs of the console conversations page.
 */

// define default parameters to prevent crashing without the need of optional operators
const ConversationBadge = ({
  name = '',
  status = 'offline',
  chatId = '',
  datetime = Date.now().toString(),
  notifCount = 0,
  img = '',
  preview = '',
  assistedBy = '',
  lastMessageData,
}: TConversationBadgeProps) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const smallBadge = location.state?.tab === 'live' || location.state?.tab === 'history';

  // calculate the timestamp based on i18n.resolvedLangauge and provided datetime
  const timeString = useMemo(() => {
    let days = differenceInDays(new Date(), new Date(datetime));
    let date = new Date(datetime);

    let locale;
    switch (i18n.resolvedLanguage) {
      case 'en':
        locale = enUS;
        break;
      case 'es':
        locale = es;
        break;
      case 'ko':
        locale = ko;
        break;
      default:
        if (!locale) locale = enUS;
        break;
    }
    try {
      if (differenceInDays(new Date(), date) > 7) {
        // Month day, time
        return format(date, 'LLL do, p', { locale: locale });
      } else if (days <= 7 && days > 1) {
        // day of week
        return format(date, 'E do, p', { locale: locale });
      } else if (days === 1 || !isSameDay(new Date(), date)) {
        return `${t('conversationBadgeYesterday')}, ${format(date, 'p', { locale: locale })}`;
      } else {
        return format(date, 'p', { locale: locale });
      }
    } catch (e) {
      console.warn('Error while calculating ConversationBadge timeString:', e);
      return '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datetime, i18n.resolvedLanguage]);

  /**
   * Operator side preview
   */
  const dispMessage = useMemo(() => {
    // 1: Show preview based on lastMessageData
     const showData = handlePreviews(lastMessageData) || PREVIEW_DEFAULT;

     if (showData !== PREVIEW_DEFAULT) {
      return showData;
     }

    // 2: chat preview field as a backup
    return preview || PREVIEW_DEFAULT;
  }, [lastMessageData, preview]);

  return (
    <ErrorBoundary debug={`ConversationBadge: ${JSON.stringify({name, status, chatId, datetime, notifCount, img, preview, assistedBy})}`}>
    {/* divs now replace Stack and Box as they compile to divs without any unique functionality or styles, and are less performant */}
    <Style>
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ marginRight: notifCount > 9 ? 1 : undefined }}
        badgeContent={notifCount}
        color="error"
        max={9}>
        <Avatar
          status={status}
          alt={name}
          img={img}
          sx={{ width: '30px', height: '30px' }} />
      </Badge>
      <div className="CBChild">
        <div className="CBNameWrapper">
          <Tooltip title={name}>
            <strong>{name}</strong>
          </Tooltip>
          {/* show placeholder if timeString is not available */}
          <p>{(smallBadge ? '' : timeString) || ''}</p>
        </div>
        <div className="CBPreviewWrapper">
          {/* show placeholder if assistedBy or preview are not available */}
          <p>{(smallBadge ? assistedBy : dispMessage) || t('conversationListPlaceholderPreview')}</p>
        </div>
      </div>
    </Style>
    </ErrorBoundary>
  );
};

export default ConversationBadge;
