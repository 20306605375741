/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 *
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 *
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphTimeSkeleton.module.css';
import useLazyComponent from '@common/hooks/useLazyComponent';

const MorphTimeFallback = () => {
  return(
    <div className={Skeleton.loader + ' ' + Styles.style} />
  )
};

// The render component below lazy loads the corresponding component using the skeleton above


const MorphTimeSkeleton = () => {
  const MorphTimeSkeletonProps = {};

  const MorphTime = useLazyComponent(
    import('@components/MorphTime').then(module => ({ default: module.MorphTime })),
    <MorphTimeFallback />,
    `MorphTime: ${JSON.stringify(MorphTimeSkeletonProps)}`
  );

  return(
    <MorphTime {...MorphTimeSkeletonProps} />
  );
};

export default MorphTimeSkeleton;
