import { Fade, Stack } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import Sanitized from '@components/Sanitized';
import MessageTextStyle from '@styles/MessageTextStyle';
import OriginalLangStyle from '@styles/OriginalLangStyle';
import TranslationInfoStyle from '@styles/TranslationInfoStyle';
import TranslationTabLeft from '@styles/TranslationTabLeft';
import TranslationTabRight from '@styles/TranslationTabRight';
import { useTranslation } from 'react-i18next';
import { useRootContext } from '@contexts/RootContext';
import { uiTranslationController, getLangName } from '@common/common';
import { IErrand, IMessage } from '@interfaces/Conversation';
import Allign from '@styles/Allign';
import MessageTime from '@components/MessageTime';
import { useNavigate } from 'react-router-dom';
import { useMessageContext } from '@contexts/MessageContext';
import useAbortController from '@common/hooks/useAbortController';
import axiosCall from '@services/axios';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { AccessType } from '@common/AccessType';
import { sendWorkflow } from '@common/WorkflowsUtils';

type TTextMessageContentProps = {
  errand: IErrand;
  message: IMessage;
}

const TextMessageContent = ({
  errand, message, 
}: TTextMessageContentProps) => {
  // hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const messageContext = useMessageContext();
  const abortController = useAbortController();
  const rootContext = useRootContext();
  const errandContext = useErrandContext();

  // useStates
  const [isFading, setIsFading] = useState(true);

  // useRefs
  const messageRef = useRef(null);
  const originalLanguageButtonDisabled = useRef(false);
  const latchedShowMessageRef = useRef(undefined);

  // following runs at every render
  const isEditing = messageContext?.editMessageId === message?._id;
  const { dispMessage, isTranslated } = uiTranslationController(message);

  const handleAddParticipant = useCallback(async (chatId) => {
    try {
      // setup axios call with controller
      const request = {
        url: `chat/${chatId}/participant`,
        method: 'post',
        data: {
          userId: message.userId,
          userType: message.operatorView ? 'Operator' : 'User'
        }
      };
      const controller = abortController.reset();
      const config = { signal: controller.signal };
      await axiosCall(request, config);

      if (message.operatorView) {
        navigate('/console', {
          state: {
            module: 'conversations',
            tab: 'mine',
            chat: chatId,
          },
        });
      }
    } catch (error) {
      console.error(`MorphMessageOptionJoin.handleClickJoin`, error);
    }
  }, [message?.userId, message?.operatorView]);

  const handleClickJoin = useCallback(async (event) => {
    try {
      const chatId = (message.message.split('data-chat-id="')[1] || '').split('"')[0];

      if (message.operatorView) {
        handleAddParticipant(chatId);
        return;
      }

      rootContext.setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        const errandIndex = prev.findIndex((e) => e._id === chatId);
        if (errandIndex === -1 || typeof rootContext.setSelectedIndex !== 'function') {
          handleAddParticipant(chatId);
        } else {
          rootContext.setSelectedIndex((prev) => {
            if (!Array.isArray(prev) || prev?.length < 2) return [errandIndex];
            return [prev[0], errandIndex];
          });
        }
        return prev;
      });
    } catch (error) {
      console.error(`MorphMessageOptionJoin.handleClickJoin`, error);
    }
  }, [errand?._id, message?._id, message?.messageType, message?.message, handleAddParticipant]);

  const sendCalculatorWorkflow = async (calculatorName) => {
    return await sendWorkflow('', calculatorName, message.chat, message.intendedAudience, AccessType.public, message.userId, message.operatorView, false);
  }

  useEffect(() => {
    const handleLink = async (e) => {
      if (e.target.tagName === 'A') {
        e.preventDefault();
        e.stopPropagation();
        // List of allowed domains to display links for when sent within the chat.
        let regexList = ['angelai.com', 'swmc.com', 'celligence.com', 'angel.ai'];
        if (message.operatorView) return;
        let href = e.target.getAttribute('href');
        if (href === '#prompts') {
          errandContext.setMorphType(MorphType.UserPromptsMenu);
        } else if (href === '#training') {
          errandContext.setMorphType(MorphType.VideoListMenu);
        } else if (['#creditRepair', '#creditBoost'].includes(href)) {
          await sendWorkflow('', "Credit Boost", message.chat, message.intendedAudience, AccessType.public, message.userId, message.operatorView, true);
        } else if(href.toLowerCase().includes('calculator')) {
          const lowerCasedHref = href.toLowerCase();
          if(lowerCasedHref.includes('affordability')) {
            // call affordability calculator
            sendCalculatorWorkflow('Affordability Calculator');
          } else if(lowerCasedHref.includes('appraisal')) {
            // call appraisal calculator
            sendCalculatorWorkflow('Appraisal Calculator');
          } else if(lowerCasedHref.includes('fixedrate')) {
            // call fixed rate calculator
            sendCalculatorWorkflow('Fixed Rate Calculator');
          } else if(lowerCasedHref.includes('refinance')) {
            // call refinance calculator
            sendCalculatorWorkflow('Refinance Calculator');
          } else if(lowerCasedHref.includes('rentvsbuy')) {
            // call rent vs buy calculator
            sendCalculatorWorkflow('Rent Vs Buy Calculator');
          } else if(lowerCasedHref.includes('zestimate')) {
            // call zestimate calculator
            sendCalculatorWorkflow('Zestimate');
          }
        }
        // Match the href to determine if it matches http(s)://(www.) any of the domains above. If so, let the user click the link.
        else if (new RegExp('https?:\/\/(www\.)?(?:' + regexList.join('|') + ')').test(href)){
          messageContext.setPreviewUrl(href);
        }
      }
    };

    const msg = messageRef.current;
    msg.addEventListener('click', handleLink);
    return () => {
      msg.removeEventListener('click', handleLink);
    };
  }, []);

  /**
   * This function is a handler callback for the Original Language component's
   * onClick event. It is responsible for temporarily revealing the origianl
   * message.
   */
  const handleClickOriginalLang = async (e) => {
    e?.stopPropagation();

    if (!message.visible) return;

    // if original language button disabled ---> do nothing
    if (originalLanguageButtonDisabled.current) {
      return;
    }

    // disable original language button
    originalLanguageButtonDisabled.current = true;

    latchedShowMessageRef.current = dispMessage;

    // Start the inital fadeout:
    setIsFading(false);

    // Wait until the fadeout is done to fade in the original message:
    setTimeout(() => {
      setIsFading(true);

      // latched update, latchedShowMessageRef must set after setIsFading is true
      latchedShowMessageRef.current = message.message;

      // Allow the revealed message to display for some time, then begin fading
      setTimeout(() => {
        setIsFading(false);

        // Finally, wait until the fadeout is done, then fade in the translated
        setTimeout(() => {
          setIsFading(true);

          // latched update, latchedShowMessageRef must set after setIsFading true
          latchedShowMessageRef.current = dispMessage;

          // chain original message show process done, re-activate original language button
          originalLanguageButtonDisabled.current = false;
        }, 500);
      }, 3000);
    }, 500);
  };

  /**
   * When the original language button is pressed, we utilize two different sources to display
   * the message. Additionally, pressing the button triggers a latch logic that updates the
   * message after a delay. If the button is not pressed, the default message dispMessage is shown.
   */
  const activeShowingMessage = (): string => {
    if (originalLanguageButtonDisabled.current) {
      // if original language button is disabled ---> we must use latchedShowMessage
      return latchedShowMessageRef.current;
    } else {
      // Show whatever supposed to show
      return dispMessage;
    }
  };

  return message && (
    <Allign
      sx={{
        display: "flex",
        width: "fit-content",
        flexDirection: "column",
        maxWidth: "min(90%, calc(100vw - 50px))",
        minWidth: isTranslated ? '220px' : '85px',
        position: "relative",
        alignItems: 
        // On the user side, Team, and Group chats: only sender is on the right side
        // On the operator side, only sender (operator) and Morgan is on the right side
        message?.alignByCurrentUser
            ? 'flex-end'
            : 'flex-start',
      }}
    >
      <ChatBubbleStyle
        sx={{
          pointerEvents: "none",
          width: 'fit-content',
          minWidth: isTranslated ? '220px' : '85px',
          maxWidth: '100%',
          borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : isEditing ? 'var(--blue050)' : 'var(--orange700)',
          background: message.accessType === AccessType.internal
            ? 'var(--peach020)'
            : message.sentByCurrentUser
              ? 'var(--gray000)'
              : 'var(--peach600)',
          p: '6px 7px 8px 9px',
          '& a': {
            display: 'inline-flex',
            position: 'relative',
          },
          '& a[href^="#"]::before': {
            content: 'open-quote',
            display: 'block',
          },
          '& a[href^="#"]::after': {
            content: 'close-quote',
            display: 'block',
          }
        }}
        onClick={handleClickJoin}
      >
        {message.messageStatus === 'edited' ? (
          <>
            <Stack direction="row" justifyContent="center" alignItems="center" ref={messageRef}>
              <Fade in={isFading} appear={false}>
                <MessageTextStyle
                  sx={{
                    wordWrap: 'break-word',
                    width: 'fit-content',
                    color: 'var(--red400)',
                    textDecorationColor: 'var(--red400)',
                    textDecorationLine: 'line-through',
                  }}
                > 
                  <Sanitized
                    tag="s"
                    highlight={message?.searchWords}
                    html={dispMessage?.split(`<i class="messageArrow"/>`)[0]?.replace(/(<([^>]+)>)/gi, '')}
                    visible={message?.visible}
                  />
                </MessageTextStyle>
              </Fade>
              <ArrowCircleRightRoundedIcon
                sx={{
                  color: 'var(--orange700)',
                  marginRight: '5px',
                }}
              ></ArrowCircleRightRoundedIcon>
              <Fade in={isFading} appear={false}>
                <MessageTextStyle
                  sx={{
                    wordWrap: 'break-word',
                    fontStyle: 'italic',
                  }}
                >
                  <Sanitized
                    highlight={message?.searchWords}
                    html={dispMessage?.split(`<i class="messageArrow"/>`)[1]?.replace(/(<([^>]+)>)/gi, '')}
                    visible={message?.visible}
                  />
                </MessageTextStyle>
              </Fade>
            </Stack>
          </>
        ) : (
          <Fade in={isFading} appear={false}>
            <Stack direction="row" justifyContent="center" alignItems="center" ref={messageRef}>
              <MessageTextStyle>
                <Sanitized
                  highlight={message?.searchWords}
                  html={activeShowingMessage()}
                  visible={message?.visible}
                />
              </MessageTextStyle>
            </Stack>
          </Fade>
        )}
        <MessageTime message={message} />
      </ChatBubbleStyle>
      {isTranslated &&
        (message?.alignByCurrentUser ? (
          <TranslationTabRight>
            <OriginalLangStyle onClick={handleClickOriginalLang}>
              <u>{getLangName(message.detectedLanguage)}</u>
            </OriginalLangStyle>
            <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
          </TranslationTabRight>
        ) : (
          <TranslationTabLeft>
            <OriginalLangStyle onClick={handleClickOriginalLang}>
              <u>{getLangName(message.detectedLanguage)}</u>
            </OriginalLangStyle>
            <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
          </TranslationTabLeft>
        ))}
    </Allign>
  );
};

export default TextMessageContent;
