import IconButton from '@mui/material/IconButton';
import React, { PropsWithChildren } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import { Styles } from '../Styles/PreviewSelectedFilesStyles';
const DocumentPreview = process.env.REACT_APP_MORGAN_CDN + '/Images/DOC.png';

const PreviewSelectedFiles: React.FC<PropsWithChildren<any>> = (props) => {
  return (
    <Styles>
      {props.selectedFiles?.map((v, i, a) => (
        <span key={i}>
          <div className="PSFThumb">
            <IconButton
              className="PSFNail"
              onClick={(e) => {
                e?.stopPropagation();
                props.setSelectedFiles((prev) => prev.filter((x) => x !== v));
              }}
            >
              <CloseIcon className="PSFCloseIcon" />
            </IconButton>
            <div className="PSFFileHolder">
              <img
                src={typeof v.type === 'string' && v.type.split('/')[0] === 'image' ? URL.createObjectURL(v) : DocumentPreview}
                alt={v.name}
              />
              {props.isSending && <CircularProgress />}
              <p>{v.name}</p>
            </div>
          </div>
        </span>
      ))}
    </Styles>
  );
};

export default PreviewSelectedFiles;
