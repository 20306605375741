import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { useSocketContext } from '@contexts/socket';
import { SocketListenerType } from '@mTypes/TSocket';
import type { TChatStatusClosed, TEventPayload, TNotificationBannerUpdate, TSocketCallback, TSocketPayload } from '@mTypes/TSocket';
import useDocumentVisibility from '@common/hooks/useDocumentVisibility';

/**
 * Custom React hook for managing socket.io events from AngelAi-Events.
 * @param {string} _id - User ID.
 * @param {string} accessToken - Authentication accessToken.
 * @returns {Socket | null} - Socket instance or null if _id or accessToken are not provided.
 */
const useEventsSocket = (_id: string, accessToken: string, accessLevel?: string): { isEventsCreated: boolean, isEventsConnected: boolean, isEventsReconnecting: boolean, eventsSocket: MutableRefObject<Socket | null> } => {
  const [isEventsCreated, setIsEventsCreated] = useState<boolean>(false);
  const [isEventsConnected, setIsEventsConnected] = useState<boolean>(false);
  const [isEventsReconnecting, setIsEventsReconnecting] = useState<boolean>(false);
  const eventsSocket = useRef<Socket | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!_id) return console.warn(`eventsSocket invalid _id ${_id}`);
    if (!accessToken) return console.warn(`eventsSocket invalid accessToken ${_id}`);

    console.info(`Events Socket - useEventsSocket - (connecting)`);

    try {
      const config = {
        transports: ['websocket', 'transport'],
        reconnection: true,
        auth: {
          token: accessToken,
        },
        query: {
          userId: !accessLevel? _id : '',
          operatorId: accessLevel? _id : '',
          accessLevel: accessLevel, // required for operator specific events
        },
      };

      eventsSocket.current = io(process.env.REACT_APP_MORGAN_EVENTS || '', config) || null;
      setIsEventsCreated(true);
    } catch (err) {
      console.error(`Events Socket - useEventsSocket - (caught-error)`, err);
    }

    // Clean up the socket connection when the component unmounts
    return () => {
      console.warn(`Events Socket - useEventsSocket - (disconnected)`);

      eventsSocket.current?.disconnect();

      setIsEventsCreated(false);
      setIsEventsConnected(false);
    };
  }, [_id, accessToken]);

  useEffect(() => {
    if (!isEventsCreated) return;

    const handleConnect = () => {
      console.info(`eventsSocket manager connected`);

      setIsEventsConnected(true);
    }

    const handleDisconnect = (data) => {
      console.info(`eventsSocket manager disconnected`, data);

      setIsEventsConnected(false);

      if (eventsSocket.current?.active) {
        // temporary failure, the socket will automatically try to reconnect
      } else {
        // the connection was denied by the server
        // in that case, `socket.connect()` must be manually called in order to reconnect
        eventsSocket.current?.connect();
      }
    }

    const handleReconnect = (data) => {
      console.info(`eventsSocket manager reconnected`, data);
      setIsEventsConnected(true);

      setIsEventsReconnecting(false);
    }

    const handleReconnecting = (data) => {
      console.info(`eventsSocket manager reconnecting`, data);
      setIsEventsConnected(false);

      setIsEventsReconnecting(true);
    }

    const handleError = (error) => {
      console.error(`eventsSocket manager error`, error);

      if (error === 'Invalid ip or device') {
        if (accessLevel !== undefined) {
          navigate(`/console/login`, {
            state: {
              systemMessage: "The system is not available right now. Please try again later.",
            },
          });
        } else if (accessLevel === undefined) {
          navigate(`/notices`, {
            state: {
              systemMessage: "The system is not available right now. Please try again later.",
            },
          });
        }
      }
    }

    console.info(`Events Socket - useEventsSocket listeners - (connected)`);

    eventsSocket.current?.on('connect', handleConnect);
    eventsSocket.current?.on('disconnect', handleDisconnect);
    eventsSocket.current?.on("connect_error", handleError);
    eventsSocket.current?.io?.on('reconnect', handleReconnect);
    eventsSocket.current?.io?.on('reconnect_attempt', handleReconnecting);

    return () => {
      console.warn(`Events Socket - useEventsSocket listeners - (disconnected)`);

      eventsSocket.current?.off('connect', handleConnect);
      eventsSocket.current?.off('disconnect', handleDisconnect);
      eventsSocket.current?.off("connect_error", handleError);
      eventsSocket.current?.io?.off('reconnect', handleReconnect);
      eventsSocket.current?.io?.off('reconnect_attempt', handleReconnecting);
    };
  }, [isEventsCreated]);

  const reconnectEvents = (visibilityState: string) => {
    if(visibilityState === 'visible') {
      if (isEventsCreated && !isEventsReconnecting) {
        eventsSocket.current?.connect();
      }
    }
  };
  
  useDocumentVisibility(reconnectEvents);

  return {
    isEventsCreated,
    isEventsConnected,
    isEventsReconnecting,
    eventsSocket
  };
};

export const useEventsListener = <T extends SocketListenerType>(
  origin: string,
  event: T,
  callback: TSocketCallback<T>,
) => {
  const socketContext = useSocketContext();

  const validatePayloadData = useCallback((data: TSocketPayload<T>) => {
    try {
      switch (event) {
        case SocketListenerType.chatStatusClosed:
          const chatStatusClosedData = data as TChatStatusClosed;
          if (typeof chatStatusClosedData === 'string') return false;
          if (typeof chatStatusClosedData.parentId !== 'string') return false;
          if (chatStatusClosedData.parentId.length !== 24) return false;
          return true;
        case SocketListenerType.notificationBannerUpdate:
          const notificationBannerUpdateData = data as TNotificationBannerUpdate;
          if (typeof notificationBannerUpdateData === 'string') return false;
          if (typeof notificationBannerUpdateData.message !== 'string') return false;
          if (typeof notificationBannerUpdateData.enabled !== 'string') return false;
          return true;
        default:
          return false;
      }
    } catch {
      return false;
    }
  }, [event]);

  const eventHandler = useCallback((payload: TEventPayload<T>) => {
    const data = payload?.data;
    const message = payload?.message;
    const isPayloadValid = validatePayloadData(data);
    if (!isPayloadValid) {
      return console.warn(`useEventsListener.${origin} (${event}): Invalid Payload`, payload);
    }

    console.log(`useEventsListener.${origin} (${event})` +
      (message ? `: ${message}` : ''), data);

    callback(data);
  }, [callback, validatePayloadData, origin]);

  useEffect(() => {
    if (!socketContext?.isEventsCreated) return;
    const socket: Socket = socketContext?.eventsSocket?.current;
    if (!socket || !socket.connected) return;

    // @ts-ignore
    socket?.on(event, eventHandler);
    console.log(`useEventsListener.${origin} (on) ${event}`);

    return () => {
      // @ts-ignore
      socket?.off(event, eventHandler);
      console.log(`useEventsListener.${origin} (off) ${event}`);
    };
  }, [eventHandler, socketContext?.isEventsCreated]);
};

export default useEventsSocket;