import React, { memo, useMemo } from 'react';

import MessageContentWrapper from './MessageContentWrapper';
import ErrorBoundary from '@components/ErrorBoundary';
import { useMessageContext } from '@contexts/MessageContext';
import { IErrand, IMessage } from '@interfaces/Conversation';
import MessageContentSkeleton from './Skeletons/MessageContentSkeleton';

/*
 *  This component renders a message in the conversation. This includes not only the chat bubble, but the Author and
 * time sent as well
 *
 *  This component has the following properties:
 *    - author - The name of the person that composed the message
 *    - time - Time the message was sent/recieved
 *    - message - The actual content of the message
 *    - isOperator - A boolean value telling us if the author was an operator or not ,
 *     this will decide if the message is rendered on the left or right of the screen and
 *     in the appropriate
 */
type TConversationMessageProps = {
  errand: IErrand;
  index: number;
  showDate: boolean;
  showSentiment: boolean;
}

const ConversationMessage = memo(({
  errand, index, showDate, showSentiment
}: TConversationMessageProps) => {
  const messageContext = useMessageContext();

  const msg = {
    regular: errand?.messages?.[index],
    private: errand?.privateMessages?.[index],
  };

  // No point in memoizing these as the dependencies would change on every render
  const messageUpdatedAt = msg.regular?.updatedAt;
  const messageVisible = msg.regular?.visible;
  const messageTranslatedLanguage = msg.regular?.translatedLanguage;

  const privateMessageUpdatedAt = msg.private?.updatedAt;
  const privateMessageVisible = msg.private?.visible;
  const privateMessageTranslatedLanguage = msg.private?.translatedLanguage;

  const message = useMemo((): IMessage => {
    if (messageContext.isPrivate) {
      return msg.private as IMessage;
    }
    return msg.regular as IMessage;
  }, [
    messageContext.isPrivate,
    index,
    messageUpdatedAt,
    messageVisible,
    messageTranslatedLanguage,
    privateMessageUpdatedAt,
    privateMessageVisible,
    privateMessageTranslatedLanguage,
  ]);

  return (
    <ErrorBoundary debug={`ConversationMessage: ${JSON.stringify({ errand, index, showDate, showSentiment })}`}>
      <MessageContentWrapper
        errand={errand}
        index={index}
        message={message}
        showDate={showDate}
        showSentiment={showSentiment}
      >
        <MessageContentSkeleton errand={errand} index={index} message={message} />
      </MessageContentWrapper>
    </ErrorBoundary>
  );
});

export default ConversationMessage;
