import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ConversationBadge from '@components/ConversationBadge';
import CreateGroupModal from '@components/CreateGroupModal.js';
import CreateTeamModal from '@components/CreateTeamModal.js';
import InfiniteScroll from 'react-infinite-scroller';
import InviteUserDialog from '@components/InviteUserDialog';
import RefreshIcon from '@mui/icons-material/Refresh';
import ScrollBox from '@components/ScrollBox';
import ConversationsListSkeletonLoader from '@components/ConversationsListSkeletonLoader';
import axiosCall from '@services/axios';
import { useTranslation } from 'react-i18next';
import useDebounce from '@common/hooks/useDebounce';
import ErrorBoundary from '@components/ErrorBoundary';
import { useRootContext } from '@contexts/RootContext';

import { patchNullMessagesOf, prepareOperatorErrands, getOnlineStatus } from '@common/errandUtils';
import { OperatorErrand } from '@mTypes/Conversation';
import { ChatType } from '@common/ChatType';
import { useUserContext } from '@contexts/user';

// User type delay for backend request
const TYPE_DEBOUNCE_MS = 500;
const CHUNK_SIZE = 15;

// Valid tabs that can be chosen
const validTabMap = {
  mine: null,
  live: null,
  history: null,
  team: null,
  group: null,
}


/*
 *  This component makes up the list of conversations seen in every tab of the
 * console conversation screen.
 *
 *  This component uses the following props:
 *    - title: The title to be displayed across the top of the list. If left blank
 *      no component shall be rendered before the items.
 *    - search: this component tells us whether or not to show the search bar.
 *    - conversations: This is the list of conversations to be rendered.
 *    - add: whether or not to display the add icon next to the search bar.
 *    - showOnline: whether or not to show the online users and the array of users to show.
 *    - group: whether or not to show group chat creation modal
 */

const ConversationsList = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { hashkey } = useParams();
  const iUserData = useUserContext();
  const rootContext = useRootContext();
  const [createGroupModal, setCreateGroupModal] = useState({ open: false, data: null });
  const [createTeamModal, setCreateTeamModal] = useState({ open: false, data: null });
  const [searchString, setSearchString] = useState('');
  const [inviteDialog, setInviteDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [errandOption, setErrandOption] = useState(true);
  const [chatIdOption, setChatIdOption] = useState(false);
  const [participantOption, setParticipantOption] = useState(true);
  const [messageOption, setMessageOption] = useState(true);

  const [convHasMore, setConvHasMore] = useState(false);
  const [convIsLoading, setConvIsLoading] = useState(false);
  const [convOffset, setConvOffset] = useState(0);

  const prevChatId = useRef('');
  const prevTab = useRef('mine');
  const isLoading = useRef(true);
  const badgeRefs = useRef([]);

  const convMapDefaultLoadMore = {
    mine: `operator/chat/default?type=conversation,errand,form&status=active,unattended&limit=${CHUNK_SIZE}&offset=${convOffset}`,
    live: `chat?participant=false&status=active&type=conversation,errand,form&limit=${CHUNK_SIZE}&offset=${convOffset}`,
    history: `chat?type=conversation,errand,form&status=closed,inactive&limit=${CHUNK_SIZE}&offset=${convOffset}`,
    team: `operator/chat/default?type=team&limit=${CHUNK_SIZE}&offset=${convOffset}`,
    group: `operator/chat/default?type=group&status=unattended,active,inactive,waiting-updates&limit=${CHUNK_SIZE}&offset=${convOffset}`,
  };

  const convMapDefaultReload = {
    mine: `operator/chat/default?type=conversation,errand,form&status=active,unattended&limit=${CHUNK_SIZE}`,
    live: `chat?participant=false&status=active&type=conversation,errand,form&limit=${CHUNK_SIZE}`,
    history: `chat?type=conversation,errand,form&status=closed,inactive&limit=${CHUNK_SIZE}`,
    team: `operator/chat/default?type=team&limit=${CHUNK_SIZE}`,
    group: `operator/chat/default?type=group&status=unattended,active,inactive,waiting-updates&limit=${CHUNK_SIZE}`,
  };

  const convMapSearchLoadMore = {
    live: `chat/search?participant=false&status=active&type=conversation,errand,form&limit=${CHUNK_SIZE}&offset=${convOffset}`,
    history: `chat/search?type=conversation,errand,form&status=closed,inactive&limit=${CHUNK_SIZE}&offset=${convOffset}`,
  };

  const convMapSearchReload = {
    live: `chat/search?participant=false&status=active&type=conversation,errand,form&limit=${CHUNK_SIZE}`,
    history: `chat/search?type=conversation,errand,form&status=closed,inactive&limit=${CHUNK_SIZE}`,
  };

  const handleCreateGroupOpen = async () => {
    // Load ops
    const ops = await axiosCall({
      url: `operator/?order=asc&orderBy=lastname`,
    });

    setCreateGroupModal({ open: true, data: ops });
  };

  const handleCreateGroupSubmit = async (groupData) => {
    setCreateGroupModal({ open: false, data: null });

    const operatorData = groupData.operators.map((op) => {
      return {
        userId: op?._id,
        userType: 'Operator',
      };
    });

    await axiosCall({
      url: 'chat',
      method: 'post',
      data: {
        participants: operatorData,
        type: ChatType.Group,
        status: 'active',
        displayName: groupData.groupName,
      },
    })
      .then((r) => {
        // Add to the conversations list
        prepareOperatorErrands([r], iUserData).then((errands) => {
          props.setErrands((prev) => {
            if (!Array.isArray(prev)) {
              console.warn('setErrands prev is not an array');
              prev = [];
            }
            return [errands[0], ...prev];
          });
          navigate('/console', {
            state: {
              module: 'conversations',
              tab: 'group',
              chat: errands[0]?._id,
            },
          });
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setConvIsLoading(false);
      });
  };

  const handleCreateTeamOpen = async () => {
    // Load ops
    const ops = await axiosCall({
      url: `operator/?order=asc&orderBy=lastname`,
    });

    setCreateTeamModal({ open: true, data: ops });
  };

  const handleCreateTeamSubmit = async (teamData) => {
    setCreateTeamModal({ open: false, data: null });

    const operatorData = teamData.operators.map((op) => {
      return {
        userId: op?._id,
        userType: 'Operator',
      };
    });

    await axiosCall({
      url: 'chat',
      method: 'post',
      data: {
        participants: operatorData,
        type: ChatType.Team,
        status: 'active',
        // displayName: teamData.teamName,
      },
    })
      .then((r) => {
        // Add to the conversations list
        prepareOperatorErrands([r], iUserData).then((errands) => {
          errands[0].onlineStatus = operatorData.find((op) => op._id !== iUserData._id)?.userData?.status || 'offline';
          props.setErrands((prev) => {
            if (!Array.isArray(prev)) {
              console.warn('setErrands prev is not an array');
              prev = [];
            }
            return [errands[0], ...prev];
          });
          navigate('/console', {
            state: {
              module: 'conversations',
              tab: 'team',
              chat: errands[0]?._id,
            },
          });
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setConvIsLoading(false);
      });
  };

  const handlePlusButtonClick = () => {
    console.log('Clicked combo button!');
    if (location.state?.tab === 'group') handleCreateGroupOpen();
    if (location.state?.tab === 'mine') setInviteDialog(true);
    if (location.state?.tab === 'team') handleCreateTeamOpen();
  };

  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const convSearchReload = async (tab, params, abortController: AbortController = null) => {
    // make sure conv is not loading
    if (convIsLoading === true && prevTab.current === tab) {
      return;
    }
    prevTab.current = tab;

    // new search string
    if (convMapSearchReload[tab] === undefined) {
      console.log(`convSearchReload tab: ${tab} doesn't support search based on endpoint`);
      return props.errands;
    }

    // lock
    setConvIsLoading(true);

    let response: OperatorErrand[] = await axiosCall({
      url: convMapSearchReload[tab],
      method: 'POST',
      data: params,
    }, {signal: abortController?.signal});

    if (response.length < CHUNK_SIZE) {
      setConvHasMore(false);
    } else {
      setConvHasMore(true);
    }

    setConvOffset(CHUNK_SIZE);

    return response;
  };

  const convSearchLoadMore = async (tab, params, abortController: AbortController = null) => {
    // make sure conv is not loading
    if (convIsLoading === true && prevTab.current === tab) {
      return;
    }
    prevTab.current = tab;

    // make sure there are more messages to fetch
    if (convHasMore === false) {
      return;
    }

    // make sure tab exists in convMapLoadMore
    if (convMapSearchLoadMore[tab] === undefined) {
      console.log(`convSearchLoadMore tab: ${tab} doesn't support search based on endpoint`);
      return;
    }

    // lock
    setConvIsLoading(true);

    let response = await axiosCall({
      url: convMapSearchLoadMore[tab],
      method: 'POST',
      data: params,
    }, {signal: abortController?.signal});

    if (response.length < CHUNK_SIZE) {
      setConvHasMore(false);
    }

    setConvOffset((prev) => prev + CHUNK_SIZE);

    return response;
  };

  const convDefaultReload = async (tab, abortController: AbortController = null) => {
    // make sure conv is not loading
    if (convIsLoading === true && prevTab.current === tab) {
      return;
    }
    prevTab.current = tab;

    // make sure tab exists in convMapReload
    if (convMapDefaultReload[tab] === undefined) {
      console.info('convMapDefaultReload unhandled tab: ', tab);
      return;
    }

    // lock
    setConvIsLoading(true);

    let response: OperatorErrand[];

    try {
      response = await axiosCall({
        url: convMapDefaultReload[tab],
      }, {signal: abortController?.signal});

      if (response.length < CHUNK_SIZE) {
        setConvHasMore(false);
      } else {
        setConvHasMore(true);
      }
  
      setConvOffset(CHUNK_SIZE);
    }
    catch (error) {
      if (!abortController?.signal?.aborted) {
        console.error(error);
      }
    }

    // release
    return response;
  };

  const convDefaultLoadMore = async (tab, abortController: AbortController = null) => {
    // make sure conv is not loading
    if (convIsLoading === true && prevTab.current === tab) {
      return;
    }
    prevTab.current = tab;

    // make sure there are more messages to fetch
    if (convHasMore === false) {
      return;
    }

    // make sure tab exists in convMapLoadMore
    if (convMapDefaultLoadMore[tab] === undefined) {
      console.info('convDefaultLoadMore unhandled tab: ', tab);
      return;
    }

    // lock
    setConvIsLoading(true);

    let response: OperatorErrand[] = await axiosCall({
      url: convMapDefaultLoadMore[tab],
    }, {signal: abortController?.signal});

    if (response.length < CHUNK_SIZE) {
      setConvHasMore(false);
    }

    setConvOffset((prev) => prev + CHUNK_SIZE);

    return response;
  };

  const convNewSearchCriteria = async (tab, params) => {
    props.setErrands([]);
    if (params.searchString === '') {
      updateErrands(await convDefaultReload(tab));
    } else {
      updateErrands(await convSearchReload(tab, params));
    }
  };

  const convLoadMoreByScroll = async (tab, params) => {
    if (isLoading.current === false) {
      isLoading.current = true
      if (params.searchString === '') {
        // load more default
        await updateErrands(await convDefaultLoadMore(tab), true);
      } else {
        // load more search
        await updateErrands(await convSearchLoadMore(tab, params), true);
      }
    }
  };

  /**
   * This function exists to submit the returned OperatorErrand array for preparation and to add to the errands state.
   * It converts them to the OperatorErrand Type due to the additional data Operator side errands need.
   * @see OperatorErrand
   * @param tabErrands OperatorErrand[] The array of errands retrieved from convDefaultReload
   * @param loadMore boolean If this call is to load more, otherwise it is a new list and do not add to existing errands.
  */
  const updateErrands = async (tabErrands: OperatorErrand[], loadMore: boolean = false, abortController: AbortController = null, onlineUsers = []) => {
    try {
      if (!tabErrands) {
        setConvIsLoading(false);
        return;
      };
      prepareOperatorErrands(tabErrands, iUserData, abortController, onlineUsers, location.state?.tab === 'mine').then((preparedErrands) => {
        if (!abortController?.signal?.aborted){
          props.setErrands((prev) => {
            if (!Array.isArray(preparedErrands) && !Array.isArray(prev)) return [];
            if (!Array.isArray(preparedErrands)) return prev;
            if (!Array.isArray(prev)) return preparedErrands;
            let mergedErrands = patchNullMessagesOf(preparedErrands, prev);
            if (loadMore) return [...prev, ...mergedErrands];
            prevTab.current = location.state.tab;
            return mergedErrands;
          });
        }
        setConvIsLoading(false);
      });
    } catch (error) {
      if (!abortController?.signal?.aborted) {
        console.error(error);
      }
    } finally{
      isLoading.current = false
    }
  }

  // load a specific errand if it is not present in the list
  const loadErrand = async (chatId: string, abortController: AbortController = null, onlineUsers = []) => {
    try {
      let response: OperatorErrand = await axiosCall({ url: `chat/${chatId}` });
      prepareOperatorErrands([response], iUserData, abortController, onlineUsers, location.state?.tab === 'mine').then((preparedErrand) => {
        props.setErrands((prev) => {
          if(!Array.isArray(preparedErrand) && !Array.isArray(prev)) return [];
          if(!Array.isArray(preparedErrand)) return prev;
          if(!Array.isArray(prev)) return preparedErrand;
          return [...preparedErrand, ...prev];
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  // handle decoding of hash key and navigating to chat from the hash.
  useEffect(() => {
    // fetch list of chats related to the new tab
    let abortController = new AbortController();
    const handleHashkey = async () => {
      try {
        // check if hashkey is present
        if (hashkey) {
          // call API to get hashkey data
          const decodedHash = await axiosCall({
            url: `hashkey/${hashkey}`,
          });
          // if chatId is present in the decoded hashkey data, add operator as a participant and navigate to the chat
          if (decodedHash?.parameters?.chatId) {
            //  Get the operatorId from local storage 
            let operatorId = iUserData._id;
            // If one exists in the hash then use it.
            if (decodedHash?.parameters?.userId) operatorId = decodedHash?.parameters?.userId;
            // call API to add operator as a participant to the chat
            const newParticipants = await axiosCall({
              url: `chat/${decodedHash.parameters?.chatId}/participant`,
              method: 'POST',
              data: {
                userId: operatorId,
                userType: 'Operator',
              },
            });
            if (newParticipants) {
              // navigate to the chat from the hash
              return navigate('/console', {
                state: {
                  module: 'conversations',
                  tab: 'mine',
                  chat: decodedHash?.parameters?.chatId,
                },
              });
            } else {
              // if there's no chatId or there was an error decoding
              return navigate('/console', {
                state: {
                  module: 'conversations',
                  tab: 'mine',
                },
              });
            }
          } else {
            // if there's no chatId or there was an error decoding
            return navigate('/console', {
              state: {
                module: 'conversations',
                tab: 'mine',
              },
            });
          }
        }
      } catch (err) {
        console.error(err);
      }

    };
    setConvIsLoading(true);
    handleHashkey();
    (async (abortController) => {
      // When opening the conversations page and there is no hashkey load the errands for
      // the current tab (page reload)
      // or default to mine if this is the first time going to the conversations page
      await updateErrands(await convDefaultReload(location.state?.tab || 'mine'), false, abortController, props.onlineUsers);
    })(abortController);

    return () => {
      abortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iUserData._id]);

  //radio button-like toggle for chat ID selection.
  useEffect(()=>{
    if (chatIdOption === true){
      setMessageOption(false);
      setParticipantOption(false);
      setErrandOption(false);
    }
  },[chatIdOption, messageOption, participantOption, errandOption])

  // set the selected tab and reload the conversation as needed
  useEffect(() => {
    // fetch list of chats related to the new tab
    let abortController = new AbortController();
    // if the location state's tab is valid, set the selected tab and reload the conversation.
    if (validTabMap[location.state?.tab] !== undefined && prevTab.current !== location.state?.tab) {
      prevTab.current = location.state?.tab;
      // Reset search text and scrolling when tab changes
      setSearchString('');
      // clear existing errands
      props.setErrands([]);
      (async () => {
        await updateErrands(await convDefaultReload(location.state?.tab || 'mine', abortController), false, abortController, props.onlineUsers);
      })();
    }

    return () => {
      setConvIsLoading(false);
      abortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.tab, i18n.language, props.onlineUsers?.length]);

  useEffect(() => {
    // fetch list of chats related to the new tab
    let abortController = new AbortController();
    if (location.state.chat && location.state?.chat !== prevChatId.current) {
      if (!Array.isArray(props.errands) || props.errands.length === 0) return; // only run if default errands are loaded.
      prevChatId.current = location.state?.chat;
      let index = props.errands.findIndex((e) => e._id === location.state?.chat);
      if (index === -1) {
        // chat not found, fetch single chat from Core
        loadErrand(location.state.chat, abortController, props.onlineUsers);        
      }
    }

    return () => {
      abortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errands?.length, location.state?.chat, props.onlineUsers?.length]);

  // update the online status for each conversation when the list of online users changes
  useEffect(() => {
    // get the status of a chat based on the primary user's status
    const getUnreadNotifications = (conversation) => {
      if (!conversation || !conversation._id || conversation._id === location.state?.chat) return 0;
      if (!Array.isArray(props.notifications) || props.notifications?.length < 1) return 0;
      return props.notifications.filter((n) => n.chat?._id === conversation._id).length || 0;
    };

    props.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      let conversations = prev;
      // iterate through each conversation
      conversations.map((conv) => {
        let conversation = conv;
        // set the online status for this conversation
        conversation.unreadNotifications = getUnreadNotifications(conv);
        return conversation;
      });
      return [...conversations];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errands?.length, props.notifications, location.state?.chat]);

  /*
   *  This useEffect is used to sort the conversation list as it is updated. The current sorting is to
   * first sort by date and then to pull all of the online conversations out and place them at the top.
   */
  useEffect(() => {
    /* For dynamic scrolling this is used to ensure that there is a correct ammount of refs
     * generated for us to attach to later.
     */
    badgeRefs.current = badgeRefs?.current?.slice(0, props.errands?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errands?.length]);
  
  // update the online status for each conversation when the list of online users changes
  useEffect(() => {
    props.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      let conversations = prev;
      // iterate through each conversation
      conversations.map((conv) => {
        let conversation = conv;
        // set the online status for this conversation
        conversation.onlineStatus = getOnlineStatus(conv, props.onlineUsers);
        return conversation;
      });
      return [...conversations];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errands?.length, props.onlineUsers?.length]);

  useDebounce(
    () => {
      // Reset scroll
      if (badgeRefs && badgeRefs.current[0]) badgeRefs.current[0].scrollIntoView();

      if (!['team', 'group', 'mine'].includes(location.state?.tab)) {
        convNewSearchCriteria(location.state?.tab, {
          searchString,
          errandOption,
          participantOption,
          messageOption,
          chatIdOption
        });
      }
    },
    TYPE_DEBOUNCE_MS,
    [searchString, errandOption, participantOption, messageOption, chatIdOption]
  );

  return (
    <ErrorBoundary>
      <InviteUserDialog
        open={inviteDialog}
        onClose={() => setInviteDialog(false)}
        operatorData={iUserData}
        setErrands={props.setErrands}
        selectedTab={location.state?.tab}
        setConvIsLoading={setConvIsLoading}
      />
      <CardContent sx={{ width: '100%', height: '100%', padding: '16px 3px 24px 16px' }}>
        {/* top */}
        <Stack direction="row">
          {props.title && (
            <Typography sx={{ display: 'flex', alignItems: 'center' }} fontSize={18}>
              {props.title}
            </Typography>
          )}  
          <Box>
            {['live', 'history'].includes(location.state?.tab) ? (
              <TextField
                label={t('consoleConversationsSearch')}
                variant="outlined"
                size="small"
                onChange={async (e) => setSearchString(e.target.value)}
                value={searchString}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Tooltip title={t('searchOption')} placement="bottom">
                      <IconButton
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-controls="lock-menu"
                        aria-label="search options"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickListItem}
                        sx={{ marginRight: '-14px' }}
                      >
                        {open ? (
                          <ArrowDropDownCircleIcon sx={{ transform: 'rotate(180deg)' }} />
                        ) : (
                          <ArrowDropDownCircleIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            ) : (
              <>
                {/* This textfield is temporarily disabled until the remainder of the search functionality has been completed */}
                <TextField
                  label={t('consoleConversationsSearch')}
                  variant="outlined"
                  size="small"
                  onChange={async (e) => setSearchString(e.target.value)}
                  value={searchString}
                  fullWidth
                />
              </>
            )}
          </Box>
          <IconButton
            onClick={() => {
              // reset scroller position
              if (badgeRefs && badgeRefs.current[0]) badgeRefs.current[0].scrollIntoView();
              convNewSearchCriteria(location.state?.tab, {
                searchString,
                errandOption,
                participantOption,
                messageOption,
                chatIdOption
              });
              navigate('/console', {
                state: {
                  module: 'conversations',
                  tab: location.state?.tab,
                  chat: location.state?.chat,
                  join: false,
                },
              });
            }}
          >
            <RefreshIcon />
          </IconButton>
          {(!location.state?.tab || location.state?.tab === 'group' || location.state?.tab === 'mine' || location.state?.tab === 'team') && (
            <IconButton onClick={handlePlusButtonClick}>
              <AddIcon />
            </IconButton>
          )}
        </Stack>
        {/* The dropdown goes here for the search feature. It will be triggered by the Arrowdropdown  */}
        <div>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
            <MenuItem>
              <FormControlLabel
                sx={{ marginLeft: '0' }}
                control={
                  <Checkbox
                    disabled={searchString.length === 0}
                    checked={errandOption}
                    onChange={(e) => {
                      setErrandOption(e.target.checked);
                    }}
                  />
                }
                label={t('searchOptionsErrand')}
                labelPlacement="end"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                sx={{ marginLeft: '0' }}
                control={
                  <Checkbox
                    disabled={searchString.length === 0}
                    checked={participantOption}
                    onChange={(e) => {
                      setParticipantOption(e.target.checked);
                    }}
                  />
                }
                label={t('searchOptionsParticipants')}
                labelPlacement="end"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                sx={{ marginLeft: '0' }}
                control={
                  <Checkbox
                    disabled={searchString.length === 0}
                    checked={messageOption}
                    onChange={(e) => {
                      setMessageOption(e.target.checked);
                    }}
                  />
                }
                label={t('searchOptionsMessages')}
                labelPlacement="end"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                sx={{ marginLeft: '0' }}
                control={
                  <Checkbox
                    disabled={searchString.length === 0}
                    checked={chatIdOption}
                    onChange={(e) => {
                      setChatIdOption(e.target.checked);
                    }}
                  />
                }
                label={'Chat ID'}
                labelPlacement="end"
              />
            </MenuItem>
          </Menu>
        </div>

        {/*  */}
        {props.title && <Divider />}
        <ScrollBox height="calc(100% - 40px)" margin="16px 0">
          {/* If the user is on the live or history tab, check we don't have errands from the Core calls, other check against the search string, since for the other three tabs, we do not search via core */}
          {(props.errands?.length > 0 && ['live', 'history'].includes(location.state?.tab))  || 
          (['mine', 'team', 'group'].includes(location.state?.tab) && props.errands.filter( (conv) => conv?.displayString?.toLowerCase().includes(searchString.toLowerCase())).length > 0) ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={() =>
                convLoadMoreByScroll(location.state?.tab, {
                  searchString,
                  errandOption,
                  participantOption,
                  messageOption,
                })
              }
              hasMore={convHasMore && !convIsLoading}
              useWindow={false}
              threshold={5}
            >
              <List>
                {
                  // if the search is cleared or we are looking at the live or history tabs
                  (searchString.length === 0 || ['live', 'history'].includes(location.state?.tab)
                    ? props.errands.filter((conv) => conv.name?.toLowerCase())
                    : props.errands.filter(
                        (
                          conv // this allows the regular UI filter in the other tabs which use the operator endpoint
                        ) => conv?.displayString?.toLowerCase().includes(searchString.toLowerCase())
                      )
                  ).map((conv, index) => (
                    <ErrorBoundary key={index} debug={conv._id}>
                    <ListItemButton
                      // these five data types will be deleted once issues
                      // are resolved for chats appearing in the wrong tab
                      data-chat-id={conv?._id}
                      data-chat-status={conv.onlineStatus}
                      data-chat-type={conv.type}
                      sx={{ padding: '0 16px', marginRight: '7px' }}
                      ref={(el) => (badgeRefs.current[index] = el)}
                      selected={location.state?.chat === conv?._id}
                      onClick={() => {
                        if (badgeRefs && badgeRefs.current[index]) badgeRefs.current[index].scrollIntoView();
                        navigate('/console', {
                          state: {
                            module: 'conversations',
                            tab: location.state?.tab,
                            chat: conv?._id,
                            join: false,
                          },
                        });
                        rootContext.setMessagesAreLoading(true);
                      }}
                    >
                      <ConversationBadge
                        name={(conv?.displayString?.toLowerCase() || t('conversationListPlaceholderName')) + (conv.showInactive ? ` (${t('tInactive')})` : '')}
                        status={conv?.onlineStatus}
                        lastMessageData={conv?.lastMessageData}
                        chatId={conv?._id}
                        datetime={conv?.lastMessageData?.updatedAt || conv.updatedAt || conv.lastUpdated} // this should be reviewed since the updatedAt isn't part of the errand obj
                        notifCount={conv?.unreadNotifications}
                        img={conv.img}
                        preview={conv.preview}
                        assistedBy={conv.assistedBy}
                      />
                      <Divider sx={{ marginTop: '8px', marginBottom: '8px' }} />
                    </ListItemButton>
                    </ErrorBoundary>
                  ))
                }
                {convIsLoading && (
                  <Stack justifyContent="center" alignItems="center">
                    <CircularProgress size="2em" />
                  </Stack>
                )}
              </List>
            </InfiniteScroll>
          ) : convIsLoading ? (
            <Container
              sx={{
                width: '100%',
                height: 'calc(100% - 40px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ConversationsListSkeletonLoader />
            </Container>
          ) : (
            <Container
              sx={{
                width: '100%',
                height: 'calc(100% - 40px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Card>
                <Typography p={1.5}> {t('consoleConversationsNoConv')} </Typography>
              </Card>
            </Container>
          )}
        </ScrollBox>
      </CardContent>
      {/* Modals */}
      {createGroupModal.open && (
        <CreateGroupModal
          open={createGroupModal.open}
          data={{ currentOp: iUserData }}
          onClose={() => setCreateGroupModal({ open: false, data: null })}
          onSubmit={handleCreateGroupSubmit}
        />
      )}

      {createTeamModal.open && (
        <CreateTeamModal
          open={createTeamModal.open}
          data={{ currentOp: iUserData }}
          onClose={() => setCreateTeamModal({ open: false, data: null })}
          onSubmit={handleCreateTeamSubmit}
        />
      )}
    </ErrorBoundary>
  );
};

export default ConversationsList;
