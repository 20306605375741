import React, { useState, useRef, Dispatch, SetStateAction } from 'react';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import MorphAttachmentMenu from './MorphAttachmentMenu';
import MorphPhotoMenu from './MorphPhotoMenu';
import MorphCalendar from './MorphCalendar';
import MorphTimeSkeleton from './Skeletons/MorphTimeSkeleton';
import MorphTimeTabSkeleton from './Skeletons/MorphTimeTabSkeleton';
import MorphCalendarMonthSkeleton from './Skeletons/MorphCalendarMonthSkeleton';
import MorphCalendarMonthTabSkeleton from './Skeletons/MorphCalendarMonthTabSkeleton';
import { MorphedConversationFooterStyles } from '@styles/MorphedConversationFooterStyles';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';
import Sanitized from '@components/Sanitized';
import { MorphType, morphIndentType } from '@common/MorphType';
import { MorphFormNavigateInitialsTopBox, MorphFormNavigateInitialsIndent } from './MorphFormNavigateInitials';
import { MorphFormInsertSignatureTopBox, MorphFormInsertSignatureIndent } from './MorphFormInsertSignature';
import { MorphFormOpenTopBox, MorphFormOpenIndent } from './MorphFormOpen';
import { MorphFormActiveChatTopBox, MorphFormActiveChatIndent } from './MorphFormActiveChat';
import { MorphFormReadyToSendTopBox, MorphFormReadyToSendIndent } from './MorphFormReadyToSend';
import MorphSlotMachine from './MorphSlotMachine';
import MorphContact from './MorphContact';
import MorphPayment from './MorphPayment';
import { MorphErrandIndent, MorphErrandTopBox } from './MorphErrand';
import MorphRecording from './MorphRecording';
import MorphErrandNewTopBox from './MorphErrandNew';
import MorphDownloadAppBanner from './MorphDownloadAppBanner';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MorphSongOfTheDay from './MorphSongOfTheDay';
import { IErrand, IUserData } from '@interfaces/Conversation';
import MorphMessageOptionsSkeleton from '@components/Skeletons/MorphMessageOptionsSkeleton';
import MorphMessageOptionsTabSkeleton from '@components/Skeletons/MorphMessageOptionsTabSkeleton';
import MorphPrivateChatSkeleton from '@components/Skeletons/MorphPrivateChatSkeleton';
import { useTranslation } from 'react-i18next';
import MorphReplySkeleton from '@components/Skeletons/MorphReplySkeleton';
import MorphBorrowerSelectorSkeleton from '@components/Skeletons/MorphBorrowerSelectorSkeleton';
import MorphShareCustomLinkSkeleton from './Skeletons/MorphShareCustomLinkSkeleton';
import MorphEditSkeleton from './Skeletons/MorphEditSkeleton';
import MorphChooseLoanProductsSkeleton from './Skeletons/MorphChooseLoanProductsSkeleton';
import MorphChooseLoanProductPriceTableSkeleton from './Skeletons/MorphLoanProductPriceTableSkeleton';
import MorphVideoListMenuSkeleton from './Skeletons/MorphVideoListMenuSkeleton';
import MorphUserPromptsMenuSkeleton from './Skeletons/MorphUserPromptsMenuSkeleton';
import MorphCreditRepairDisputeAccountTypeSkeleton from './Skeletons/MorphCreditRepairAccountTypeSkeleton';
import MorphCreditRepairWelcomeSkeleton from './Skeletons/MorphCreditRepairWelcomeSkeleton';
import MorphRefinanceCalculatorWelcomeSkeleton from './Skeletons/MorphRefinanceCalculatorWelcomeSkeleton';

/**
 * The MorphedConversationFooter is the component that lays above the normal conversation footer and gives the
 * impression of it having "morphed" into the  version that has an "indent" on the top border. For example,
 * this is seen when the user clicks on the "voice recording" mic button to the right of the input field.
 * The morphType determines what component is displayed in the indent. This is set in ConversationFooter.tsx
 * and then passed into here as a prop to render the appropriate component.
 * You can also see pictures here: http://jira.swmc.com:8080/browse/MRGN-508
 */

type TMorphedConversationFooterProps = {
  action: boolean;
  cancelAction: (key: any, clear: boolean) => void;
  handleSubmit: ((e: Event) => void);
  dispFilterMode: string;
  editMessageId: string;
  errand: IErrand;
  filterName: string;
  handleOpenContacts: () => void;
  isTyping: string[];
  messageFilter: string;
  operatorData?: IUserData;
  parentId: string;
  selectedFiles: File[];
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setIconToShow: Dispatch<SetStateAction<string>>;
  setIsTyping: Dispatch<SetStateAction<string[]>>;
  setPreviewUrl: Dispatch<SetStateAction<string>>;
  setSelectedFiles: Dispatch<SetStateAction<File[]>>;
  setShowContactsConsent: Dispatch<SetStateAction<boolean>>;
  setValue: Dispatch<SetStateAction<string>>;
  showSentiment: boolean;
};

const MorphedConversationFooter = ({
  action, cancelAction, handleSubmit, dispFilterMode, editMessageId, errand, filterName, handleOpenContacts, isTyping, messageFilter, operatorData, parentId, selectedFiles, setEditMessageId, setIconToShow, setIsTyping, setPreviewUrl, setSelectedFiles, setShowContactsConsent, setValue, showSentiment,
}: TMorphedConversationFooterProps) => {
  const { t } = useTranslation();
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const [text, setText] = useState('&nbsp;');
  const [indentType, setIndentType] = useState<morphIndentType>(morphIndentType.FormGenerate);
  const [showPermissionReminder, setShowPermissionReminder] = useState(false);
  const indentRef = useRef(null);
  const fade = errandContext.morphType !== MorphType.Recording && errandContext.morphType !== MorphType.Contacts;
  const [selectedClosedErrand, setSelectedClosedErrand] = useState<number>(null);

  const MorphTypeMap = () => {
    switch (errandContext?.morphType) {
      case MorphType.Attachment:
        return <MorphAttachmentMenu
          setShowPermissionReminder={setShowPermissionReminder}
          setIconToShow={setIconToShow}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          handleOpenContacts={handleOpenContacts}
          setShowContactsConsent={setShowContactsConsent}
          errand={errand}
          operatorData={operatorData}
        />;
      case MorphType.PhotoPlain:
      case MorphType.PhotoMain:
        return <MorphPhotoMenu
          setShowPermissionReminder={setShowPermissionReminder}
          setIconToShow={setIconToShow}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          setText={setText}
        />;
      case MorphType.Contacts:
        return <MorphContact filterName={filterName} setText={setText} />;
      case MorphType.Calendar:
        return <MorphCalendar setShowPermissionReminder={setShowPermissionReminder} setIconToShow={setIconToShow} setSelectedFiles={setSelectedFiles} selectedFiles={selectedFiles} setText={setText} />
      case MorphType.DOB:
        return <MorphCalendarMonthSkeleton/>
      case MorphType.CalendarMonth:
        return <MorphCalendarMonthSkeleton/>
      case MorphType.Time:
        return <MorphTimeSkeleton/>
      case MorphType.FormOpen:
        return <MorphFormOpenTopBox />;
      case MorphType.Payment:
        return <MorphPayment setText={setText} errand={errand} cancelAction={cancelAction}/>;
      case MorphType.Errand:
        return <MorphErrandTopBox errand={errand} setSelectedClosedErrand={setSelectedClosedErrand} />;
      case MorphType.ErrandNew:
        return <MorphErrandNewTopBox />;
      case MorphType.ErrandEdit:
        return <MorphErrandNewTopBox />;
      case MorphType.FormActiveChat:
        return <MorphFormActiveChatTopBox />;
      case MorphType.FormNavigateInitials:
        return <MorphFormNavigateInitialsTopBox parentId={parentId} />;
      case MorphType.FormReadyToSend:
        return <MorphFormReadyToSendTopBox parentId={parentId} errand={errand} />;
      case MorphType.FormInsertSignature:
        return <MorphFormInsertSignatureTopBox indentType={indentType} setIndentType={setIndentType} />;
      case MorphType.DownloadAppBanner:
        return <MorphDownloadAppBanner errand={errand} />;
      case MorphType.MessageOptions:
        return <MorphMessageOptionsSkeleton errand={errand} setEditMessageId={setEditMessageId} setValue={setValue}  />
      case MorphType.PrivateChat:
        return <MorphPrivateChatSkeleton
          dispFilterMode={dispFilterMode}
          editMessageId={editMessageId}
          errand={errand}
          isPrivate={false}
          isTyping={isTyping}
          messageFilter={messageFilter}
          operatorData={operatorData}
          setEditMessageId={setEditMessageId}
          setPreviewUrl={setPreviewUrl}
          setIsTyping={setIsTyping}
          setValue={setValue}
          showSentiment={showSentiment}
        />
      case MorphType.Reply:
        return <MorphReplySkeleton errand={errand} />;
      case MorphType.Edit:
        return <MorphEditSkeleton errand={errand} cancelAction={cancelAction}/>;
      case MorphType.VideoListMenu:
        return <MorphVideoListMenuSkeleton errand={errand} cancelAction={cancelAction} />;
      case MorphType.UserPromptsMenu:
        return <MorphUserPromptsMenuSkeleton errand={errand} cancelAction={cancelAction}/>;
      case MorphType.BorrowerSelector:
        return <MorphBorrowerSelectorSkeleton setText={setText}/>;
      case MorphType.LoanProducts:
        return <MorphChooseLoanProductsSkeleton setText={setText} />;
      case MorphType.LoanProductPriceTable:
        return <MorphChooseLoanProductPriceTableSkeleton />;
      case MorphType.CreditRepairDisputeAccountType:
        return <MorphCreditRepairDisputeAccountTypeSkeleton errand={errand} cancelAction={cancelAction} />
      default:
        return null;
    }
  };

  const MorphTypeMapTab = () => {
    switch (errandContext?.morphType) {
      case MorphType.Attachment:
        return t('selectOption');
      case MorphType.VideoListMenu:
        return window.innerWidth > 425 ? 
          rootContext.videoMenuTitle : 
          window.innerWidth <= 320 ?
          <span style={{fontSize: '10px'}}>{`${rootContext.videoMenuTitle.substring(0, 20)}...`}</span> :
          <span style={{fontSize: '11px'}}>{`${rootContext.videoMenuTitle.substring(0, 24)}...`}</span>
      case MorphType.UserPromptsMenu:
        return t('userPromptsMenuTabText');
      case MorphType.CreditRepairDisputeAccountType:
        return t('creditRepairDisputeAccountTypesDescription');
      // case MorphType.DownloadAppBanner:
      //   return t('downloadNow');
      case MorphType.FormOpen:
        return <MorphFormOpenIndent />;
      case MorphType.Recording:
        return <MorphRecording />;
      case MorphType.DOB:
          return <MorphCalendarMonthTabSkeleton />;
      case MorphType.CalendarMonth:
        return <MorphCalendarMonthTabSkeleton />;
      case MorphType.Time:
        return <MorphTimeTabSkeleton />;
      case MorphType.CreditRepairWelcome:
        return <MorphCreditRepairWelcomeSkeleton handleSubmit={handleSubmit}/>;
      case MorphType.RefinanceCalculatorWelcome:
          return <MorphRefinanceCalculatorWelcomeSkeleton handleSubmit={handleSubmit}/>;
      case MorphType.SlotMachine:
        return <MorphSlotMachine />;
      case MorphType.SongOfTheDay:
        return <MorphSongOfTheDay />;
      case MorphType.Errand:
        return <MorphErrandIndent selectedClosedErrand={selectedClosedErrand} />;
      case MorphType.FormActiveChat:
        return <MorphFormActiveChatIndent />;
      case MorphType.FormReadyToSend:
        return <MorphFormReadyToSendIndent errand={errand} parentId={parentId} />;
      case MorphType.FormInsertSignature:
        return <MorphFormInsertSignatureIndent indentType={indentType} setIndentType={setIndentType} />;
      case MorphType.FormNavigateInitials:
        return <MorphFormNavigateInitialsIndent parentId={parentId} />;
      case MorphType.PhotoMain:
        return (
          <>
            <div style={{ marginTop: '5px' }}>
              <Sanitized html={text} />
            </div>
            {errandContext.mainPhoto !== null && errandContext.photoSelectorIndex === errandContext.mainPhoto ? (
              <StarIcon onClick={() => { errandContext.setMainPhoto(null) }} />
            ) : (
              <StarBorderIcon onClick={() => { if (errandContext.photoSelectorIndex!== null) errandContext.setMainPhoto(errandContext.photoSelectorIndex) }} />
            )}
          </>
        );
      case MorphType.MessageOptions:
        return <MorphMessageOptionsTabSkeleton />
      case MorphType.PrivateChat:
        return t('privateChat');
      case MorphType.ShareCustomLink:
        return <MorphShareCustomLinkSkeleton cancelAction={cancelAction} />
      default:
        return <Sanitized html={text} />;
    }
  };

  return (
    <MorphedConversationFooterStyles
      className={
        (operatorData ? ' isOperator ' : '') +
        (rootContext.selectedIndex?.length === 2 ? ' isSplitScreen ' : '') +
        (errandContext.isMorphedFooterCloseButtonOnLeft ? ' isMorphedFooterCloseButtonOnLeft ' : '') +
        ` morphType${errandContext?.morphType} `
      }
    >
      <div className='container'>
        <Fade in={errandContext.morphType !== MorphType.None} appear={fade} timeout={fade ? 1000 : 0}>
          <Slide
            direction="left"
            in={errandContext.morphType !== MorphType.None}
            timeout={errandContext?.morphType === MorphType.Contacts ? 1000 : 0}
            easing={{
              enter: 'cubic-bezier(0, 1.5, .8, 1)',
              exit: 'linear',
            }}
            appear={errandContext?.morphType === MorphType.Contacts}
            container={indentRef.current}
          >
            <div className="topBox" ref={indentRef}>
              {MorphTypeMap()}
            </div>
          </Slide>
        </Fade>
        <Fade in={errandContext.morphType !== MorphType.None} appear={fade} timeout={fade ? 1000 : 0}>
          <div className="bottomBox" ref={errandContext?.morphIndent}>
            <div className="indentWrapper">
              <div className="middle">
                <div className="tab">
                  {MorphTypeMapTab()}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </MorphedConversationFooterStyles>
  );
};

export default MorphedConversationFooter;
