import React, { useMemo } from 'react';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { useMessageContext } from '@contexts/MessageContext';
import { AccessType } from '@common/AccessType';
import { DeletedMessageContentFallback, PrivateMessageContentFallback } from './Fallbacks';
import { MessageFallbackSelector } from './Fallbacks/MessageFallbackSelector';
import { MessageTypeSelector } from '@components/MessageTypeSelector';
import DeletedMessageContent from '@components/MessageContent/DeletedMessageContent';
import PrivateMessageContent from '@components/MessageContent/PrivateMessageContent';
import {
  ErrBoundaryWithSuspenseWrapper,
  getMessageDebugString,
} from '@components/Suspense/ErrBoundaryWithSuspenseWrapper';

type MessageContentSkeletonProps = {
  errand: IErrand;
  index: number;
  message: IMessage;
};

const MessageContentSkeleton = React.memo(({ errand, index, message }: MessageContentSkeletonProps) => {
  const messageContext = useMessageContext();

  const isDeletedMessageContent = useMemo(() => {
    return message.messageStatus === 'deleted';
  }, [message.messageStatus]);

  const isPrivateMessageContent = useMemo(() => {
    return !messageContext.isPrivate && message.accessType === AccessType.private;
  }, [messageContext.isPrivate, message.accessType]);

  const componentTypeProps = useMemo(() => {
    // prop definitions for error boundary reports
    const ErrandProp = {
      errand,
    };

    const MessageProp = {
      message,
    };

    const ErrandMessageProps = {
      errand,
      message,
    };

    const IndexMessageProps = {
      index,
      message,
    };

    const ErrandIndexMessageProps = {
      errand,
      index,
      message,
    };

    return {
      ErrandProp,
      MessageProp,
      ErrandMessageProps,
      IndexMessageProps,
      ErrandIndexMessageProps,
    };
  }, [message, errand, index]);

  if (!message) return <></>;

  // manual error + suspense
  const debugString = getMessageDebugString(
    componentTypeProps,
    message,
    isDeletedMessageContent,
    isPrivateMessageContent
  );

  const messageComponent = isDeletedMessageContent ? (
    <DeletedMessageContent {...componentTypeProps.MessageProp} />
  ) : isPrivateMessageContent ? (
    <PrivateMessageContent {...componentTypeProps.ErrandIndexMessageProps} />
  ) : (
    // all other msg types
    <MessageTypeSelector componentTypeProps={componentTypeProps} message={message} />
  );

  const fbComponent = isDeletedMessageContent ? (
    <DeletedMessageContentFallback message={message} />
  ) : isPrivateMessageContent ? (
    <PrivateMessageContentFallback message={message} />
  ) : (
    // all other message types
    <MessageFallbackSelector message={message} />
  );

  return (
    <ErrBoundaryWithSuspenseWrapper debugString={debugString} fbComponent={fbComponent}>
      {messageComponent}
    </ErrBoundaryWithSuspenseWrapper>
  );
});

export default MessageContentSkeleton;
