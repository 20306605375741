import { Chip, Divider, IconButton, Paper, Stack, Typography, useTheme, MorganTheme } from '@mui/material';
import React, { Dispatch, SetStateAction, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Styles from '@styles/ThemeShift.module.css';

import ScrollHandler from './ScrollHandler';
import ConversationMessage from './ConversationMessage';
import InfiniteScroll from 'react-infinite-scroller';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import loadMessages from '@common/loadMessages';
import { useTranslation } from 'react-i18next';
import axiosCall from '@services/axios';
import { useSocketContext } from '@contexts/socket';
import { copyObj, datesAreOnSameDay, shouldCurrUserReplyTo } from '@common/userMessagesUtils';
import { IErrand, IMessage, IUserData } from '@interfaces/Conversation';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';
import { getUserConsent } from '@storage/userStorage';
import MessagesSkeletonLoader from './MessagesSkeletonLoader';
import MessagesSkeletonLoaderSmall from './MessagesSkeletonLoaderSmall';
import { getCurrentParticipant, getPrimaryParticipant } from '@common/errandUtils';
import { FormBodySizeType } from '../Forms/commonForms';
import { ChatType } from '@common/ChatType';
import { getUserPlayedToday } from '@storage/userStorage';
import { MorphType } from '@common/MorphType';
import { MessageContext } from '@contexts/MessageContext';
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';
import AnalyzingAngel from './AnalyzingAngel';
import { ValidatorFunctions } from '@common/Validators';
import { ResetFooterUserAction } from '@common/common';

/* Limit of how many messages to fetch, convention dictates that top level consts are UPPER_CASE */
const LIMIT = 15; // encountered issues when we tried to use the env variable it was saying NaN.

export type TConversationBodyProps = {
  dispFilterMode: string;
  editMessageId: string;
  errand: IErrand;
  isPrivate?: boolean;
  isTyping?: string[]; // private chat only
  messageFilter: string;
  operatorData?: IUserData;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setIsTyping?: Dispatch<SetStateAction<string[]>>; // private chat only
  setPreviewUrl: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
  showBouncyRope?: boolean;
  showSentiment: boolean;
};

const ConversationBody = memo(({
  dispFilterMode, editMessageId, errand, isPrivate, messageFilter, operatorData, setEditMessageId, setPreviewUrl, setValue, showBouncyRope, showSentiment, 
}: TConversationBodyProps) => {
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const { messagesSocket, isMessagesConnected } = useSocketContext();
  const theme: MorganTheme = useTheme();
  const { t, i18n } = useTranslation();
  const { _id, isOperator } = useUserContext();
  const [isDisclaimerSeparate, setIsDisclaimerSeparate] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [triggerLoadMore, setTriggerLoadMore] = useState(false);
  const hasMoreMessages = useRef(false);
  const scrollBoxContainerRef = useRef(null);
  const bodyRef = useRef(null);

  const participant = useCallback(() => {
    return getCurrentParticipant(errand, _id);
  }, [errand.participants, _id]);

  const showAnalyzingAngel = (errandContext.isMorganTyping || errandContext.isAnalyzing) && !isOperator;

  // for disclaimer message height proper value
  const [disclaimerHeight, setDisclaimerHeight] = useState(0);
  const initialMessagesAreLoadedRef = useRef(false);

  const filteredMessages = useMemo(() => {
    const middleButtonIndex = errand.messages?.findIndex(msg => msg?.middleButton === true);
    const filters = (msg: IMessage, index: number, array: IMessage[]) => msg !== undefined && 
      ( dispFilterMode.toUpperCase() === 'NONE' || msg?.messageType === dispFilterMode ) &&
      ( messageFilter.length === 0 || msg?.searchWords === messageFilter || 
        // So the unblur button and new messages still render when messageFilter is set and the convo history is blurred
        ( middleButtonIndex >= 0 && index >= middleButtonIndex )
      ) &&
      array.findIndex((m: IMessage) => m?._id === msg?._id) === index;
    if (isPrivate) {
      // useMemo prevents isDisclaimerSeparate useEffect from running every render
      if (!errand.privateMessages || errand.privateMessages?.length === 0) {
        // guard statement to prevent issues with filter
        return [];
      }
      return errand.privateMessages.filter(filters);
    }
    // useMemo prevents isDisclaimerSeparate useEffect from running every render
    if (!errand.messages || errand.messages?.length === 0) {
      // guard statement to prevent issues with filter
      return [];
    }
    return errand.messages.filter(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrivate, errand.messages, errand.privateMessages, dispFilterMode, messageFilter]);

  const getTrueIndex = useCallback((messageId) => {
    if (isPrivate) {
      return errand?.privateMessages?.findIndex((msgData) => msgData?._id === messageId);
    }
    return errand?.messages?.findIndex((msgData) => msgData?._id === messageId);
  }, [isPrivate, errand?.messages, errand.privateMessages]);

  const BlurMessages = (loadedMesages) => {

    // guard statement to prevent unneccesary processing
    const currentParticipant = participant();

    if (
      messageHistoryAllowedBoolean ||
      currentParticipant === undefined ||
      isPrivate ||
      operatorData !== undefined ||
      !Array.isArray(errand.participants) ||
      errand.participants.length === 0 ||
      !Array.isArray(loadedMesages) ||
      loadedMesages.length === 0
    ) {
      return loadedMesages;
    }

    const messageArr = [];
    const middleButton = {
      middleButton: true,
      timestamp: Date.now(),
      visible: currentParticipant?.messageHistoryAllowed || false,
    };

    for (const message of loadedMesages) {
      messageArr.push({ ...message, visible: currentParticipant?.messageHistoryAllowed || false });
    }

    // added middleButton
    messageArr.push(middleButton);

    return messageArr;
  };

  const setUpNewChat = async (controller) => {
    try {
      rootContext.setMessagesAreLoading(true);
      setIsLoading(true);
      setIsInitialLoad(true);

      let loadedMessages: IMessage[] = await loadMessages(
        {
          url: 
            `chat/${errand._id}/message${messageFilter ? '/search' : ''}` + 
            `?order=desc&orderBy=createdAt&${messageFilter ? '' : `limit=${LIMIT}`}&batchOrder=asc` +
            `&internal=${isOperator ? true : false}` +
            `${isPrivate && errand.recipients?.length > 0 
              ? `&intendedAudience=${errand.recipients.sort().join(',')}`
              : ''}`,
          method: messageFilter ? 'post' : 'get',
          data: { keywords: messageFilter ? [messageFilter] : null },
        },
        _id,
        errand._id,
        errand.type,
        isOperator || isPrivate || participant()?.messageHistoryAllowed,
        isOperator,
        undefined,
        controller
      );

      if (!loadedMessages) return;

      //const loadedMessages = [];
      hasMoreMessages.current = loadedMessages?.length === LIMIT;

      // Check to see if messages has content and loadedMessages is empty, if so implies race condition 
      if ((isPrivate ? errand?.privateMessages?.length > 0 : errand?.messages?.length > 0) && (loadedMessages?.length === 0 || loadedMessages === undefined || loadedMessages === null)) {
        // Dont do anything to messages 
        console.warn('A race condition between the chat-message-update socket event and the loadMessages call was detected. Using the previously stored data.');
      } else {
        rootContext.setErrands((prev) => {
          // blur if needed
          loadedMessages = BlurMessages(loadedMessages);

          const chatObj = prev.find((e) => e._id === errand._id);

          if (chatObj) {
            if (isPrivate) {
              chatObj.privateMessages = loadedMessages;
            } else {
              chatObj.messages = loadedMessages;
            }

            chatObj.lastMessageData = loadedMessages[loadedMessages.length - 1];
          }

          return [...prev];
        });
      }

      setIsLoading(false);
      //trigger the skeleton loader to disappear
      rootContext.setMessagesAreLoading(false);

      let offset = isOperator || participant()?.messageHistoryAllowed ? 1 : 2;
      let messagesWithoutNotifications = loadedMessages?.filter(m => m?.messageType !== 'Notification');
      let lastMessage = messagesWithoutNotifications?.[messagesWithoutNotifications?.length - offset];
      // Check for message history allowed to not to change the footer of such user that has last msg as action and is NOT allowed to view messageHistory.
      const messageHistoryAllowed = participant()?.messageHistoryAllowed;
      // If the last loaded message was an action type && it is intended for current user, then we should automatically set the footer
      if (!operatorData 
          && loadedMessages?.length > 0 
          && lastMessage?.messageType === 'Action' 
          && lastMessage?.action?.description !== 'TCPA' 
          && (shouldCurrUserReplyTo(lastMessage, _id) === true)
          // This will prevent the user from replying to the last
          // action IF user is not allowed to see msgs history. 
          // (thus prevent replying to that action)
          && messageHistoryAllowed === true) {
        if (lastMessage?.action?.chatTypeInitiator === ChatType.Form 
          || lastMessage?.action?.chatTypeInitiator === ChatType.Activity
          || lastMessage?.action?.chatTypeInitiator === ChatType.Page) {return;}
        else {
          rootContext.setErrands((prev) => {
            const chatObj = prev.find((e) => e._id === errand._id);

            if (chatObj) {
              // If the last message is a Slot Machine actions and the user has played, we ignore the last message action
              if (lastMessage?.action?.description === 'Slot Machine' && getUserPlayedToday()) {
                ResetFooterUserAction(chatObj);
              } else if (
                (isPrivate && lastMessage?.accessType === AccessType.private) ||
                (!isPrivate && lastMessage?.accessType !== AccessType.private)
              ) {
                chatObj.icon = lastMessage?.icon;
                chatObj.placeholder = lastMessage?.action?.description;
                chatObj.action = {
                  ...lastMessage?.userAction,
                  action: lastMessage?.action,
                  userActionId: lastMessage?.userAction?._id,
                  active: true,
                };
                chatObj.recipients = lastMessage.intendedAudience
                  ? [lastMessage.sender._id, ...lastMessage.intendedAudience].sort()
                  : [];
              }
            }

            return [...prev];
          });
        }
      }
    }
    catch (err) {
      if (controller.signal.aborted) {
        console.log('The user aborted the request');
      } else {
        console.error('The request failed:', err);
      }
    }
    finally {
      setIsInitialLoad(false);
      rootContext.setMessagesAreLoading(false);
      // mark the initial load via ref.
      // make a macroTask (run after full rerender)
      setTimeout(() => {
        initialMessagesAreLoadedRef.current = true;
      }, 0);
    }
  };

  const loadMoreMessages = async (abortController, force = false) => {
    try {
      errandContext.setIsMorphedFooterCloseButtonOnLeft(false);
      errandContext.setMorphType((prev) => {
        if (prev === MorphType.MessageOptions) {
          return MorphType.None;
        }
        return prev;
      });
      errandContext.setMessageOptionsIndex(-1);
      if (((
          isOperator || participant()?.messageHistoryAllowed) &&
          errand?._id?.length === 24 &&
          !isInitialLoad &&
          !isLoading &&
          hasMoreMessages.current &&
          !rootContext.messagesAreLoading
        ) || !!force
      ) {
        // if filter is applied (lang carousel), omit loading more messages for now
        if(dispFilterMode.toUpperCase() !== 'NONE') {
          rootContext.setMessagesAreLoading(false);
          return;
        }
        // Lock the loading and update offset
        rootContext.setMessagesAreLoading(true);
        setIsLoading(true);
        // Get the next chunk
        const more: IMessage[] = await loadMessages(
          {
            url: `chat/${errand?._id}/message` + 
            `${messageFilter ? '/search' : ''}` +
            `?order=desc&orderBy=createdAt${messageFilter ? '' : `&offset=${(isPrivate ? errand?.privateMessages?.length : errand?.messages?.length) || 0}`}` +
            `${messageFilter ? '' : `&limit=${LIMIT}`}&batchOrder=asc` +
            `&internal=${isOperator ? true: false}` +
            `${isPrivate && errand.recipients?.length > 0 
              ? `&intendedAudience=${errand.recipients.sort().join(',')}`
              : ''}`,
            method: messageFilter ? 'post' : 'get',
            data: {keywords: messageFilter ? [messageFilter] : null}
          },
          _id,
          errand?._id,
          errand?.type,
          isOperator || isPrivate || participant()?.messageHistoryAllowed,
          isOperator,
          undefined, // messageIn
          abortController
        );

        if (!more) return;
        // If there are no more messages then we don't need to update state
        if (more?.length > 0) {
          rootContext.setErrands((prev) => {
            const chatObj = prev.find((e) => e._id === errand?._id);

            if (chatObj) {
              let prevMsg, toFilter;
              if (isPrivate) {
                prevMsg = chatObj.privateMessages || [];
                toFilter = prevMsg?.length > 0 ? [...more, ...prevMsg] : more;
                chatObj.privateMessages = toFilter.filter(
                  (message, index, self) =>
                    message !== undefined && self.findIndex((m) => m._id === message._id) === index
                );
                chatObj.lastMessageData = chatObj.privateMessages[chatObj.privateMessages.length - 1];
              } else {
                prevMsg = chatObj.messages;
                toFilter = prevMsg?.length > 0 ? [...more, ...prevMsg] : more;
                chatObj.messages = toFilter.filter(
                  (message, index, self) =>
                    message !== undefined && self.findIndex((m) => m._id === message._id) === index
                );
                chatObj.lastMessageData = chatObj.messages[chatObj.messages.length - 1];
              }
            }

            return [...prev];
          });
        }
        // if the length of the returned messages is not equal to the chunk size then there are no more messages
        hasMoreMessages.current = more?.length === LIMIT;
        // Release
        setIsLoading(false);
        // trigger the skeleton loader to disappear
        rootContext.setMessagesAreLoading(false);
      }
    } catch (err) {
      if (!abortController?.signal?.aborted) {
        console.error(err);
      }
    } finally {
      // 11/13/23 Harrison Fales - This timeout is explicity a temporary fix that should be removed with an additional
      // change to better handle the multiple variables governing loading and scroll position. The issue is currently due to
      // a race condition where triggerLoadMore conditional component is changing the size of the scroll box at times that are
      // preventing the scrollHandler from properly adjusting the scroll postion when loading stops. 
      setTimeout( () => {
        // required after searching to allow message history to load
        setIsInitialLoad(false);
        // allow the function to be called again
        setTriggerLoadMore(false);
      }, 100)
    }
  };

  // This will be updated for each iteration of messages
  let previousDate = null;

  const handleClickRequestHistory = async () => {
    const abortController = new AbortController();

    // const primary = errand.participants?.find((x) => x?.primary === true)?.userData?._id;
    const primary = getPrimaryParticipant(errand)?.userData?._id;
    let wf, id;

    if (participant()?.primary) {
      // The user is primary so we need to send workflows for primary
      if (participant().userData?.userId?.length > 0 || participant().userData?.webUserId?.length > 0) {
        // The user has authenticated through SunSoft before so we should use the authenticate workflow
        wf = await axiosCall({
          url: `workflow/db/search?active=true&fields=_id,active`,
          method: 'post',
          data: { search: 'Unblur History' },
        }, { signal: abortController?.signal });
        id = wf.filter((w) => w.active)[0]?._id;
        if (id?.length === 24) {
          await axiosCall({
            url: `chat/${errand?._id}/workflow/${id}`,
            method: 'POST',
            data: {
              userId: _id,
              userType: 'User',
              owner: primary,
            },
          }, { signal: abortController?.signal });
        }
      } else {
        // This user has not authenthenicated through SunSoft yet so we should use the OTP unblur workflow
        wf = await axiosCall({
          url: `workflow/db/search?active=true&fields=_id,name,active`,
          method: 'post',
          data: { search: 'Allow History' },
        }, { signal: abortController?.signal });
        id = wf.filter((w) => w.active && w.name === 'Allow History')[0]?._id;
        await axiosCall({
          url: `chat/${errand?._id}/workflow/${id}`,
          method: 'POST',
          data: {
            userId: _id,
            userType: 'User',
            owner: primary,
          },
        }, { signal: abortController?.signal });
      }
    } else {
      wf = await axiosCall({
        url: `workflow/db/search?active=true&fields=_id,name,active`,
        method: 'post',
        data: { search: 'Approve Unblur History' }
      }, { signal: abortController?.signal });
      id = wf.filter((w) => w.active && w.name === 'Approve Unblur History')[0]?._id;
      await axiosCall({
        url: `chat/${errand?._id}/workflow/${id}`,
        method: 'POST',
        data: {
          owner: primary,
          userId: _id,
          userType: (isOperator ? 'Operator' : 'User'),
          intendedAudience: [primary],
        }
      }, { signal: abortController?.signal });
    }
    console.log('request history sent', wf);
  };

  const messageHistoryAllowedBoolean: boolean = useMemo(()=> {
    const result = participant()?.messageHistoryAllowed;

    if (typeof result === 'boolean') {
      return result;
    }

    // default value
    return true;
  }, [participant]);

  useEffect(() => {
    if (!errand?._id || !isMessagesConnected) return;

    const abortController = new AbortController();

    setUpNewChat(abortController);

    return () => {
      abortController?.abort();
      // clear messages to prevent the errands object from growing too large.
      rootContext.setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === errand?._id);

        if (chatObj) {
          if (isPrivate) {
            chatObj.privateMessages = [];
          } else {
            chatObj.messages = [];
          }
        }

        return [...prev];
      });
    }
  }, [errand._id, isMessagesConnected, rootContext.viewReload, messageHistoryAllowedBoolean]);

  useEffect(() => {
    if (!isMessagesConnected) return;

    const onInternalMesageUpdate = (payload) => {
      console.log(`Messages Socket - ConversationBody - (internal-message-update)`, payload);
      rootContext.setErrands((prev) => {
        // logic changed as find returns null rather than undefined and this keeps the logic congruent with other states.
        const chatObj = prev.find((e) => e._id === payload.data.chat);

        if (chatObj) {
          const messageIndex = (chatObj.messages || []).findIndex((m) => m._id === payload.data._id);
          if (messageIndex) {
            console.error(`internal-message-update at messageIndex ${messageIndex}`);
            if (isOperator) {
              chatObj.messages[messageIndex].accessType = AccessType.internal;
              return [...prev];
            }
            // const messages = chatObj.messages;
            chatObj.messages = (chatObj.messages || []).filter((m) => m._id !== payload.data._id);
            return [...prev];
          }
          const privateMessageIndex = (chatObj.privateMessages || []).findIndex((m) => m._id === payload.data._id);
          if (privateMessageIndex !== -1) {
            console.error(`internal-message-update at privateMessageIndex ${privateMessageIndex}`);
            if (isOperator) {
              chatObj.privateMessages[privateMessageIndex].accessType = AccessType.internal;
              return [...prev];
            }
            chatObj.privateMessages = (chatObj.privateMessages || []).filter((m) => m._id !== payload.data._id);
            return [...prev];
          }
        }

        console.error(`internal-message-update index not found`);
        return prev;
      });
    };

    // Register to receive real time read status on this chat
    const onMessageStatusUpdate = (payload) => {
      console.log(`Messages Socket - ConversationBody - (message-status-update)`, payload);
      rootContext.setErrands((prev) => {
        // logic changed as find returns null rather than undefined and this keeps the logic congruent with other states.
        const chatObj = prev.find((e) => e._id === payload.data.chat);

        if (chatObj) {
          const messages = isPrivate ? chatObj.privateMessages : chatObj.messages;

          if (messages) {
            const messageObj = messages.find((m) => m._id === payload.data.messageId);

            if (messageObj) {
              // update notifications for specified message
              messageObj.notifications = [payload.data];
            }
          }
        }

        return [...prev];
      });
    };

    const onUserNameUpdate = (payload) => {
      console.log(`Messages Socket - ConversationBody - (user-name-update)`, payload);

      const data = payload?.data;
      const userId = data?._id;
      const chatId = data?.chat;

      rootContext.setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === errand?._id);

        if (chatObj) {
          if (chatObj._id === chatId) {
            const messages = isPrivate ? chatObj.privateMessages : chatObj.messages;

            // for each message update the name if needed
            for (const msg of messages || []) {
              if (msg?.sender?._id === userId) {
                msg.sender = { ...msg.sender, ...data };
              }
            }
          }
        }

        return [...prev];
      });
    };

    console.log('Messages Socket - ConversationBody - (on)');
    messagesSocket.current?.on('internal-message-update', onInternalMesageUpdate);
    messagesSocket.current?.on('message-status-update', onMessageStatusUpdate);
    messagesSocket.current?.on('user-name-update', onUserNameUpdate);
    return () => {
      console.log('Messages Socket - ConversationBody - (off)');
      messagesSocket.current?.off('internal-message-update', onInternalMesageUpdate);
      messagesSocket.current?.off('message-status-update', onMessageStatusUpdate);
      messagesSocket.current?.off('user-name-update', onUserNameUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errand._id]);

  // Triggers everytime the message filter is updated
  useEffect(() => {
    // If initial messages are not loaded yet, do nothing.
    if(initialMessagesAreLoadedRef.current === false) return;
    setIsInitialLoad(true);
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errand?._id);

      if (chatObj) {
        if (isPrivate) {
          chatObj.privateMessages = [];
        } else {
          chatObj.messages = [];
        }
      }

      return [...prev];
    });
    const abortController = new AbortController();
    loadMoreMessages(abortController, true);
    return () => {
      abortController?.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageFilter]);

  // Triggers everytime the triggerLoadMore is updated
  useEffect(() => {
    if (triggerLoadMore) {
      const abortController = new AbortController();
      loadMoreMessages(abortController, false);
      return () => {
        abortController?.abort();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerLoadMore]);

  // For proper disclaimer rendering
  // useEffect(() => {
  //   if (filteredMessages[0]?.messageType === 'Disclaimer') {
  //     const getDisclaimerMessageHeight = () => disclaimerHeight || 0;
  //     const getScrollboxContainerTopOffset = () => scrollBoxContainerRef.current?.getBoundingClientRect()?.top || 0;
  //     const getBodyRefTopOffset = () => bodyRef.current?.getBoundingClientRect()?.top || 0;

  //     setIsDisclaimerSeparate((prev) => {
  //       // Y coordinate responsible for setting the disclaimer to render separately.
  //       let scrollboxThreshold = getDisclaimerMessageHeight() + getScrollboxContainerTopOffset() + 20;
  //       // Top of bodyRef plus disclaimer message height as needed
  //       let scrollBoxTopOffset = getBodyRefTopOffset();
  //       // if disclaimer is in chat, offset should be increased by disclaimer height
  //       if(!prev) {
  //         scrollBoxTopOffset += getDisclaimerMessageHeight();
  //       }
  //       // default value to help on initial load
  //       if (scrollBoxTopOffset === 0) {
  //         scrollBoxTopOffset = 301;
  //       }

  //       // criteria for isDisclaimerSeparate made more readable
  //       let isOffsetGreaterThanThreshhold = scrollBoxTopOffset > scrollboxThreshold;
  //       let isFirstMessageDisclaimer = filteredMessages?.[0]?.messageType === 'Disclaimer';
  //       let isDispFilterNone = 'NONE' === dispFilterMode.toUpperCase();
  //       let isNotSearching = !filteredMessages?.[0]?.searchWords;
  //       return isOffsetGreaterThanThreshhold && isFirstMessageDisclaimer && isDispFilterNone && isNotSearching;
  //     });
  //   }
  //   // do not put ignore exhaustive deps, we want them to ensure this is always rendered properly.
  // }, [filteredMessages, dispFilterMode, windowDimensions, disclaimerHeight]);

  /* End the edit message process when clicking anywhere else on the screen */
  useEffect(() => {
    if (editMessageId) {
      const onClick = (event) => {
        if (!errandContext?.footerRef?.current?.contains(event.target) && errandContext?.boundaryRef?.current?.contains(event.target)) {
          errandContext.setMorphType(MorphType.None);
          setEditMessageId('');
          setValue('');
          // changes: icon, placeholder and action.
          rootContext.setErrands((prev) => {
            const chatObj = prev.find((e) => e._id === errand?._id);

            if (chatObj) {
              // Reset the footer input state in currErrand state obj by found index.
              ResetFooterUserAction(chatObj);
            }

            // return copy.
            return [...prev];
          });
        }
      };

      document.addEventListener('mousedown', onClick);
      return () => {
        document.removeEventListener('mousedown', onClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMessageId]);

  //Consolidated functions for handling translated
  // messages, and useEffect used for checking for when
  // messages have been updated
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
        // Replace Ref with an undefined check to skip renders when language isn't defined yet
        if(i18n.language !== undefined && !isLoading){
          //Handle/get info for translating messages
          setIsInitialLoad(true);
        
          const loadedMessages: IMessage[] = await loadMessages(
            {
              url: `chat/${errand?._id}/message` +
                `?order=desc&orderBy=createdAt&limit=${LIMIT}&batchOrder=asc` +
                `&internal=${isOperator ? true : false}` +
                `${isPrivate && errand.recipients?.length > 0 
                  ? `&intendedAudience=${errand.recipients.sort().join(',')}`
                  : ''}`,
            },
            _id,
            errand?._id,
            errand.type,
            isOperator || isPrivate || participant()?.messageHistoryAllowed,
            isOperator,
            undefined,
            abortController
          );

          if (ValidatorFunctions.isNotEmptyArray(loadedMessages)) {
            rootContext.setErrands((prev) => {
              const chatObj = prev.find((e) => e._id === errand._id);

              if (chatObj) {
                if (isPrivate) {
                  chatObj.privateMessages = loadedMessages;
                } else {
                  chatObj.messages = loadedMessages;
                }
  
                chatObj.lastMessageData = loadedMessages[loadedMessages.length - 1];
                
                hasMoreMessages.current = loadedMessages?.length === LIMIT;

                setIsInitialLoad(false);
              }

              return [...prev];
            });
          }
        }
      })();
    
    return () => {
      abortController?.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const renderDisclaimerMessage = () => {
    if (!errand || !errand.messages || errand.messages.length === 0) {
      return null;
    }
    if (errand.messages[0].messageType !== 'Disclaimer'){
      return null;
    }

    return (
      <ConversationMessage
        errand={errand}
        index={0}
        showDate={false}
        showSentiment={false}
      />
    )
  };

  /**
   * Conversation Body hide condition, we can hide/show conversation body with this logic
   * @returns true/false
   * true - hide Conversation Body
   * false - show Conversation Body
   */
  const conversationBodyHideCondition = (): boolean => {
    // User Message hide condition
    if (errand.type === ChatType.Form && errandContext.formBodySize !== FormBodySizeType.Small) {
      return true;
    }
    return false;
  }

  const handleLoadMore = useCallback(() => {
    if(initialMessagesAreLoadedRef.current === false) return;
    if(dispFilterMode.toUpperCase() === 'NONE') {
      // @ts-ignore
      setTriggerLoadMore((_) => true);
    }
  }, [dispFilterMode, setTriggerLoadMore])

  return (
    <MessageContext.Provider value={{ bodyRef, isPrivate: isPrivate, setPreviewUrl: setPreviewUrl, isDisclaimerSeparate, editMessageId, setDisclaimerHeight, disclaimerHeight, hasMoreMessages }}>
    <Stack className={isPrivate ? Styles.isPrivate : ''} ref={scrollBoxContainerRef} sx={{ display: conversationBodyHideCondition() ? 'none' : 'flex', position: 'relative', height: '100%', width: '100%', overscrollBehavior: 'none' }}>
      {/* Conditional logic for rendering the ElectronicDocument to the ConversationBody */}
      {filteredMessages ? (
        <>
        <ScrollHandler action={errand.action} isSearching={Boolean(errand.messages?.[0]?.searchWords)} operatorData={operatorData} isPrivate={isPrivate} errand={errand}>
        {triggerLoadMore && <MessagesSkeletonLoaderSmall />}
        {filteredMessages.length > 0 ? (
          <InfiniteScroll
            className={Styles.infiniteScroll}
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={
              (isOperator || participant()?.messageHistoryAllowed) &&
              errand?._id?.length === 24 &&
              !isInitialLoad &&
              !isLoading &&
              hasMoreMessages.current
            }
            useWindow={false}
            threshold={50}
            // Icy 6/8/23: Commented out this trigger because loader is already being checked to render earlier
            // loader={
            //   triggerLoadMore && <MessagesSkeletonLoaderSmall key={errand?.messages?.length + 1}/>
            // }
            isReverse={true}
          >
            {/* Filter the messages to not display system type messages. We do, however, use the messages so we need to retrieve messages matching { sender: user, messageType: action, accessType: system } to update the footer. - Jaden 07/01/24 */}
            {filteredMessages?.map((message: IMessage, index, array) => {
              // 2024/10/07 - bold: Index mismatches if there are null _id messages, so we must do true index all the time
              index = getTrueIndex(message._id);

              const hideFieldAttribute = message?.action?.fieldAttribute?.description === 'ENVELOPE';

              if (!message || message.accessType === AccessType.system || hideFieldAttribute) {
                return <div key={index} />;
              }

              if (message.messageType === 'Errand' && message.operatorView) {
                return <div key={index} />;
              }

              // ON DISCLAIMER MESSAGE return empty div if disclaimer needs to be shown separately else just render it.
              if (isDisclaimerSeparate && message.messageType === 'Disclaimer') {
                return <div key={index} />;
              }

              // Check if the pevious date is the same as today
              const showDate =
                !datesAreOnSameDay(previousDate, message?.createdAt) &&
                !(index === 1 && array[0].messageType === 'Disclaimer');

              // update the previous  date to this message's createdAt date
              previousDate = message?.createdAt;

              if (!message?.middleButton) {
                // Check to see if this message should be blurred. If any
                // message is to be blurred, we should avoid rendering
                // the searchbar until the entire converstaion history
                // is allowed.

                return (
                  <ConversationMessage
                    key={message?._id}
                    errand={errand}
                    index={index}
                    showDate={showDate}
                    showSentiment={showSentiment}
                  />
                );
              } else {
                return !message.visible && !isPrivate ? (
                  <Divider
                    key={index}
                    sx={{
                      '&::before, &::after': {
                        borderColor: theme.palette.orange['600'],
                        borderBottomWidth: 5,
                      },
                    }}
                  >
                    <IconButton onClick={handleClickRequestHistory}>
                      <Stack alignItems="center">
                        <QuestionAnswerIcon sx={{ color: theme.palette.orange['600'] }} fontSize="large" />
                        <Chip
                          label={participant()?.primary ? t('requestUnblur') : t('requestUnblurPermission')}
                          sx={{
                            backgroundColor: theme.palette.orange['600'],
                            color: 'var(--gray000)',
                          }}
                        />
                      </Stack>
                    </IconButton>
                  </Divider>
                ) : (
                  <span key={index}></span>
                );
              }
            })}
            {showAnalyzingAngel && <AnalyzingAngel />}
          </InfiniteScroll>
        ) : (getUserConsent() === 'true' || operatorData) && (isInitialLoad || triggerLoadMore || isLoading || rootContext.messagesAreLoading)? (
          <MessagesSkeletonLoader />
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              height: '60px',
              width: '100%',
            }}
          >
            <Paper sx={{ p: 1.5 }}>
              <Typography> {t('noMessages')} </Typography>
            </Paper>
          </Stack>
            )}
        </ScrollHandler>
        {/* Render Disclaimer Separately Logic */}
        {/* isDisclaimerSeparate === true */}
        {isDisclaimerSeparate && (renderDisclaimerMessage())}
      </>
      ) : (
        <Stack key={errand?.messages?.length + 1} justifyContent="center" alignItems="center" height="100%" />
      )}
    </Stack>
    </MessageContext.Provider>
  );
});

export default ConversationBody;