/**
 * @file This component is a stylized Button component
 * for skipping actions, from the SKIPPABLE field attribute.
 * 
 * @author Hunter Gordon
 */

import React, { useRef } from 'react';
import axiosCall from '@services/axios';
import { Button, Divider } from "@mui/material"
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import ErrorBoundary from "@components/ErrorBoundary";
import { MorphType } from '@common/MorphType';
import { useErrandContext } from '@contexts/ErrandContext';
import { useUserContext } from '@contexts/user';

const Skip = styled(Button)({
  border: "1px solid var(--peach900)",
  height: "25px",
  padding: "5px",
  textAlign: "center",
  marginLeft: "5px",
  minWidth: "max-content",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "var(--peach900)",
    border: "1px solid var(--gray000)",
    color: "var(--gray000)"
  },
  textTransform: "none"
});

const Line = styled(Divider)(({ theme }) => ({
  height: '25px',
  margin: '0 7px',
  backgroundColor: theme.palette.orange['600'],
}));


const SkipButton = (props) => {
  const { t } = useTranslation();
  const { _id } = useUserContext();
  // useRef used for preventing too many repeated network calls by rapidly clicking skip button
  // Make be converted into a timer, or something that further increases time required in between action requests
  const isSendingAction = useRef(false);
  const errandContext = useErrandContext();

  const handleOnClick = async () => {

    if (isSendingAction.current) {
      console.log(`Cannot submit action, waiting for request to complete.`);
      return;
    }

    if (!props.errand?.action?.action?.fieldName) {
      console.info(`Cannot generate skip button: No action fieldName`);
      return;
    }

    if (!props.errand._id || props.errand._id === '') {
      return;
    }

    let userId = _id;
    if (!userId || userId === '') {
      return;
    }

    if (!props.errand.recipients || props.errand.recipients.includes(null) === true) {
      return;
    }

    isSendingAction.current = true;

    errandContext.setMorphType(MorphType.None);

    const data = await axiosCall({
      url: `field/create`,
      method: 'POST',
      data: {
        active: true,
        chat: props.errand._id,
        name: props.errand.action.action.fieldName,
        value: 'skipped'
      }
    });
    if (data){
      await axiosCall({
        url: `useraction/${props.errand.action.userActionId}`,
        method: 'PUT',
        data: {
          status: 'resolved' //proceed to the next action in the WF.
        }
      });
    }

    isSendingAction.current = false;
    props.resetStates();

  }

  return <ErrorBoundary debug={`ResentOTPButton: ${JSON.stringify(props)}`}>
    <Skip onClick={handleOnClick}>{t('tSkip')}</Skip>
    <Line orientation='vertical' />
  </ErrorBoundary>
}

export default SkipButton;