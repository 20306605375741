import { IMessage } from "@interfaces/Conversation";
import { ValidatorFunctions } from "./Validators";

const copyObj = (obj) => {
  if(objHasFunction(obj)) {
    console.error('Not able to copy given object, because it has a function inside.')
  } else {
    return JSON.parse(JSON.stringify(obj));
  }
}

function objHasFunction(obj) {
  if (typeof obj === 'function') {
    return true;
  }

  if (typeof obj === 'object' && obj !== null) {
    for (let key in obj) {
      if (objHasFunction(obj[key])) {
        return true;
      }
    }
  }

  return false;
}

const filters = {
  isSenderUser: (msg) =>  msg.senderType === 'User'
}

/**
 * Determine whether the conversation footer should change for the current user.
 * Only users that are part of the intendedAudience should respond to actions.
 * 
 * @param message the message object
 * @returns {boolean}
 */
const shouldCurrUserReplyTo = (message: IMessage, _id: string): boolean => {
  try {
    if (!message || !message.userId) return false;
    if (message.operatorView 
      || (message.messageType !== 'Action' 
        && message.messageType !== 'UserPromptsMenu' 
        && message.messageType !== 'VideoListMenu')) 
    {
          return false;
    }
    // check the userAction owner to be equal to current user id.
    if (message.userAction.owner === _id) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(`userMessagesUtils.shouldCurrentUserReplyTo.catch: `, error?.stack || error?.message);
    return false;
  }
}


const isMessageOfTypeAction = (msg) => {
  if(msg.messageType === "Action") {
    return true;
  } 

  return false;
}

const getLastMsg = (chatMessages) => {
  return chatMessages[chatMessages.length - 1];
}

const isUserActionLastInChat = (userActionId, chatMessages) => {
  const lastMsgUserActionId = getLastMsg(chatMessages)?.userAction?._id;

  if(lastMsgUserActionId === undefined || lastMsgUserActionId === null) {
    return false;
  }

  if(lastMsgUserActionId === userActionId) {
    return true;
  }

  return false;
}

const isMessageOfType = (msg: IMessage, msgType: string) => {
  if(msg.messageType === msgType) {
    return true;
  }

  return false;
}

const strRelatedToSlotMachine = (str: string) => {
  if(!str || str === '' || typeof str !== 'string') {
    return false;
  }
  return str.toLowerCase().includes('slot machine')
}

const isSlotMachineRelatedAction = (action) => {
  if(action && action?.description && action?.description !== '' && ValidatorFunctions.isTypeOfString(action?.description)) {
    if(strRelatedToSlotMachine(action.description)) {
      return true;
    }
  }
  return false;
}

const isSlotMachineRelatedMsg = (msg: IMessage) => {
  return isSlotMachineRelatedAction(msg?.action) && strRelatedToSlotMachine(msg?.placeholder);
}

const findSlotMachineActionMessageIn = (msgArr: IMessage[]): IMessage => {
  return msgArr.find((msg) => isMessageOfType(msg, 'Action') && isSlotMachineRelatedMsg(msg));
}

const findMessageOfTypeWithin = (msgArr: IMessage[], msgType: string) => {
  return msgArr.find((msg) => isMessageOfType(msg, msgType));
}

const findMessageOfTypeOrder = (msgArr: IMessage[]) => {
  return findMessageOfTypeWithin(msgArr, 'Order');
}

const findMessageOfTypePropertyListing = (msgArr: IMessage[]) => {
  return findMessageOfTypeWithin(msgArr, 'PropertyListing');
}

const datesAreOnSameDay = (first, second) => {
  if (!first || !second) return false;
  first = new Date(first);
  second = new Date(second);
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

export { shouldCurrUserReplyTo, copyObj, isMessageOfTypeAction, getLastMsg, isUserActionLastInChat, findMessageOfTypeOrder, findMessageOfTypePropertyListing, findSlotMachineActionMessageIn, isSlotMachineRelatedAction, datesAreOnSameDay }