import { IMessage } from '@interfaces/Conversation';
import React from 'react';
const ActionMessageContent = React.lazy(() => import('@components/MessageContent/ActionMessageContent'));
const AudioMessageContent = React.lazy(() => import('@components/MessageContent/AudioMessageContent'));
const CalculatorResultMessageContent = React.lazy(
  () => import('@components/MessageContent/CalculatorResultMessageContent')
);
const CouponMessageContent = React.lazy(() => import('@components/MessageContent/CouponMessageContent'));
const DeletedMessageContent = React.lazy(() => import('@components/MessageContent/DeletedMessageContent'));
const DisclaimerMessageContent = React.lazy(() => import('@components/MessageContent/DisclaimerMessageContent'));
const DocumentMessageContent = React.lazy(() => import('@components/MessageContent/DocumentMessageContent'));
const CustomLinkMessageContent = React.lazy(() => import('@components/MessageContent/CustomLinkMessageContent'));
const AppointmentMessageContent = React.lazy(() => import('@components/MessageContent/AppointmentMessageContent'));
const CreditRepairWelcomeMessageContent = React.lazy(() => import('@components/MessageContent/CreditRepairWelcomeMessageContent'));
const DocumentFieldMessageContent = React.lazy(() => import('@components/MessageContent/DocumentFieldMessageContent'));
const ErrandMessageContent = React.lazy(() => import('@components/MessageContent/ErrandMessageContent'));
const FieldMessageContent = React.lazy(() => import('@components/MessageContent/FieldMessageContent'));
const ImagingUploadStatusMessageContent = React.lazy(
  () => import('@components/MessageContent/ImagingUploadStatusMessageContent')
);
const InviteMessageContent = React.lazy(() => import('@components/MessageContent/InviteMessageContent'));
const LockConfirmationMessageContent = React.lazy(
  () => import('@components/MessageContent/LockConfirmationMessageContent')
);
const NotificationMessageContent = React.lazy(() => import('@components/MessageContent/NotificationMessageContent'));
const OrderMessageContent = React.lazy(() => import('@components/MessageContent/OrderMessageContent'));
const PermissionRejectedMessageContent = React.lazy(
  () => import('@components/MessageContent/PermissionRejectedMessageContent')
);
const PrivateMessageContent = React.lazy(() => import('@components/MessageContent/PrivateMessageContent'));
const PropertyListingMessageContent = React.lazy(
  () => import('@components/MessageContent/PropertyListingMessageContent')
);
const ReferrerMessageContent = React.lazy(() => import('@components/MessageContent/ReferrerMessageContent'));
const SignatureConfirmationMessageContent = React.lazy(
  () => import('@components/MessageContent/SignatureConfirmationMessageContent')
);
const SlotMessageContent = React.lazy(() => import('@components/MessageContent/SlotMessageContent'));
const SongMessageContent = React.lazy(() => import('@components/MessageContent/SongMessageContent'));
const TcpaMessageContent = React.lazy(() => import('@components/MessageContent/TcpaMessageContent'));
const TextMessageContent = React.lazy(() => import('@components/MessageContent/TextMessageContent'));
const TipsMessageContent = React.lazy(() => import('@components/MessageContent/TipsMessageContent'));
const VideoListMenuMessageContent = React.lazy(() => import('@components/MessageContent/VideoListMenuMessageContent'));
const UserPromptsMenuMessageContent = React.lazy(
  () => import('@components/MessageContent/UserPromptsMenuMessageContent')
);
const UrlMessageContent = React.lazy(() => import('@components/MessageContent/UrlMessageContent'));
const VideoMessageContent = React.lazy(() => import('@components/MessageContent/VideoMessageContent'));
const LoanProductComparisonMessageContent = React.lazy(
  () => import('@components/ChooseLoanProduct/LoanProductComparisonMessageContent')
);
const LoanConditionsLinkContent = React.lazy(() => import('@components/MessageContent/LoanConditionsLinkContent'));
const ProfileBubbleMessageContent = React.lazy(
  () => import('@components/MessageContent/ProfileBubbleMessageContent/ProfileBubbleMessageContent')
);
const CreditRepairDisputeAccountsContent = React.lazy(
  () => import('@components/MessageContent/CreditRepairDisputeAccountsContent')
);
const DownloadTheAppMessageContent = React.lazy(
  () => import('@components/MessageContent/DownloadTheAppMessageContent')
);
const RatePricingResultMessageContent = React.lazy(
  () => import('@components/MessageContent/RatePricingResultMessageContent')
);

export const MessageTypeSelector = React.memo(
  ({ message, componentTypeProps }: { message: IMessage; componentTypeProps: any }) => {
    const { ErrandProp, MessageProp, ErrandMessageProps, IndexMessageProps, ErrandIndexMessageProps } =
      componentTypeProps;
    switch (message?.messageType) {
      case 'Action':
        switch (message?.action?.description) {
          case 'Slot Machine':
            return <SlotMessageContent {...ErrandMessageProps} />;
          case 'TCPA':
            return <TcpaMessageContent {...ErrandMessageProps} />;
          default:
            return <ActionMessageContent {...ErrandIndexMessageProps} />;
        }
      case 'Audio':
        return <AudioMessageContent {...ErrandMessageProps} />;
      case 'CalculatorResult':
        // @ts-ignore @hunter Please fix this ts error regarding conflicting message types.
        return <CalculatorResultMessageContent {...ErrandMessageProps} />;
      case 'Coupon':
        return <CouponMessageContent {...MessageProp} />;
      case 'Disclaimer':
        return <DisclaimerMessageContent {...MessageProp} />;
      case 'Document':
        return <DocumentMessageContent {...ErrandMessageProps} />;
      case 'CustomLink':
        return <CustomLinkMessageContent {...ErrandMessageProps} />;
      case 'Appointment':
        return <AppointmentMessageContent {...ErrandMessageProps} />;
      case 'CreditRepairWelcome':
      case 'RefinanceCalculatorWelcome':
      case 'CalculatorsWelcome':
        return <CreditRepairWelcomeMessageContent {...ErrandMessageProps} />;
      case 'Errand':
        return <ErrandMessageContent {...ErrandProp} />;
      case 'Field':
        if (message.documents) return <DocumentFieldMessageContent {...ErrandMessageProps} />;
        return <FieldMessageContent {...MessageProp} />;
      case 'HelpTip':
      case 'HelpTipGroup':
        // @ts-ignore @eric Please fix this ts error regarding conflicting message types.
        return <TipsMessageContent {...MessageProp} />;
      case `ImagingUploadStatus`:
        return <ImagingUploadStatusMessageContent {...ErrandMessageProps} />;
      case 'Invite':
        // @ts-ignore @Icy Please fix this ts error regarding conflicting message types.
        return <InviteMessageContent {...MessageProp} />;
      case 'LockConfirmation':
        // @ts-ignore @eric Please fix this ts error regarding conflicting message types.
        return <LockConfirmationMessageContent {...MessageProp} />;
      case 'Notification':
        return <NotificationMessageContent {...MessageProp} />;
      case 'Order':
        return <OrderMessageContent {...MessageProp} />;
      case 'PermissionRejected':
        return <PermissionRejectedMessageContent />;
      case 'PropertyListing':
        return <PropertyListingMessageContent {...ErrandMessageProps} />;
      case 'Referrer':
        return <ReferrerMessageContent {...ErrandMessageProps} />;
      case 'SignatureConfirmation':
        return <SignatureConfirmationMessageContent {...IndexMessageProps} />;
      case 'Song':
        return <SongMessageContent {...ErrandIndexMessageProps} />;
      case 'Text':
      case 'Greeting':
        return <TextMessageContent {...ErrandMessageProps} />;
      case 'Url':
        return <UrlMessageContent {...MessageProp} />;
      case 'Video':
        return <VideoMessageContent {...ErrandMessageProps} />;
      case 'LoanProductComparison':
        return <LoanProductComparisonMessageContent {...MessageProp} />;
      case 'LoanConditions':
        return <LoanConditionsLinkContent {...MessageProp} />;
      case 'VideoListMenu':
        return <VideoListMenuMessageContent {...ErrandMessageProps} />;
      case 'UserPromptsMenu':
        return <UserPromptsMenuMessageContent {...ErrandMessageProps} />;
      case 'RateInfo':
        return <TextMessageContent {...ErrandMessageProps} />;
      case 'ProfileBubble':
        return <ProfileBubbleMessageContent {...MessageProp} />;
      case 'CreditRepairDisputeAccounts':
        return <CreditRepairDisputeAccountsContent {...MessageProp} />
      case 'DownloadTheApp':
        return <DownloadTheAppMessageContent {...ErrandMessageProps} />
      case 'RatePricingResult':
        return <RatePricingResultMessageContent {...ErrandMessageProps} />
      default:
        return <></>;
    }
  }
);
