/**
 * @file This component is a stylized Box component
 * for styling the Conversation Title Component.
 *
 * @author Quinley James
 */
 import { GroupIcon, InviteFriend } from '@assets/Icons';
import React, { PropsWithChildren } from 'react';
import { Box, styled } from '@mui/system';
import {
  Button,
  Typography,
} from '@mui/material';

export const StyledTitleContainer: React.FC<PropsWithChildren<any>> = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDesktop' && prop !== 'isWidget'
})<any>(({ theme, isDesktop, isWidget }) => `
  display: grid;
  justify-content: space-between;
  alignitems: center;
  grid-template-columns: 1fr auto;
  min-height: 56px;
  height: 56px;
  zIndex: 1;
  gap: 5px;
  border-bottom-left-radius: 25px;
  position: relative;
  padding: 0 20px 0 20px;
  background-color: var(--peach900);
  border-top-right-radius: ${(isDesktop || isWidget) ? '8px' : '0'};
  border-top-left-radius: ${(isDesktop || isWidget) ? '8px' : '0'};
  margin-top: -1px;
  .CTSearchBar {
    background-color: var(--peach000);
    margin-right: 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    width: 70%;
    height: 25px;
    .CTSearchIcon {
      color: var(--orange600);
      width: .75em;
      height: .75em;
    }
    .CTInputBase {
      flex: 1;
      font-size: 16px;
      margin-top: 4px;
      '&::placeholder': { color: var(--gray950)) };
    }
  }
  .CTClosedSearch {
    color: var(--gray000);
    .CTIconButton {
      padding: 8px;
      min-width: 24px;
      min-height: 24px;
      color: var(--blue999);
    }
    .CTToolTip {
      .CTIconButton {
        padding: 8px;
        min-width: 24px;
        min-height: 24px;
        color: var(--blue999);
      }
    }
  }
  .CTOpenInNewIcon {
    margin-right: 15px;
  }
  .CTSmlsIcon {
    width: 20px; 
    height: 20px; 
    padding-bottom: 1px;
  }
  .CTJoinConvoBtn {
    background-color: var(--gray000);
    height: 30px;
    font-size: 14px;
    white-space: nowrap;
    min-width: auto;
    :hover {
      background-color: var(--gray000);
    };
  }
  .CTRestoreConvoBtn {
    background-color: var(--gray000);
    height: 30px;
    font-size: 14px;
    white-space: nowrap;
    min-width: auto;
    :hover {
      background-color: var(--gray000);
    }
  }
  .CTAuthShield {
    min-width: 21px;
    width: auto;
    height: 25px;
  }
  .CTNotAuthShield {
    min-width: 21px;
    width: auto;
    height: 25px;
    cursor: pointer;
  }
`);

export const StyledInviteIcon = styled(InviteFriend)(`
  color: var(--blue999);
  cursor: pointer;
  width: 24px;
  height: 24px;
  min-width: 1em;
  min-height: 1em;
`);

export const StyledGroupIcon = styled(GroupIcon)(`
  color: var(--blue999) !important;
  cursor: pointer;
  width: 24px;
  height: 24px;
  min-width: 1em;
  min-height: 1em;
`);

export const StyledName = styled(Typography)(`
  color: var(--blue999);
  white-space: nowrap;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: capitalize;
`);

export const StyledNameButton: React.FC<PropsWithChildren<any>> = styled(Button)(`
  color: var(--blue999);
  white-space: nowrap;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  border-left: none;
  text-transform: capitalize;
  min-height: 0;
  min-width: 0;
  padding: 0;
`);
