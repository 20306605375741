import { useErrandContext } from '@contexts/ErrandContext';
import { IMessage } from '@interfaces/Conversation';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import tStyles from '@styles/Typography.module.css';

type TMessageDate = {
  message: IMessage,
}

const MessageDate = ({
  message
}: TMessageDate) => { 
  const { i18n } = useTranslation();
  const errandContext = useErrandContext();
  const messageDateRef = useRef(null);

  const machineDate = useMemo(() => {
    if (!message.createdAt) return '';
    try {
      return new Date(message.createdAt).toISOString();
    } catch {
      return '';
    }
  }, [message.createdAt]);

  const messageDate = useMemo(() => {
    if (!message.createdAt) return '';
    try {
      return new Date(message.createdAt).toLocaleDateString(i18n.language, {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    } catch {
      return '';
    }
  }, [message.createdAt, i18n.language]);

  // add ref to timeline as needed
  useEffect(() => {
    if (message?.searchWords && messageDateRef.current !== null) {
      const array = (errandContext.searchRefs.current || []);
      array.push({ _id: message?._id, htmlElement: messageDateRef.current as HTMLDivElement });
      array.filter((v, i, a) => a.findIndex((r) => r._id === v._id) === i);
      errandContext.searchRefs.current = array;
    }
  }, [message?.searchWords]);

  return (
    <time
      ref={messageDateRef}
      dateTime={machineDate}
      className={[
        tStyles.base,
        tStyles.taCenter,
        tStyles.mbSmall,
        tStyles.mtLarge,
        tStyles.mAuto,
        tStyles.fsSmall,
        tStyles.lhNarrow
      ].join(' ')}
    >
      {messageDate}
    </time>
  )
}

export default MessageDate;