import React from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import ErrorBoundary from '@components/ErrorBoundary';
import Styles from "../Styles/AttachmentMenuCloseButtonStyles.module.css";
import { useErrandContext } from '@contexts/ErrandContext';
import Fade from '@mui/material/Fade';
import { MorphType } from '@common/MorphType';
import { IErrand } from '@interfaces/Conversation';

/*
 *  This component makes up the individual conversation badges that are visible
 * in all tabs of the console conversations page.
 */
type TAttachmentMenuCloseButtonProps = {
  errand: IErrand;
  setSelectedFiles: (files: File[]) => void;
  cancelAction: (key: any, clear?: boolean, withMorphTypeChange?: boolean) => Promise<void>;
}

// define default parameters to prevent crashing without the need of optional operators
const AttachmentMenuCloseButton = ({
  errand, setSelectedFiles, cancelAction
}: TAttachmentMenuCloseButtonProps) => {
  const errandContext = useErrandContext();

  const handleXButtonClick = () => {
    setSelectedFiles([]);
    if (errand.action) {
      cancelAction(null, true, false);
    }
    errandContext.setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });
  };

  return (
    <ErrorBoundary debug={`AttachmentMenuCloseButton`}>
      <Fade in={true} appear={true} timeout={1000}>
        <button 
        onClick={handleXButtonClick} 
        className={`
        ${Styles.attchMenuBtnWrapper} 
        ${errandContext.morphType === MorphType.VideoListMenu && Styles.attchMenuRedBtnWrapper} 
        ${errandContext.morphType === MorphType.UserPromptsMenu && Styles.attchMenuBlueBtnWrapper} 
        ${errandContext.morphType === MorphType.CreditRepairDisputeAccountType && Styles.attchMenuDarkBlueBtnWrapper} 
        ${errandContext.morphType !== MorphType.Attachment && errandContext.isMorphedFooterCloseButtonOnLeft && Styles.left} `}>
          <HighlightOffOutlinedIcon />
        </button>
      </Fade>
    </ErrorBoundary>
  );
};

export default AttachmentMenuCloseButton;
