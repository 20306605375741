import React, { useEffect, useState, useContext, useCallback, useRef, useMemo } from 'react';

import { MorphUserPromptsMenuProps, TWorkflowData_UPmenu, UserPromptsMenuState, TEdge, Edges } from './types';
import {
  isFirstDecimalDigitCompareThan,
  parseFetchedWorkflowsData,
  _customDebounce,
  getUserStatus,
  filterByStatus,
} from './utils';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { isMobile, isMobileOrTablet } from '@common/deviceTypeHelper';
import useAbortController from '@common/hooks/useAbortController';
import Styles from '@styles/MorphUserPromptsMenu.module.css';
import { useFooterContext } from '@contexts/FooterContext';
import { useRootContext } from '@contexts/RootContext';
import UPM_TranslationModule from './translations';
import { IPrompt } from '@interfaces/Conversation';
import { useTranslation } from 'react-i18next';
import { MorphType } from '@common/MorphType';
import { Arrows } from './components/Arrows';
import { fetchSampleTextData, fetchWorkflows } from './api';
import { SwiperClass } from 'swiper/react';
import { useUserContext } from '@contexts/user';

import { SWIPER_SLIDE_WIDTH, SPACE_BETWEEN_SLIDES } from './components/constants';
import { ValidatorFunctions } from '@common/Validators';
import { MainSwiper } from './components/MainSwiper';

const getSwiperStyle = (slideListItems, isLoading, workflowWasSent) => {
  return {
    height: `${slideListItems.length === 0 || isLoading ? '45px' : workflowWasSent === true ? '0px' : '100px'}`,
    opacity: `${workflowWasSent ? '0' : '1'}`,
  };
};

const MorphUserPromptsMenu = React.forwardRef((props: MorphUserPromptsMenuProps, ref) => {
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const footerContext = useFooterContext();
  const rootContext = useRootContext();
  const prevReachedEdgeRef = useRef(null);
  const userContext = useUserContext();

  const { t, i18n } = useTranslation();
  const abortController = useAbortController();

  const [slideListItems, setSlideListItems] = useState<TWorkflowData_UPmenu[]>([]);
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
  const [workflowWasSent, setWorkflowWasSent] = useState<boolean>(false);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [focusedArrowIndex, setFocusedArrowIndex] = useState(-1);
  const [reachedEdge, setReachedEdge] = useState<TEdge>(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const tmsRef = useRef<NodeJS.Timeout[]>([]);
  const userDoubleClickedRef = useRef(false);
  const lastFocusedIndexRef = useRef(null);
  const swiperSectionRef = useRef(null);
  const [addon, setAddon] = useState(0);
  // includes tablet
  const isMobileDevice = isMobileOrTablet();

  // Used for accessing the wf id name from within the conversation footer handleSubmit handler
  const getWfIdName = () => {
    return slideListItems[swiperInstance?.activeIndex + addon].idName.replaceAll(' ', '_');
  }
  // pass it to ref to be able to access it from the other components through errandContext.moprhUserPromptsMenuRef
  React.useImperativeHandle(ref, () => ({
    getWfIdName,
  }));

  const focusElement = useCallback(
    (idx) => {
      if (ValidatorFunctions.isNotUndefinedNorNull(idx) && !isNaN(idx)) {
        const htmlEl = swiperInstance?.el?.children[0]?.children[idx] as HTMLElement;
        htmlEl?.focus();
        lastFocusedIndexRef.current = idx;
      }
    },
    [swiperInstance]
  );

  const updateRootUserSelectedPromptInfoRef = useCallback((data: IPrompt | null) => {
    if (rootContext.userSelectedPromptRef) rootContext.userSelectedPromptRef.current = data;
  }, []);

  const resetState = useCallback(
    (unmount = false) => {
      if (!swiperInstance) return;
      errandContext.setUserPromptsMenuState(UserPromptsMenuState.WORKFLOW_NOT_SELECTED);
      footerContext.sendButtonRef.current?.update('');
      updateRootUserSelectedPromptInfoRef(null);
      userDoubleClickedRef.current = false;
      setSelectedIdx(null);
      setWorkflowWasSent(false);
      if (!unmount) {
        setTimeout(() => {
          focusElement(swiperInstance?.activeIndex + addon);
        }, 0);
      }
    },
    [errandContext.setUserPromptsMenuState, setSelectedIdx, swiperInstance, focusElement, addon]
  );

  const clearTimeouts = () => {
    if (tmsRef.current.length === 0) return;
    for (const tID of tmsRef.current) {
      clearTimeout(tID);
    }
    // clear arr
    tmsRef.current = [];
  };

  const handleTabForward = useCallback(
    (disableNavigationBeyondBoundaries = false) => {
      // if reached left Edge and or beyond
      if (reachedEdge === Edges.left) {
        // we came back
        if (swiperInstance.activeIndex + addon === swiperInstance.activeIndex) {
          // remove reached edge
          setReachedEdge(null);
          return;
        }
        // we are still in beyond edge range
        // increment addon
        setAddon((prevAddonValue) => prevAddonValue + 1);
        return;
      }

      if (reachedEdge === Edges.right) {
        // at the very end
        if (swiperInstance.activeIndex + addon === slideListItems.length - 1) {
          if (disableNavigationBeyondBoundaries) return;
          setFocusedArrowIndex(1);
          return;
        }
        // increment addon value
        setAddon((prevAddonValue) => prevAddonValue + 1);
        return;
      }

      // not yet reached an edge
      swiperInstance?.slideNext();
    },
    [swiperInstance, addon, reachedEdge, slideListItems.length, setAddon, setReachedEdge, setFocusedArrowIndex]
  );

  const handleTabBackward = useCallback(
    (disableNavigationBeyondBoundaries = false) => {
      // if we reached left edge
      if (reachedEdge === Edges.left) {
        // if we already at the very start
        if (swiperInstance.activeIndex + addon === 0) {
          if (disableNavigationBeyondBoundaries) return;

          setFocusedArrowIndex(0); // focus left arrow
          return;
        }
        // decrement addon value
        setAddon((prevAddonValue) => prevAddonValue - 1);
        return;
      }

      if (reachedEdge === Edges.right) {
        if (swiperInstance.activeIndex + addon === swiperInstance.activeIndex) {
          setReachedEdge(null);
          return;
        }
        // decrement addon
        setAddon((prevAddonValue) => prevAddonValue - 1);
        return;
      }

      // if we did not reach an edge
      swiperInstance?.slidePrev();
    },
    [swiperInstance, reachedEdge, addon, setAddon, setFocusedArrowIndex, setReachedEdge]
  );

  const onSingleSlideClickHandler = useCallback(
    async (e, idx) => {
      if (!swiperInstance) return;
      // if it was already clicked and user clicked again AND its not a 3rd click but only second.
      // this prevents user from triple (>= 2 times) click spamming.
      if (selectedIdx === idx && userDoubleClickedRef.current === false) {
        // mark user clicked two times
        userDoubleClickedRef.current = true;
        // everything will be handled here.
        // call handleSubmit manually
        footerContext.sendButtonRef.current?.handleSubmit();
        return;
      }

      // if user clicks on it the first time
      const clickedWorkflowData = slideListItems[idx];
      const wfNameInCurrLang = clickedWorkflowData.name[i18n.language];
      // prepare new root user selected prompt info ref data
      const newPromptInfoData = {
        _id: clickedWorkflowData._id,
        type: 'workflow',
        name: wfNameInCurrLang,
      } as IPrompt;

      // Accessibility part (focus control)
      // we clicked on some different cell (not the focused one)
      // only on not mobile devices. (not tablet and not mobile)
      if (isMobileDevice === false) {
        if (idx !== swiperInstance.activeIndex + addon) {
          e.preventDefault();
          // get left and right edges indexes
          const [left, right] = calcEdgesIdxes();
          // set the proper addon if not beyond the edges
          // if beyond left edge
          if (idx < left) {
            // slide to cell with idx of left edge index
            swiperInstance.slideTo(left);
            // set proper addon
            setAddon(idx - left);
          }
          // beyond right edge
          else if (idx > right) {
            // slide to cell with idx of right edge index
            swiperInstance.slideTo(right);
            // set proper addon
            setAddon(idx - right);
          } else {
            // if right on the edge
            if (idx === right || idx === left) {
              // slide to the edge idx
              swiperInstance.slideTo(idx);
              // reset addon
              setAddon(0);
            }
          }
        }
      }
      // fetch random sample text
      let sampleText = null
      try {
        // fetch random sample text
        const wfIdName = clickedWorkflowData.idName.replaceAll(' ', '_'); // remove spaces and put low dashes
        const { data } = await fetchSampleTextData(wfIdName);
        const { sample } = data;
        sampleText = sample;
      } catch (err) {
        console.error('Error occured while fetching workflow random sample text data', err);
        // This shows error message in placeholder and hide in 2 seconds
        errandContext.setUserPromptsMenuState(UserPromptsMenuState.WORKFLOW_FETCH_ERROR);
        tmsRef.current.push(
          setTimeout(() => {
            errandContext.setMorphType(MorphType.None);
            // hide in at least 5 seconds.
          }, 5000)
        );
      }
      if(ValidatorFunctions.isUndefinedOrNull(sampleText) === true) return;
      // Handle Workflow Selection.
      // setTimeout is used for smooth animation here.
      setTimeout(() => {
        setSelectedIdx(idx);
        // update footer UI through errandContext
        errandContext.setUserPromptsMenuState(UserPromptsMenuState.WORKFLOW_SELECTED);
        // update the prompt name to be displayed in footer placeholder
        rootContext.setUserSelectedPrompt(wfNameInCurrLang);
        // play send button icon animation
        footerContext.sendButtonRef.current?.update(sampleText);
        // focus on send button
        footerContext.chatInputFieldRef.current?.update(sampleText);
        // setTimeout is used to ensure that the sendButton gets focused and focus is not lost by browser.
        // without it, it does not focus sendButton. Bahavior may vary on different browsers.
        setWorkflowWasSent(true); // this will make smooth animation
        setTimeout(() => {
          // Send directly in chat
          footerContext.sendButtonRef.current?.handleSubmit();
        }, 350);
      }, 10);

      // update rootContext userSelected prompt info
      updateRootUserSelectedPromptInfoRef(newPromptInfoData);
    },
    [
      swiperInstance,
      selectedIdx,
      slideListItems,
      setSelectedIdx,
      focusElement,
      setAddon,
      errandContext.setUserPromptsMenuState,
      rootContext.setUserSelectedPrompt,
      updateRootUserSelectedPromptInfoRef,
    ]
  );

  // returns indexes of left & right edges in arr of numbers (size 2)
  // Structure: [leftIndex, rightIndex]
  const calcEdgesIdxes = useCallback(() => {
    if (!swiperSectionRef.current) return;
    const swiperWidthValue = swiperSectionRef.current.getBoundingClientRect()?.width;
    let actualFittingElements = swiperWidthValue / (SWIPER_SLIDE_WIDTH + SPACE_BETWEEN_SLIDES);
    let actualFittingElementsWhole = Math.round(actualFittingElements);
    const elemsToSide = Math.floor(actualFittingElementsWhole / 2);
    const leftEdgeIndex = elemsToSide - 1;
    const rightEdgeIndex = slideListItems.length - elemsToSide;
    return [leftEdgeIndex, rightEdgeIndex];
  }, [slideListItems.length]);

  const handleSlideChange = useCallback(
    (_swiper: SwiperClass) => {
      if (!swiperSectionRef.current) return;

      // on mobile, when slide changes, just focus and nothing else.
      if (isMobileDevice === true) {
        focusElement(_swiper?.activeIndex);
        return;
      }

      // desktop view
      const currIndex = _swiper?.activeIndex;
      const [leftEdgeIndex, rightEdgeIndex] = calcEdgesIdxes();

      // if slided/clicked on left edge
      if (currIndex <= leftEdgeIndex) {
        // if slided by dragging over the edge
        if (currIndex < leftEdgeIndex) {
          swiperInstance.slideTo(leftEdgeIndex);
          focusElement(leftEdgeIndex);
          setAddon(0);
        }
        // slided/clicked on the edge
        setReachedEdge(Edges.left);
        return;
      }
      // if slided/clicked on right edge
      else if (currIndex >= rightEdgeIndex) {
        // if slided by dragging over the edge
        if (currIndex > rightEdgeIndex) {
          swiperInstance.slideTo(rightEdgeIndex);
          focusElement(rightEdgeIndex);
          setAddon(0);
        }
        // slided/clicked on the edge
        setReachedEdge(Edges.right);
        return;
      } else {
        // if reached edge is not Null
        // AND we clicked in between edges
        // means we came from edge
        if (reachedEdge !== null) {
          setReachedEdge(null);
          setAddon(0);
        }
        // focus currently clicked index
        focusElement(_swiper.activeIndex);
      }
    },
    [swiperInstance, setAddon, setReachedEdge, reachedEdge, focusElement, calcEdgesIdxes]
  );

  const onClick = (e) => {
    if (!swiperInstance) return;
    let slideClicked = false;
    for (const el of swiperInstance?.el?.children[0]?.children) {
      if (el.contains(e.target)) {
        slideClicked = true;
        break;
      }
    }
    // only fire when container AND not the singleSlide was clicked
    // because we already have handler for that.
    if (slideClicked === false) {
      setTimeout(() => {
        focusElement(swiperInstance.activeIndex + addon);
      }, 0);
    }
  };

  // ==================== UseEffects START ====================
  // initial fetch
  useEffect(() => {
    // Cancel Current Action if the is one.
    // cancelAction args: key null, clear true and withMorphTypeChange false.
    // last is false because we need to leave the same morphType.
    if (
      ValidatorFunctions.isNotUndefinedNorNull(props?.errand) &&
      ValidatorFunctions.isNotUndefinedNorNull(props.errand?.action)
    ) {
      if (props?.cancelAction) props.cancelAction(null, true, false);
    }

    // Set user prompts menu to WORKFLOW_FETCH_LOADING state
    errandContext.setUserPromptsMenuState(UserPromptsMenuState.WORKFLOW_FETCH_LOADING);

    // fetch the workflows
    fetchWorkflows(abortController.get())
      // Handle fetched workflows
      .then((fetchedWorkflows) => {
        errandContext.setUserPromptsMenuState(UserPromptsMenuState.WORKFLOW_NOT_SELECTED);
        const wfDataArr: TWorkflowData_UPmenu[] = parseFetchedWorkflowsData(fetchedWorkflows);
        const userStatus = getUserStatus(userContext);
        const filteredByStatus = filterByStatus(userStatus, wfDataArr);
        setSlideListItems(filteredByStatus);
      })
      // Handle Error
      .catch((err) => {
        console.error('Error occured while fetching workflows data', err);
        // This shows error message in placeholder and hide in 2 seconds
        errandContext.setUserPromptsMenuState(UserPromptsMenuState.WORKFLOW_FETCH_ERROR);
        tmsRef.current.push(
          setTimeout(() => {
            errandContext.setMorphType(MorphType.None);
            // hide in at least 5 seconds.
          }, 5000)
        );
      })
      .finally(() => {
        setIsLoading((prev) => false);
      });
    setHasMounted(true);

    // set body to unscrollable
    // change the style
    document.body.style.overflow = 'hidden';
  }, []);

  const navigateToMiddleElement = useCallback(() => {
    if (
      swiperInstance === null ||
      ValidatorFunctions.isTypeOfArray(slideListItems) === false ||
      slideListItems.length < 3 ||
      !hasMounted
    )
      return;

    let middleIdx = 0;
    // >= 3
    // is mobile
    if (isMobile() === true) {
      swiperInstance.slideTo(1);
      return;
    }

    if (ValidatorFunctions.isUndefinedOrNull(swiperSectionRef.current) === true) {
      swiperInstance.slideTo(1);
      return;
    }
    const swiperWidthValue = swiperSectionRef.current.getBoundingClientRect()?.width;

    if (ValidatorFunctions.isUndefinedOrNull(swiperWidthValue) === true) {
      swiperInstance.slideTo(1);
      return;
    }
    // divide by SWIPER_SLIDE_WIDTH
    // we get ceil (and not floor) because we allow the first element to be a bit cut off
    // 8 is margin rights
    let actualFittingElements = swiperWidthValue / (SWIPER_SLIDE_WIDTH + SPACE_BETWEEN_SLIDES);
    let actualFittingElementsWhole = Math.round(actualFittingElements);

    // check NaNs
    if (isNaN(actualFittingElements) === true || isNaN(actualFittingElementsWhole) === true) {
      swiperInstance.slideTo(1);
      return;
    }
    // if the last element can be fit in for at least 0.5, leave it cut off, else make sure that it is fully visible.
    let corrector = isFirstDecimalDigitCompareThan(actualFittingElements, 4, ">")
      || isFirstDecimalDigitCompareThan(actualFittingElements, 3, "<")
      ? 1
      : 2;

    if (actualFittingElementsWhole > slideListItems.length) {
      // edge case: when there is less actual items then the swiper can theoretically fit inside.
      // use the length of it.
      actualFittingElementsWhole = slideListItems.length;
      corrector = 1;
    }
    middleIdx = Math.round(actualFittingElementsWhole / 2) - corrector;

    // check NaN
    if (isNaN(middleIdx) === true) {
      swiperInstance.slideTo(1);
      return;
    }

    // Check Negative
    if (middleIdx < 0) middleIdx = 0;
    // On desktop, middle index cannot be 0
    if (isMobileDevice === false && middleIdx === 0) {
      // left edge
      middleIdx = calcEdgesIdxes()[0];
    }

    swiperInstance.slideTo(middleIdx);
  }, [swiperInstance, slideListItems?.length, hasMounted, calcEdgesIdxes]);

  // Component Elements Translation Logic
  useEffect(() => {
    if (
      i18n.language === 'en' ||
      ValidatorFunctions.isTypeOfArray(slideListItems) === false ||
      slideListItems.length === 0
    )
      return;

    // patch with translations from translationsModule
    const localWorkflowsArrWithTranslations = slideListItems.map((wfData) => {
      // if no translation present, then there is some error in translation.
      // keep original version of message in this case.
      const currTranslation = UPM_TranslationModule.get(UPM_TranslationModule.getWfKey(wfData));
      return {
        ...wfData,
        name: {
          ...wfData.name,
          [i18n.language]: currTranslation ?? wfData.name[i18n.language],
        },
      };
    });
    setSlideListItems(localWorkflowsArrWithTranslations);
  }, [i18n.language, slideListItems?.length]);

  // Automatic navigation to middle element in swiper
  // once all workflows items are loaded and rendered.
  useEffect(() => {
    if (
      ValidatorFunctions.isUndefinedOrNull(swiperInstance) ||
      ValidatorFunctions.isTypeOfArray(slideListItems) === false ||
      slideListItems.length < 3
    )
      return;
    navigateToMiddleElement();
    // focus navigated el after mount
    focusElement(swiperInstance.activeIndex);
  }, [swiperInstance, slideListItems?.length, navigateToMiddleElement, focusElement]);

  // arrow clicks handler + scrollHandler
  useEffect(() => {
    if (!swiperInstance) return;

    const onWheel = (e: React.WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!hasMounted) return;
      if (!swiperInstance) return;
      let callHandler = false;

      if (e?.type === 'wheel') {
        const target = e?.target as Node;
        // check if swiperSection contains that target event element, allow calling the main logic handler
        if (swiperSectionRef.current && swiperSectionRef.current.contains(target)) callHandler = true;
      } else {
        return;
      }

      // if happened outside the component, do nothing
      if (!callHandler) return;

      if (e?.deltaY > 0) {
        handleTabForward(true);
      } else if (e?.deltaY < 0) {
        handleTabBackward(true);
      }
    };

    const debouncedOnWheel = _customDebounce(onWheel, 100);

    const onKeyDown = (e) => {
      if (e.code === 'ArrowLeft') {
        // if right arrow is focused
        if (focusedArrowIndex === 1) {
          setFocusedArrowIndex(-1);
        } else {
          handleTabBackward(true);
        }
      } else if (e.code === 'ArrowRight') {
        // if left arrow is focused
        if (focusedArrowIndex === 0) {
          setFocusedArrowIndex(-1);
          focusElement(swiperInstance.activeIndex + addon);
        } else {
          // if on the edge or in the range
          handleTabForward(true);
        }
      }
    };
    // set event listeners for arrows
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('wheel', debouncedOnWheel, { passive: false });
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('wheel', debouncedOnWheel);
    };
  }, [swiperInstance, reachedEdge, handleTabBackward, handleTabForward, hasMounted, focusedArrowIndex]);

  // errandContext UserPromptsMenuState Observer
  useEffect(() => {

    const keyDownHandler = (e) => {
      // on Escape press
      if (e.key === 'Escape') {
        // if already a WORKFLOW_NOT_SELECTED state, then close the whole component
        if (errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_NOT_SELECTED) {
          errandContext.setMorphType(MorphType.None);
        } else {
          resetState();
        }
      }
    };

    // add event listener if component is in selected OR not_selected state
    if (
      errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_SELECTED ||
      errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_NOT_SELECTED
    ) {
      document.addEventListener('keydown', keyDownHandler); // Esc key listener for accessibility
    }
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [errandContext.userPromptsMenuState]);

  useEffect(() => {
    if (swiperInstance) {
      // if -1, focus the active index
      if (focusedArrowIndex === -1) {
        focusElement(swiperInstance.activeIndex + addon);
      }
    }
  }, [swiperInstance, focusedArrowIndex]);

  // Addon and ReachedEdge handler
  useEffect(() => {
    if (!swiperSectionRef) return;
    if (isMobileDevice === true) return;
    const swiperWidthValue = swiperSectionRef.current.getBoundingClientRect()?.width;
    let actualFittingElements = swiperWidthValue / (SWIPER_SLIDE_WIDTH + SPACE_BETWEEN_SLIDES);
    let actualFittingElementsWhole = Math.round(actualFittingElements);
    const elemsToSide = Math.floor(actualFittingElementsWhole / 2);

    const leftEdgeIndex = elemsToSide - 1;
    const rightEdgeIndex = slideListItems.length - elemsToSide;

    if (reachedEdge === Edges.left || reachedEdge === Edges.right) {
      focusElement(swiperInstance.activeIndex + addon);
    }

    // came back from left edge
    if (
      reachedEdge === null &&
      prevReachedEdgeRef.current === Edges.left &&
      swiperInstance.activeIndex === leftEdgeIndex
    ) {
      swiperInstance?.slideNext();
    }
    // came back from right edge
    if (
      reachedEdge === null &&
      prevReachedEdgeRef.current === Edges.right &&
      swiperInstance.activeIndex === rightEdgeIndex
    ) {
      swiperInstance?.slidePrev();
    }

    prevReachedEdgeRef.current = reachedEdge;
  }, [addon, reachedEdge]);

  // Unmount Handler
  useEffect(() => {
    return () => {
      clearTimeouts();
      abortController.abort();
      resetState(true);
      // make page scrollable again
      document.body.style.overflow = '';
    };
  }, []);
  // ==================== UseEffects END ====================

  // minimizes style updates
  const mainSwiperStyle = useMemo(
    () => getSwiperStyle(slideListItems, isLoading, workflowWasSent) as { height: string; opacity: string },
    [slideListItems, isLoading, workflowWasSent]
  );

  const mainWrapperClassStr = `${Styles.wrapper} ${isLoading ? Styles.loadingWrapper : ''}`;
  return (
    <>
      <section className={mainWrapperClassStr} ref={swiperSectionRef} onClick={onClick}>
        <Arrows
          slideListItemsLength={slideListItems?.length}
          setFocusedArrowIndex={setFocusedArrowIndex}
          focusedArrowIndex={focusedArrowIndex}
          handleTabBackward={handleTabBackward}
          handleTabForward={handleTabForward}
          workflowWasSent={workflowWasSent}
          swiperInstance={swiperInstance}
          focusElement={focusElement}
          selectedIdx={selectedIdx}
          hasMounted={hasMounted}
          resetState={resetState}
          isLoading={isLoading}
          addon={addon}
        />
        <MainSwiper
          onSingleSlideClickHandler={onSingleSlideClickHandler}
          setFocusedArrowIndex={setFocusedArrowIndex}
          lastFocusedIndexRef={lastFocusedIndexRef}
          setSwiperInstance={setSwiperInstance}
          handleTabBackward={handleTabBackward}
          handleSlideChange={handleSlideChange}
          handleTabForward={handleTabForward}
          setReachedEdge={setReachedEdge}
          slideListItems={slideListItems}
          swiperInstance={swiperInstance}
          focusElement={focusElement}
          reachedEdge={reachedEdge}
          style={mainSwiperStyle}
          isLoading={isLoading}
          setAddon={setAddon}
          addon={addon}
        />
        <div className={Styles.accessibilityDescDiv} id="UPM_StartOfList">
          {t('UPM_First_Item_in_the_list')}
        </div>
        <div className={Styles.accessibilityDescDiv} id="UPM_EndOfList">
          {t('UPM_Last_Item_in_the_list')}
        </div>
      </section>
      <div
        aria-hidden="true"
        className={
          Styles.bottomBorder +
          ' ' +
          (errandContext.isMorphedFooterCloseButtonOnLeft === true ? Styles.isMorphedFooterCloseButtonOnLeft : '')
        }
      ></div>
    </>
  );
});

export default MorphUserPromptsMenu;
