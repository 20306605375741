/**
 * @author icy.parkinson
 * @date 07/21/2023
 * Styles to be used in conjunction with the cooresponding component.
 *
 * Styles should always be defined in their own file to prevent them from being defined within
 * funcitonal components and causing additional renders.
 * Classes should be used over props to keep static css and dynamic html. This will result in
 * better performance akin to css modules while allowing us to take advantage of mui components
 * When possible, don't uses Stacks or Boxes and define all styles by nesting tags or classes.
 * The css here should mirror the JSX as close as possible.
 * Only create multiple style components when you need to take advantage of an existing mui
 * component that will add valuable styles or functionality.
 */

import { MorganTheme } from '@mui/material';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MorphType } from '@common/MorphType';
import { useMorphContext } from '@contexts/MorphContext';
const WoodGrain = process.env.REACT_APP_MORGAN_CDN + '/Images/woodgrain.png';


type TStyleProps = {
  theme?: MorganTheme; // NOTICE: Included by default with mui styled
};

export const MorphedConversationFooterStyles = styled('div')<TStyleProps>(
  ({ theme }) => `
  display: flex;
  background-color: transparent;
  position: relative;
  z-index: 5;

/**
 * The TextFieldSizers are a fixed-size box which are rendered at specific resolutions to
 * give the Container div dynamic sizing based on the size of the text/input field.
 *
 * There are 3 different layouts of Morgan that affects the size of the text field
 * 1: > 56.25rem -- Errand sidebar is displayed -- use isDesktop
 * 2: 50rem - 56.25rem -- Textfield changes to mobile view -- use isDesktopTextField
 * 3: < 50rem -- Errand sidebar disappears
 */

  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: fit-content;
    width: 100%;
    margin-top: 0;
    transform: translate${useMediaQuery(theme.breakpoints.up('md')) ? '(0, 0)' : '(0, 0.1875rem)'};
  }

  &.morphType${MorphType.ErrandNew} .container,
  &.morphType${MorphType.ErrandEdit} .container{
    margin: 0;
  }
  &.morphType${MorphType.PrivateChat} .container,
  &.morphType${MorphType.VideoListMenu} .container,
  &.morphType${MorphType.UserPromptsMenu} .container,
  &.morphType${MorphType.CreditRepairDisputeAccountType} .container,
  &.morphType${MorphType.MessageOptions} .container {
    margin: ${useMediaQuery(theme.breakpoints.up('md')) ? '0 30px' : '0 20px'};
  }

  /** The Top Box holds the primary content of the morphed footer. This is where the topic or type of morph lives. */ 
  .topBox {
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
    background-color: transparent;
    z-index: 2;
    overflow: visible;
    margin-bottom: 0.125rem;
  }
  &.morphType${MorphType.Calendar} .topBox,
  &.morphType${MorphType.PhotoPlain} .topBox,
  &.morphType${MorphType.PhotoMain} .topBox,
  &.morphType${MorphType.Attachment} .topBox,
  &.morphType${MorphType.PrivateChat} .topBox,
  &.morphType${MorphType.MessageOptions} .topBox {
    flex-direction: column;
  }
  &.morphType${MorphType.Attachment} .topBox,
  &.morphType${MorphType.PrivateChat} .topBox,
  &.morphType${MorphType.MessageOptions} .topBox {
    position: absolute;
    bottom: 0;
    height: fit-content;
  }
  &.morphType${MorphType.Recording} .topBox {
    overflow: hidden;
    height: 2.625rem;
  }
  &.morphType${MorphType.Contacts} .topBox {
    height: fit-content;
    align-items: center;
  }
  &.morphType${MorphType.ErrandNew} .topBox, 
  &.morphType${MorphType.ErrandEdit} .topBox {
    margin-top: -30px;
  }
  &.morphType${MorphType.VideoListMenu} .topBox,
  &.morphType${MorphType.UserPromptsMenu} .topBox,
  &.morphType${MorphType.LoanProductPriceTable} .topBox,
  &.morphType${MorphType.CreditRepairDisputeAccountType} .topBox {
    flex-direction: column;
    position: absolute;
    bottom: 0;
    height: fit-content;
  }
  &.morphType${MorphType.LoanProductPriceTable} .topBox {
    align-items: center;
  }

  .bottomBox{
    position: absolute;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    bottom: 0;
    transform: translate(0rem, calc(100% - 0.0625rem)); 
    z-index: 2;
  }
  &.morphType${MorphType.DownloadAppBanner} .bottomBox{
    z-index: 1;
  }

  &.morphType${MorphType.Reply} .bottomBox, &.morphType${MorphType.Edit} .bottomBox {
    display: none;
  }
  &.morphType${MorphType.PhotoPlain} .bottomBox::before,
  &.morphType${MorphType.PhotoMain} .bottomBox::before,
  &.morphType${MorphType.Calendar} .bottomBox::before,
  &.morphType${MorphType.Attachment} .bottomBox::before,
  &.morphType${MorphType.VideoListMenu} .bottomBox::before,
  &.morphType${MorphType.UserPromptsMenu} .bottomBox::before,
  &.morphType${MorphType.CreditRepairDisputeAccountType} .bottomBox::before,
  &.morphType${MorphType.MessageOptions} .bottomBox::before {
    position: absolute;
    left: -0.250rem;
    top: -0.125rem;
    width: 0.625rem;
    height: 0.625rem;
    content: '';
    display: block;
    background: ${
      useMediaQuery(theme.breakpoints.up('md'))
        ? 'radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--orange000) 0.625rem)'
        : 'transparent'
    };
  }
  &.morphType${MorphType.Attachment}.isMorphedFooterCloseButtonOnLeft .bottomBox::before,
  &.morphType${MorphType.MessageOptions}.isMorphedFooterCloseButtonOnLeft .bottomBox::before {
    position: absolute;
    left: unset;
    right: -0.250rem;
    top: -0.125rem;
    width: 0.625rem;
    height: 0.625rem;
    content: '';
    display: block;
    background: ${
      useMediaQuery(theme.breakpoints.up('md'))
        ? 'radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--orange000) 0.625rem)'
        : 'transparent'
    };
  }

  &.morphType${MorphType.UserPromptsMenu} .bottomBox::before {
    position: absolute;
    left: -0.250rem;
    top: -0.125rem;
    width: 0.625rem;
    height: 0.625rem;
    content: '';
    display: block;
    background: ${
      useMediaQuery(theme.breakpoints.up('md'))
        ? 'radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--blue930) 0.5625rem, var(--blue930) 0.625rem)'
        : 'transparent'
    };
  }

  &.morphType${MorphType.CreditRepairDisputeAccountType} .bottomBox::before {
    position: absolute;
    left: -0.250rem;
    top: -0.125rem;
    width: 0.625rem;
    height: 0.625rem;
    content: '';
    display: block;
    background: ${
      useMediaQuery(theme.breakpoints.up('md'))
        ? 'radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--blue995) 0.5625rem, var(--blue995) 0.625rem)'
        : 'transparent'
    };
  }

  &.morphType${MorphType.UserPromptsMenu}.isMorphedFooterCloseButtonOnLeft .bottomBox::before {
    position: absolute;
    left: unset;
    right: -0.250rem;
    top: -0.125rem;
    width: 0.625rem;
    height: 0.625rem;
    content: '';
    display: block;
    background: ${
      useMediaQuery(theme.breakpoints.up('md'))
        ? 'radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--blue930) 0.5625rem, var(--blue930) 0.625rem)'
        : 'transparent'
    };
  }

  &.morphType${MorphType.CreditRepairDisputeAccountType}.isMorphedFooterCloseButtonOnLeft .bottomBox::before {
    position: absolute;
    left: unset;
    right: -0.250rem;
    top: -0.125rem;
    width: 0.625rem;
    height: 0.625rem;
    content: '';
    display: block;
    background: ${
      useMediaQuery(theme.breakpoints.up('md'))
        ? 'radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--blue995) 0.5625rem, var(--blue995) 0.625rem)'
        : 'transparent'
    };
  }

  &.morphType${MorphType.UserPromptsMenu} .bottomBox::after {
    position: absolute;
    left: -0.250rem;
    top: -0.8125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-top-left-radius: 0.5rem;
    content: '';
    display: block;
    background: ${useMediaQuery(theme.breakpoints.up('md')) ? 'var(--blue930)' : 'transparent'};
  }

  &.morphType${MorphType.CreditRepairDisputeAccountType} .bottomBox::after {
    position: absolute;
    left: -0.250rem;
    top: -0.8125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-top-left-radius: 0.5rem;
    content: '';
    display: block;
    background: ${useMediaQuery(theme.breakpoints.up('md')) ? 'var(--blue995)' : 'transparent'};
  }

  &.morphType${MorphType.UserPromptsMenu}.isMorphedFooterCloseButtonOnLeft .bottomBox::after {
    position: absolute;
    left: unset;
    right: -0.250rem;
    top: -0.8125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0.5rem;
    content: '';
    display: block;
    background: ${useMediaQuery(theme.breakpoints.up('md')) ? 'var(--blue930)' : 'transparent'};
  }

  &.morphType${MorphType.CreditRepairDisputeAccountType}.isMorphedFooterCloseButtonOnLeft .bottomBox::after {
    position: absolute;
    left: unset;
    right: -0.250rem;
    top: -0.8125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0.5rem;
    content: '';
    display: block;
    background: ${useMediaQuery(theme.breakpoints.up('md')) ? 'var(--blue995)' : 'transparent'};
  }

  &.morphType${MorphType.VideoListMenu} .bottomBox::before {
    position: absolute;
    left: -0.250rem;
    top: -0.125rem;
    width: 0.625rem;
    height: 0.625rem;
    content: '';
    display: block;
    background: ${
      useMediaQuery(theme.breakpoints.up('md'))
        ? 'radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--blue300) 0.6625rem, var(--blue300) 0.625rem)'
        : 'transparent'
    };
  }

  &.morphType${MorphType.VideoListMenu}.isMorphedFooterCloseButtonOnLeft .bottomBox::before {
    position: absolute;
    left: unset;
    right: -0.250rem;
    top: -0.125rem;
    width: 0.625rem;
    height: 0.625rem;
    content: '';
    display: block;
    background: ${
      useMediaQuery(theme.breakpoints.up('md'))
        ? 'radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--blue300) 0.6625rem, var(--blue300) 0.625rem)'
        : 'transparent'
    };
  }

  &.morphType${MorphType.VideoListMenu} .bottomBox::after {
    position: absolute;
    left: -0.250rem;
    top: -0.3125rem;
    width: 0.75rem;
    height: 0.25rem;
    border-top-left-radius: 0.5rem;
    content: '';
    display: block;
    background: ${useMediaQuery(theme.breakpoints.up('md')) ? 'var(--blue300)' : 'transparent'};
  }
  &.morphType${MorphType.VideoListMenu}.isMorphedFooterCloseButtonOnLeft .bottomBox::after {
    position: absolute;
    left: unset;
    right: -0.250rem;
    top: -0.8125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0.5rem;
    content: '';
    display: block;
    background: ${useMediaQuery(theme.breakpoints.up('md')) ? 'var(--blue300)' : 'transparent'};
  }

  &.morphType${MorphType.PhotoPlain} .bottomBox::after,
  &.morphType${MorphType.PhotoMain} .bottomBox::after,
  &.morphType${MorphType.Calendar} .bottomBox::after,
  &.morphType${MorphType.Attachment} .bottomBox::after,
  &.morphType${MorphType.MessageOptions} .bottomBox::after {
    position: absolute;
    left: -0.250rem;
    top: -0.8125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-top-left-radius: 0.5rem;
    content: '';
    display: block;
    background: ${useMediaQuery(theme.breakpoints.up('md')) ? 'var(--orange000)' : 'transparent'};
  }
  &.morphType${MorphType.Attachment}.isMorphedFooterCloseButtonOnLeft .bottomBox::after,
  &.morphType${MorphType.MessageOptions}.isMorphedFooterCloseButtonOnLeft .bottomBox::after {
    position: absolute;
    left: unset;
    right: -0.250rem;
    top: -0.8125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0.5rem;
    content: '';
    display: block;
    background: ${useMediaQuery(theme.breakpoints.up('md')) ? 'var(--orange000)' : 'transparent'};
  }

  &.morphType${MorphType.ErrandNew} .bottomBox, 
  &.morphType${MorphType.ErrandEdit} .bottomBox {
    display: none;
  }

    &.morphType${MorphType.PhotoPlain} .indentWrapper::before,
    &.morphType${MorphType.PhotoMain} .indentWrapper::before,
    &.morphType${MorphType.Calendar} .indentWrapper::before{
    position: absolute;
    right: -0.250rem; // Adjusted to right
    top: -0.125rem;
    width: 0.625rem;
    height: 0.625rem;
    content: '';
    display: block;
    background: ${
      useMediaQuery(theme.breakpoints.up('md'))
        ? 'radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--orange000) 0.625rem)'
        : 'transparent'
    };
  }

  &.morphType${MorphType.PhotoPlain} .indentWrapper::after,
  &.morphType${MorphType.PhotoMain} .indentWrapper::after,
  &.morphType${MorphType.Calendar} .indentWrapper::after{
    position: absolute;
    right: -0.250rem; // Adjusted to right
    top: -0.8125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-top-right-radius: 0.5rem; // Adjusted the border radius
    content: '';
    display: block;
    background: ${useMediaQuery(theme.breakpoints.up('md')) ? 'var(--orange000)' : 'transparent'};
  }

  &.morphType${MorphType.Reply} + div .inputContainer {
    padding-top: 6px!important;
  }
  &.morphType${MorphType.SongOfTheDay} + div .inputContainer {
    padding-top: 15px!important;
  }

  .indentWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
  }

  .middle::before {
    position: absolute;
    left: -0.59375rem;
    top: 0.03125rem;
    width: 0.625rem;
    height: 0.625rem;
    background: radial-gradient(circle at 0 100%, transparent 0.5rem, var(--orange400) 0.5625rem, var(--peach000) 0.625rem);
    content: '';
    display: block;
  }

  .middle {
    position: relative;
    width: fit-content;
    border: 0.0625rem solid var(--orange400);
    border-top: none;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    background: var(--peach000);
    padding: 0 0.1875rem;
  }

  .middle::after {
    position: absolute;
    right: -0.59375rem;
    top: 0.03125rem;
    width: 0.625rem;
    height: 0.625rem;
    background: radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--orange400) 0.5625rem, var(--peach000) 0.625rem);
    content: '';
    display: block;
    z-index: 0;
  }

  .tab {
    width: fit-content;
    min-width: 4.875rem;
    font-size: 0.6875rem;
    font-family: ${theme.typography.fontFamily};
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    color: var(--gray000);
    background-color: var(--orange000);
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.625rem;
    max-width: 16.5625rem;
    min-height: 1.125rem;
    position: relative;
    z-index: 1;
    margin-top: -0.08rem;
  }
  &.morphType${MorphType.Payment} .tab {
    width: fit-content;
    min-width: 4.875rem;
    font-size: 0.6875rem;
    font-family: ${theme.typography.fontFamily};
    background-image: url(${WoodGrain});
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    color: var(--gray000);
    background-color: var(--orange700);
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.625rem;
    max-width: 16.5625rem;
    min-height: 1.125rem;
    position: relative;
    z-index: 10;
    margin-top: -0.08rem;
  }

  &.morphType${MorphType.Recording} .tab {
    background-color: transparent;
    min-width: 10.875rem;
    font-size: 0;
  }

  &.morphType${MorphType.SlotMachine} .tab {
    padding: 0;
  }

  &.morphType${MorphType.SongOfTheDay} .tab {
    padding: 0px;
    background-color: transparent;
  }

  &.morphType${MorphType.CalendarMonth} .tab,
  &.morphType${MorphType.DOB} .tab {
    background-color: ${useMorphContext().hideCalendarMonth ? 'var(--orange700)' : 'var(--gray000)'};
    bottom: 3px;
    border-radius: ${useMorphContext().hideCalendarMonth ? '10px' : 'inherit'};
    border: 2px solid var(--peach900);
    border-top: none;
  }

  &.morphType${MorphType.Time} .tab {
    bottom: 2px;
    background-color: var(--peach800);
    width: 120px;
    font-size: 1rem;
    border-radius: ${useMorphContext().hideTime ? '10px' : 'inherit'};
  }

  &.morphType${MorphType.SongOfTheDay} .middle {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  &.morphType${MorphType.FormOpen} .tab,
  &.morphType${MorphType.FormActiveChat} .tab,
  &.morphType${MorphType.CreditRepairWelcome} .tab {
    padding: 0;
  }
  &.morphType${MorphType.RefinanceCalculatorWelcome} .tab {
    padding: 0;
  }

  &.morphType${MorphType.Contacts} .tab {
    color: var(--gray920);
    background-color: transparent;
  }
  &.morphType${MorphType.LoanProducts} .tab {
    color: var(--gray920);
    background-color: transparent;
  }
  &.morphType${MorphType.BorrowerSelector} .tab {
    color: black;
    background-color: var(--peach800);
    border-left: 1px solid var(--orange900);
    border-right: 1px solid var(--orange900);
    border-bottom: 1px solid var(--orange900);
    transform: translate(0px, -1px);
  }

  &.morphType${MorphType.Errand} .tab{
    color: var(--orange700);
  }

  &.morphType${MorphType.PhotoPlain} .tab::before,
  &.morphType${MorphType.PhotoMain} .tab::before,
  &.morphType${MorphType.Attachment} .tab::before,
  &.morphType${MorphType.PrivateChat} .tab::before,
  &.morphType${MorphType.CalendarMonth} .tab::before,
  &.morphType${MorphType.DOB} .tab::before,
  &.morphType${MorphType.Time} .tab::before,
  &.morphType${MorphType.VideoListMenu} .tab::before,
  &.morphType${MorphType.MessageOptions} .tab::before,
  &.morphType${MorphType.UserPromptsMenu} .tab::before,
  &.morphType${MorphType.CreditRepairDisputeAccountType} .tab::before,
  &.morphType${MorphType.VideoListMenu} .tab::before,
  &.morphType${MorphType.Errand} .tab::before,
  &.morphType${MorphType.ErrandNew} .tab::before,
  &.morphType${MorphType.ErrandEdit} .tab::before,
  &.morphType${MorphType.FormInsertSignature} .tab::before,
  &.morphType${MorphType.FormNavigateInitials} .tab::before,
  &.morphType${MorphType.FormReadyToSend} .tab::before {
    position: absolute;
    left: -0.5625rem;
    top: -0.03125rem;
    width: 0.5625rem;
    height: 0.75rem;
    content: '';
    display: block;
  }

  &.morphType${MorphType.PhotoPlain} .tab::before,
  &.morphType${MorphType.PhotoMain} .tab::before {
    background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--orange700) 0.5625rem, var(--gray000) 0.625rem);
  }

  &.morphType${MorphType.Errand} .tab,
  &.morphType${MorphType.ErrandNew} .tab,
  &.morphType${MorphType.ErrandEdit} .tab,
  &.morphType${MorphType.FormReadyToSend} .tab,
  &.morphType${MorphType.FormNavigateInitials} .tab,
  &.morphType${MorphType.FormInsertSignature} .tab,
  &.morphType${MorphType.SongOfTheDay} .tab {
    border: 0.0625rem solid;
    border-top: none;
    transform: translate(0, -0.1875rem);
    height: 50%;
  }

  &.morphType${MorphType.FormInsertSignature} .tab::before,
  &.morphType${MorphType.FormNavigateInitials} .tab::before,
  &.morphType${MorphType.FormReadyToSend} .tab::before {
    background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--peach800) 0.5625rem, var(--gray000) 0.625rem);
  }

  &.morphType${MorphType.PhotoPlain} .tab::before,
  &.morphType${MorphType.PhotoMain} .tab::before,
  &.morphType${MorphType.Attachment} .tab::before,
  &.morphType${MorphType.VideoListMenu} .tab::before,
  &.morphType${MorphType.UserPromptsMenu} .tab::before,
  &.morphType${MorphType.CreditRepairDisputeAccountType} .tab::before,
  &.morphType${MorphType.MessageOptions} .tab::before {
    background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--orange000) 0.625rem);
  }
  &.morphType${MorphType.PrivateChat} .tab::before {
    background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--peach000) 0.625rem);
  }
  &.morphType${MorphType.CalendarMonth} .tab::before,
  &.morphType${MorphType.DOB} .tab::before {
    background: ${useMorphContext().hideCalendarMonth ? 'transparent;' : 'radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--peach900) 0.5625rem, var(--gray000) 0.625rem);'} 
    top: -0.07125rem;
  }

  &.morphType${MorphType.Time} .tab::before {
    background: ${useMorphContext().hideTime ? 'transparent;' : 'radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--gray000) 0.5625rem, var(--peach800) 0.625rem);'}
  }

  // left tab serif
  &.morphType${MorphType.DownloadAppBanner} .tab::before,
  &.morphType${MorphType.LoanProductPriceTable} .tab::before {
    background: var(--orange700);
    clip-path: path('M0 1C5 1 10 6 10 11V1H0Z');    
    left: -9.75px;
    top: -1px;
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    display: block;
  }

  &.morphType${MorphType.Recording} .tab {
    font-size: 0;
  }

  &.morphType${MorphType.ErrandNew} .tab ::after,
  &.morphType${MorphType.ErrandEdit} .tab ::after {
    background: radial-gradient(circle at 100% 100%, transparent 8px, var(--peach900) 9px, var(--peach900) 10px);
  }

  &.morphType${MorphType.ErrandNew} .tab ::before,
  &.morphType${MorphType.ErrandEdit} .tab ::before {
    background: radial-gradient(circle at 0% 100%, transparent 8px, var(--peach900) 9px, var(--peach900) 10px);
  }

  &.morphType${MorphType.ErrandNew} .tab,
  &.morphType${MorphType.ErrandEdit} .tab{
    border-color: var(--peach900);
    background: var(--peach900);
    text-transform: none;
    padding: 0 30px;
  }

  &.morphType${MorphType.FormActiveChat} .tab,
  &.morphType${MorphType.FormOpen} .tab,
  &.morphType${MorphType.CreditRepairWelcome} .tab {
    padding: 0;
  }
  &.morphType${MorphType.RefinanceCalculatorWelcome} .tab {
    padding: 0;
  }

  &.morphType${MorphType.VideoListMenu} .tab {
    border: 0.0625rem solid var(--blue300);
    transform: translate(0, -0.1875rem);
  }
  &.morphType${MorphType.UserPromptsMenu} .tab {
    border: 0.0625rem solid var(--blue930);
    transform: translate(0, -0.1875rem);
  }
  &.morphType${MorphType.CreditRepairDisputeAccountType} .tab {
    border: 0.0625rem solid var(--blue995);
    transform: translate(0, -0.1875rem);
  }
  &.morphType${MorphType.PhotoPlain} .tab,
  &.morphType${MorphType.PhotoMain} .tab,
  &.morphType${MorphType.Attachment} .tab,
  &.morphType${MorphType.PrivateChat} .tab,
  &.morphType${MorphType.MessageOptions} .tab,
  &.morphType${MorphType.Errand} .tab,
  &.morphType${MorphType.FormInsertSignature} .tab,
  &.morphType${MorphType.FormNavigateInitials} .tab,
  &.morphType${MorphType.FormReadyToSend} .tab {
    border: 0.0625rem solid var(--orange000);
    border-top: none;
    transform: translate(0, -0.1875rem);
  }
  &.morphType${MorphType.PrivateChat} .tab {
    border: 0.0625rem solid var(--orange000);
    border-top: none;
    transform: translate(0, -0.1875rem);
  }

  &.morphType${MorphType.PhotoPlain} .tab,
  &.morphType${MorphType.PhotoMain} .tab,
  &.morphType${MorphType.Attachment} .tab,
  &.morphType${MorphType.VideoListMenu} .tab,
  &.morphType${MorphType.UserPromptsMenu} .tab,
  &.morphType${MorphType.CreditRepairDisputeAccountType} .tab,
  &.morphType${MorphType.MessageOptions} .tab {
    background-color: var(--orange000);
    color: var(--gray000);
  }
  &.morphType${MorphType.UserPromptsMenu} .tab {
    background-color: var(--blue930);
    color: var(--gray000);
  }
  &.morphType${MorphType.CreditRepairDisputeAccountType} .tab {
    background-color: var(--blue995);
    color: var(--gray000);
  }
  &.morphType${MorphType.VideoListMenu} .tab {
    background-color: green;
    color: var(--gray000);
  }
  &.morphType${MorphType.PrivateChat} .tab {
    background-color: var(--peach000);
    color: var(--gray920);
  }

  &.morphType${MorphType.VideoListMenu} .tab {
    background-color: var(--blue300);
    color: var(--gray000);
  }
  &.morphType${MorphType.Errand} .tab {
    border-color: var(--orange700);
    background-color: var(--peach300);
  }

  &.morphType${MorphType.FormInsertSignature} .tab,
  &.morphType${MorphType.FormNavigateInitials} .tab,
  &.morphType${MorphType.FormReadyToSend} .tab {
    border-color: var(--peach800);
    background: var(--gray000);
    padding: 0;
  }

  &.intermediatePixelDensity .tab::before,
  &.intermediatePixelDensity .tab::after {
    top: 0;
  }

  &.morphType${MorphType.FormActiveChat} .tab,
  &.morphType${MorphType.FormOpen} .tab,
  &.morphType${MorphType.CreditRepairWelcome} .tab {
    max-height: 1.125rem;
  }
  &.morphType${MorphType.RefinanceCalculatorWelcome} .tab {
    max-height: 1.125rem;
  }
  &.morphType${MorphType.DownloadAppBanner} .tab {
    background-color: var(--orange700);
    color: var(--gray000);
    transform: translate(0, -3px);
    width: 155px;
  }
  &.morphType${MorphType.LoanProductPriceTable} .tab {
    background-color: var(--orange700);
    color: var(--gray000);
    transform: translate(0, -1px);
  }
  
  // right tab serif
  &.morphType${MorphType.DownloadAppBanner} .tab::after,
  &.morphType${MorphType.LoanProductPriceTable} .tab::after {
    background: var(--orange700);
    clip-path: path('M11 1C6 1 1 6 1 11V1H11Z');    
    right: -8.75px;
    top: -1px;
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    display: block;
  }

  &.morphType${MorphType.PhotoPlain} .tab::after,
  &.morphType${MorphType.PhotoMain} .tab::after,
  &.morphType${MorphType.Attachment} .tab::after,
  &.morphType${MorphType.PrivateChat} .tab::after,
  &.morphType${MorphType.CalendarMonth} .tab::after,
  &.morphType${MorphType.DOB} .tab::after,
  &.morphType${MorphType.Time} .tab::after,
  &.morphType${MorphType.MessageOptions} .tab::after,
  &.morphType${MorphType.VideoListMenu} .tab::after,
  &.morphType${MorphType.UserPromptsMenu} .tab::after,
  &.morphType${MorphType.CreditRepairDisputeAccountType} .tab::after,
  &.morphType${MorphType.Errand} .tab::after,
  &.morphType${MorphType.ErrandNew} .tab::after,
  &.morphType${MorphType.ErrandEdit} .tab::after,
  &.morphType${MorphType.FormInsertSignature} .tab::after,
  &.morphType${MorphType.FormNavigateInitials} .tab::after,
  &.morphType${MorphType.FormReadyToSend} .tab::after {
    position: absolute;
    right: -0.5625rem;
    top: -0.03125rem;
    width: 0.5625rem;
    height: 0.75rem;
    content: '';
    display: block;
  }

  &.morphType${MorphType.Time} .tab::after{
    z-index: -1;
  }

  &.morphType${MorphType.Errand} .tab::before {
    background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--orange700) 0.5625rem, var(--peach300) 0.625rem);
  }

  &.morphType${MorphType.VideoListMenu} .tab::before {
    background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--blue300) 0.6625rem, var(--blue300) 0.625rem);
  }

  &.morphType${MorphType.UserPromptsMenu} .tab::before {
    background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--blue930) 0.5625rem, var(--blue930) 0.625rem);
  }

  &.morphType${MorphType.CreditRepairDisputeAccountType} .tab::before {
    background: radial-gradient(circle at 0% 100%, transparent 0.5rem, var(--blue995) 0.5625rem, var(--blue995) 0.625rem);
  }

  &.morphType${MorphType.Errand} .tab::after {
    background: radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--orange700) 0.5625rem, var(--peach300) 0.625rem);
  }

  &.morphType${MorphType.VideoListMenu} .tab::after {
    background: radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--blue300) 0.6625rem, var(--blue300) 0.625rem);
  }

  &.morphType${MorphType.FormInsertSignature} .tab::after,
  &.morphType${MorphType.FormNavigateInitials} .tab::after,
  &.morphType${MorphType.FormReadyToSend} .tab::after {
    background: radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--peach800) 0.5625rem, var(--gray000) 0.625rem);
  }
  
  &.morphType${MorphType.PhotoPlain} .tab::after,
  &.morphType${MorphType.PhotoMain} .tab::after,
  &.morphType${MorphType.Attachment} .tab::after,
  &.morphType${MorphType.MessageOptions} .tab::after {
    background: radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--orange000) 0.625rem);
  }
  &.morphType${MorphType.VideoListMenu} .tab::after {
    background: radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--blue300) 0.6625rem, var(--blue300) 0.625rem);
  }
  &.morphType${MorphType.UserPromptsMenu} .tab::after {
    background: radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--blue930) 0.5625rem, var(--blue930) 0.625rem);
  }
  &.morphType${MorphType.CreditRepairDisputeAccountType} .tab::after {
    background: radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--blue995) 0.5625rem, var(--blue995) 0.625rem);
  }
  &.morphType${MorphType.PrivateChat} .tab::after {
    background: radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--orange000) 0.5625rem, var(--peach000) 0.625rem);
  }
  &.morphType${MorphType.CalendarMonth} .tab::after,
  &.morphType${MorphType.DOB} .tab::after {
    background: ${useMorphContext().hideCalendarMonth ? 'transparent;' : 'radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--peach900) 0.5625rem, var(--gray000) 0.625rem);'} 
    top: -0.07125rem;
  }

  &.morphType${MorphType.Time} .tab::after {
    background: ${useMorphContext().hideTime ? 'transparent' : 'radial-gradient(circle at 100% 100%, transparent 0.5rem, var(--gray000) 0.5625rem, var(--peach800) 0.625rem);'}
  }

  &.morphType${MorphType.PhotoPlain},
  &.morphType${MorphType.PhotoMain},
  &.morphType${MorphType.Calendar}{
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }
  
  &.morphType${MorphType.Attachment},
  &.morphType${MorphType.Time},
  &.morphType${MorphType.FormInsertSignature},
  &.morphType${MorphType.FormNavigateInitials},
  &.morphType${MorphType.FormReadyToSend} {
    margin-left: ${useMediaQuery(theme.breakpoints.up('md')) ? '1.875rem' : '20px'};
    margin-right: ${useMediaQuery(theme.breakpoints.up('md')) ? '1.875rem' : '20px'};
  }


  &.morphType${MorphType.ShareCustomLink} .tab {
    background: transparent;
    min-width: 11rem;
  }
  `
);
