import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { Styles } from '../Styles/TypingIndicatorStyles';
import getTypingName from '../Common/getTypingName';
import { useSocketContext } from '../Contexts/socket';
import useTimeout from '@common/hooks/useTimeout';
import { MorphType } from '@common/MorphType';
import { useUserContext } from '@contexts/user';
import { AccessType } from '@common/AccessType';

const TypingIndicator: React.FC<PropsWithChildren<any>> = (props) => {
  const { t } = useTranslation();
  const { messagesSocket, isMessagesConnected } = useSocketContext();
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const { _id } = useUserContext();
  const morganIsAnalyzing: boolean = (errandContext.isMorganTyping || errandContext.isAnalyzing) && !props.operatorData;
  const { reset, clear } = useTimeout(() => errandContext.setIsMorganTyping(true), 5000);
  const stopTypingTimeoutRef = useRef(null);

  useEffect(() => {
    if (!isMessagesConnected) return;
    
    const chatEventHandler = (payload) => {
      // if operatorID, then it's from AI. also check if it's the user view
      if (payload.data.type !== 'typing') return; // guard statement
      // if (payload.data.operatorId) {
      //   setIsMorganTyping(payload.data.message === 'true');
      // }
      // otherwise check if it's a participant id and the user has the nickname morgan.
      else if (payload.data?.participantId) {
        const p = props.errand?.participants?.find((x) => x?.active && x?._id === payload?.data?.participantId);
        let typist = p ? getTypingName(p, props.operatorData !== undefined, payload.data?.isPrivate, _id) : undefined;
        if (!typist?.name) return;
        // if it's a nickname which is Morgan then...
        if (typist.name === 'angelai' && typist.isNickName === true && !props.operatorData) {
          // If Angel was analyzing and an operator nicknamed AngelAi starts typing, allow the
          // meditating angel gif to show
          if (payload.data?.message === 'true') {
            errandContext.setIsMorganTyping(true);
          }
          // Clear the gif after 15 seconds in case operator stops typing and doesn't send the message
          // (otherwise the gif will not go away)
          if (stopTypingTimeoutRef.current !== null) {
            clearTimeout(stopTypingTimeoutRef.current);
          }
          stopTypingTimeoutRef.current = setTimeout(() => {
            errandContext.setIsMorganTyping(false);
          }, 15000);
        }
        // if there was a participant at all, and if it's not the current user
        else {
          props.setIsTyping((prev) => {
            let index = !prev ? -1 : prev.findIndex((p) => p.participantId === payload.data?.participantId);
            if (index === -1 && payload.data?.message === 'true') {
              // add participant if they were not in the list (-1)
              return [...prev, typist];
            } else if (payload.data?.message !== 'true') {
              // remove the typist if they were in the list
              return [...prev?.slice(0, index), ...prev?.slice(index + 1)];
            }
            return prev;
          });
        }
      }
    };

    messagesSocket.current?.on('chat-event-emitted', chatEventHandler);
    return () => {
      messagesSocket.current?.off('chat-event-emitted', chatEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, props.errand?._id, props.errand?.participants]);

  useEffect(() => {
    if (
      props.errand?.messages === undefined ||
      props.errand?.messages === null ||
      props.errand?.messages.length === 0
    ) {
      // Clear timer if there are currently no messages in the errand 
      errandContext.setIsMorganTyping(false);
      errandContext.setIsAnalyzing(false);
      clear();
    }
    if ((props.errand?.messages?.length > 0) && !errandContext.isAnalyzing) {
      // There is a new message in the chat and Angel is not analyzing (as we do not want to clear the gif if she is
      // already analyzing)
      const messages = props.errand.messages || [];
      const lastMessage = messages[messages.length - 1];
      if (!lastMessage?.message ||
           lastMessage?.senderType === 'Operator' ||
           ['Notification', 'Errand'].includes(lastMessage?.messageType) ||
           lastMessage?.accessType === AccessType.system) {
        // The user has canceled an action, received a notification, message about their errands being completed, or
        // received a message from an operator. Clear the timeout and stop showing the gif. (Notification messages and
        // errand messages may be sent with a "User" senderType which is why we have added that check). Additionally, we
        // want to ignore system messages as these are not visisble to the user.
        errandContext.setIsMorganTyping(false);
        clear();
      } else if (errandContext.morphType !== MorphType.None) {
        // A morph type has been set. Clear the timeout and stop showing the gif
        errandContext.setIsMorganTyping(false);
        clear();
      } else if (
        lastMessage?.senderType !== 'Operator' &&
        lastMessage?.sender?._id === _id &&
        errandContext.morphType === MorphType.None
      ) {
        // The current user was the last to send a message in the chat and they are not currently answering an action.
        // If the message was sent/updated in the last minute, start the timer to show the meditating gif after 5
        // seconds
        const minutesSinceLastMessage = Math.abs(
          new Date(lastMessage.updatedAt).getTime() - new Date().getTime()
        ) / (1000 * 60);
        if (minutesSinceLastMessage < 1) {
          reset();
        }
      }
    } else {
      errandContext.setIsMorganTyping(false);
      clear();
    }

    return () => {
      errandContext.setIsMorganTyping(false);
      clear();
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errand?.messages, errandContext.morphType, errandContext.isAnalyzing]);

  return morganIsAnalyzing ? (
    // If Angel is analyzing, then the ConversationBody component will dislpay the meditating angel.
    // This is so that it can appear as a message (as opposed to being superimposed over the messages, which
    // would be seen when scrolling up in the conversation)
    <></>
  ) : (
    <Styles
      className={[
        ...(morganIsAnalyzing ? ['morganIsAnalyzing'] : []),
        ...(props.isTyping?.length ? ['typing'] : []),
        ...(props.isPrivate ? ['isPrivate'] : []),
      ].join(' ')}
    >
      <div>
        <span></span>
        <span></span>
        <span></span>
        {
          <>
            {props.isTyping.length === 1 && errandContext.isMorganTyping ? (
              <p>{`${props.isTyping[0].name} ${t('and')} AngelAi ${t('areTyping')}`}</p>
            ) : props.isTyping.length === 1 ? (
              <p>{`${props.isTyping[0].name} ${t('isTyping')}`}</p>
            ) : props.isTyping.length === 2 ? (
              <p>{`${props.isTyping[0].name} ${t('and')} ${props.isTyping[1].name} ${t('areTyping')}`}</p>
            ) : (
              <p>{t('multipleTyping')}</p>
            )}
          </>
        }
      </div>
    </Styles>
  );
};

export default TypingIndicator;
