import React, { useEffect, useState, useContext, useCallback } from 'react';
// import FolderIcon from '@assets/Icons/AttachmentMenu/FolderIcon.svg';
import DocumentsIcon from '@assets/Icons/AttachmentMenu/DocumentsIcon.svg';
import CameraIcon from '@assets/Icons/AttachmentMenu/CameraIcon.svg';
import PicturesIcon from '@assets/Icons/AttachmentMenu/PicturesIcon.svg';
import ContactsIcon from '@assets/Icons/AttachmentMenu/ContactsIcon.svg';
import { useTranslation } from 'react-i18next';
import Styles from "../Styles/MorphAttachmentMenu.module.css";
import Snackbar from '@mui/material/Snackbar';
import handleFile from '@common/handleFile';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import ThinClientUtils from '@common/ThinClientUtils';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import { sendWorkflow } from '@common/WorkflowsUtils';
import axiosCall from '@services/axios';
import { IUserData, IErrand } from '../Interfaces/Conversation';
import { getPrimaryParticipant } from '@common/errandUtils';
import { AccessType } from '@common/AccessType';
import { MorphType } from '@common/MorphType';
import { useUserContext } from '@contexts/user';

type MorphAttachmentMenuProps = {
  setShowPermissionReminder: (value: boolean) => void;
  setIconToShow: (value: string) => void;
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  handleOpenContacts: () => void;
  setShowContactsConsent: (value: boolean) => void;
  selectedFiles: File[];
  operatorData?: IUserData;
  errand: IErrand;
};

const MorphAttachmentMenu: React.FC<React.PropsWithChildren<MorphAttachmentMenuProps>> = ({ selectedFiles, setSelectedFiles, setShowContactsConsent, handleOpenContacts, children, ...props }) => {
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const { t } = useTranslation();
  const { _id, isOperator } = useUserContext();
  const [isWarningActive, setIsWarningActive] = useState(false);
  const [isCameraAllowed, setIsCameraAllowed] = useState(false);
  const CONTACTS_LIMIT = 2;
  const CONTACTS_ORDER_BY = 'displayName';

  const handleCameraClick = (event) => {
    // Thin Client specific event to request Camera access on click
    if (ThinClientUtils.isThinClient()){
      window.dispatchEvent(new CustomEvent('cameraAccessEvent', {
        detail: {
          key: 'cameraAccessEvent',
        }
      }))
    }
    // check camera permissions
    // if granted, continue to camera
    // if denied, show permission reminder
    if (!isCameraAllowed) {
      event.preventDefault();
      // prompt user for camera permission
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: 'environment',
          },
        })
        .then(() => {
          setIsCameraAllowed(true);
          props.setShowPermissionReminder(false);
        })
        .catch((err) => {
          console.error(err);
          props.setIconToShow('cam');
          props.setShowPermissionReminder(true);
        });
    }
  };

  const handleFiles = (e) => {
    let files = handleFile(e);
    if (typeof files === 'string') {
      setIsWarningActive(t(files));
    } else {
      setSelectedFiles((prev) => {
        let mergedFiles = [...prev, ...files] as File[];
        let uniqueFiles = mergedFiles.filter((value: File, index: number, array: File[]) =>
          array.findIndex((v: File) => v === value) === index
        );
        return uniqueFiles;
      });
    }
  };
  const getContactsList = useCallback(
    async (offset) => {
      const data = {
        userId: _id,
      };

      let request = {
        url: `contact/search?offset=${offset}&limit=${CONTACTS_LIMIT}&orderBy=${CONTACTS_ORDER_BY}`,
        method: 'POST',
        data: data,
      };
      try {
        const fetchedContacts = await axiosCall(request);
        return fetchedContacts;
      } catch (error) {
        console.log(error);
      }
    },
    [_id]
  );

  const sendInviteFriendWorkflow = async () => {
    try {
      const recipients = isOperator ? [getPrimaryParticipant(props.errand)?.userData?._id] : [_id];
      // send Invite Friend workflow to user
      await sendWorkflow(
        '',
        'Invite Friend',
        props.errand._id,
        recipients,
        AccessType.public,
        _id,
        props.operatorData !== undefined,
        false
      );
    } catch (err) {
      console.error('MorphAttachmentMenu.sendInviteFriendWorkflow', err);
    }
    errandContext.setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });
  };

  const handleClickAddFriend = async () => {
    try {
      // Always send workflow on desktop
      if (!ThinClientUtils.isThinClient()) {
        await sendInviteFriendWorkflow();
        return;
      }
      const fetchedContacts = await getContactsList(0);
      if (Array.isArray(fetchedContacts) && fetchedContacts.length > 0) {
        // user has contacts, display them
        handleOpenContacts();
        return;
      }
      // User doesn't have any contacts or hasn't granted permission
      // check if permission has not been asked
      if (localStorage && typeof localStorage.getItem === 'function' && localStorage.getItem('contactConsent') === null) {
        // display the consent modal to ask user for permission
        setShowContactsConsent(true);
        return;
      }

      await sendInviteFriendWorkflow();
      return;
    } catch (err) {
      console.error('MorphAttachmentMenu.handleClickAddFriend', err);
    }
  };

  const handleClickPictures = () => {
    if (ThinClientUtils.isThinClient()) {
      window.dispatchEvent(
        new CustomEvent('picAccessEvent', {
          detail: {
            key: 'picAccessEvent',
          },
        })
      );
    }
  };
  // const handleAttachmentContactsClick = () => {
  //   if (localStorage.getItem('contactConsent')) {
  //     handleOpenContacts();
  //   } else {
  //     // Thin Client only
  //     // Send contacts permission request to user with postMessage
  //     // As soon as permission is enabled, sync contacts
  //     localStorage.setItem('contactConsent', JSON.stringify(true))
  //     window.dispatchEvent(new StorageEvent('storage', {
  //       key: 'contactConsentYes',
  //       newValue: JSON.stringify(true),
  //       oldValue: localStorage.getItem('contactConsent'),
  //       url: window.location.href,
  //       storageArea: localStorage
  //     }));
  //   }
  // };

  useEffect(() => {
    function preventDefault(e) {
      e.preventDefault();
    }
    // required to process files dropped on this conversation
    errandContext?.boundaryRef?.current?.addEventListener('drop', handleFiles);
    // both are required to prevent opening files in a new tab if the file is dropped elsewhere.
    window.addEventListener('drop', preventDefault);
    window.addEventListener('dragover', preventDefault);
    return () => {
      window.removeEventListener('drop', preventDefault);
      window.removeEventListener('dragover', preventDefault);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Snackbar
        open={isWarningActive ? true : false}
        autoHideDuration={6000}
        onClose={() => setIsWarningActive(null)}
        message={isWarningActive}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      <div className={Styles.morphAttachmentMenuWrapper}>
        {/* Folder Icon*/}
        {/* <button className={Styles.btn}>
          <div className={Styles.btnBorder}>
            <img src={FolderIcon} alt="FolderIcon" />
            <p className={Styles.label}>{t('tFolder')}</p>
          </div>
      </button> */}

        {/* Documents Icon */}

          <label className={Styles.btn}>
            <div className={Styles.btnBorder}>
              <input type="file"
                // accept is synced with handleFile.ts
                accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .xml, .csv, .txt, .rtf, .odt, .ods, .odp, .epub, .mobi, .azw, .fb2, .djvu, .xps, .fnm, .jpg, .jpeg, .png, .gif, .bmp, .tiff, .ico, .webp, .m4a, .mp3, .wav, .aac, .flac, .wma, .aiff"
                multiple={true}
                style={{ display: 'none' }}
                onChange={handleFiles} />
              <img src={DocumentsIcon} alt="DocumentsIcon" />
              <p className={Styles.label}>{t('tDocuments')}</p>
            </div>
          </label>

        {/* Camera Icon */}
        {isMobileOrTablet() && (
            <label className={Styles.btn}>
              <div className={Styles.btnBorder}>
                <input type="file"
                  accept="image/*"
                  capture="environment"
                  style={{ display: 'none' }}
                  onClick={handleCameraClick}
                  onChange={handleFiles} />
                <img src={CameraIcon} alt="CameraIcon" />
                <p className={Styles.label}>{t('tCamera')}</p>
              </div>
            </label>
        )}

        {/* Pictures Icon */}
          <label className={Styles.btn}>
            <div className={Styles.btnBorder}>
              <input type="file"
                accept="image/*"
                multiple
                style={{ display: 'none' }}
                onChange={handleFiles} 
                onClick={handleClickPictures} />
              <img src={PicturesIcon} alt="PicturesIcon" />
              <p className={Styles.label}>{t('tPictures')}</p>
            </div>
          </label>

        {/* Contacts Icon */}
        {!props.operatorData && (
          <button className={Styles.btn} onClick={handleClickAddFriend}>
            <div className={Styles.btnBorder}>
              <img src={ContactsIcon} alt="ContactsIcon" />
              <p className={Styles.label}>{t('tAddFriend')}</p>
            </div>
          </button>
        )}
      </div>
      <div className={Styles.morphAttachmentMenuBottomBorder}></div>
    </>
  );
};

export default MorphAttachmentMenu;
