import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import React from 'react';
import { CircularProgress } from '@mui/material';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import useAbortController from '@common/hooks/useAbortController';
import axiosCall from '../Services/axios';
import { ElectronicSignatureEventType } from '../Forms/commonForms';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import eventBus from '@common/eventBus.js';
import { useRootContext } from '@contexts/RootContext';
import { IFormContext, FormContext } from '@contexts/FormContext';
import { AccessType } from '@common/AccessType';
import {
  formWrapper,
  EsignActionStateType,
  FormClickEventType,
  StyledSignatureButton,
  EsignFormType,
} from './commonForms';

import * as pdfjs from 'pdfjs-dist';
import { useUserContext } from '../Contexts/user';

//TODO: Deploy worker internally.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFSign = (props) => {
  const { PDFData, setPDFData, templateId, parentId, loadingPdf, errandName } = props;

  //use states
  const [scaleState, setScaleState] = useState(1);
  const canvasRef = useRef(null);
  const renderTaskRef = useRef(null); // Ref to store the render task
  const wrapperRef = useRef(null);
  const pdfContainerRef = useRef(null);
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const rootContext = useRootContext();
  const [submittedClicked, setSubmittedClicked] = useState(false);
  const abortController = useAbortController();
  // uses a ref to prevent unnecessary re renders and prevents the error log and should stop the flip behavior
  const isRendering = useRef<boolean>(false);
  const [loading, setLoading] = useState(false);
  const formContext = useContext<IFormContext>(FormContext);
  const { _id } = useUserContext();
  const primaryParticipantData = errandContext.getPrimaryParticipantFullName();
  const firstName = primaryParticipantData.userData.firstname;
  const lastName = primaryParticipantData.userData.lastname;

  const base64ToUint8Array = (base64) => {
    const raw = atob(base64);
    const uint8Array = new Uint8Array(raw.length);
    for (let i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  };

  const insertSignature = async (id) => {
    setLoading(true);
    try {
      const requestData = {
        url: `ai-sign/forms/${errandContext.errandId}/signdocument`,
        method: 'post',
        data: {
          templateId,
          anchorId: id,
          PDFData,
          firstName,
          lastName,
          formName: EsignFormType.AISIGN,
          trueTimeZone: formContext.timeZoneGrabber(),
          trueBrowser: formContext.browserTypeGrabber(),
          signatureImageData: errandContext.wetSignature,
          borrowerName: `${firstName} ${lastName}`,
          templateName: PDFData.templateName,
        },
      };
      const response = await axiosCall(requestData);
      // Update the PDFData state variable with the new documentBase64
      setPDFData((prevPDFData) => {
        const updatedSigners = prevPDFData.signers.map((signer) => ({
          ...signer,
          anchorPositions: signer.anchorPositions.map((anchor) =>
            anchor.anchorId === id ? { ...anchor, signed: true } : anchor
          ),
        }));

        //check if the doc is ready to be signed.
        const anchors = PDFData?.signers?.map((signer) => signer.anchorPositions)[0];
        let signedAnchorsCount = anchors.filter((anchor) => anchor.signed === true).length;
        // all of the anchors have been signed and we can display the submit button.
        console.log('anchors are', anchors, 'signed are', signedAnchorsCount);
        if (anchors.length !== 0 && signedAnchorsCount + 1 >= anchors?.length) {
          errandContext.newFormEvent(ElectronicSignatureEventType.ReadyToSendDocument);
        }

        return {
          ...prevPDFData,
          documentBase64: response.updatedDocument,
          signers: updatedSigners,
        };
      });
      await renderPDF(response.updatedDocument);

      // Logic to render PDF using updatedPDFBase64
    } catch (error) {
      setLoading(false);
      console.error('Error inserting signature:', error);
    }
    setLoading(false);
  };

  const renderPDF = useCallback(
    async (data) => {
      if (isRendering.current) {
        console.log('error: PDF is currently rendering');
        return;
      }
      isRendering.current = true;
      data = base64ToUint8Array(data);

      // Adjust for device pixel ratio
      const devicePixelRatio = window.devicePixelRatio || 1;

      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
        renderTaskRef.current = null;
      }

      const loadingTask = pdfjs.getDocument({ data });
      try {
        const pdf = await loadingTask.promise;
        if (!PDFData.totalPages) {
          setPDFData((prevPDFData) => ({ ...prevPDFData, totalPages: pdf._pdfInfo.numPages }));
        }
        errandContext.setFormTotalPages(pdf._pdfInfo.numPages);

        const page = await pdf.getPage(errandContext.formCurrentPage);

        let rotation = 0;
        if (page.rotate === 180 || page.rotate === -180) {
          rotation = 180;
        }

        const wrapperWidth = wrapperRef.current.clientWidth;
        // 4/5/24 - Icy: This viewport scale used to just be 0.9, but when more than 1 errand was open, it would
        // cause the window size to change with every page-turn. Thus, I added a conditional to only use 0.9 scale
        // when the form is the only errand shown to the user, allowing the user to read more easily and fixing the
        // window resizing issue
        const viewport = page.getViewport({ scale: rootContext.selectedIndex.length !== 2 ? 0.9 : 1, rotation });
        const scale = wrapperWidth / viewport.width;
        setScaleState(scale);
        const scaledViewport = page.getViewport({ scale, rotation });

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.width = scaledViewport.width * devicePixelRatio;
        canvas.height = scaledViewport.height * devicePixelRatio;
        canvas.style.width = `${scaledViewport.width}px`;
        canvas.style.height = `${scaledViewport.height}px`;

        context.scale(devicePixelRatio, devicePixelRatio);

        const renderContext = { canvasContext: context, viewport: scaledViewport };
        const renderTask = page.render(renderContext);
        renderTaskRef.current = renderTask;
        await renderTask.promise;

        if (pdfContainerRef.current && canvasRef.current) {
          pdfContainerRef.current.style.height = `${canvasRef.current.style.height}`;
        }
      } catch (error) {
        console.error('Error rendering PDF: ', error);
      } finally {
        isRendering.current = false;
      }
    },
    [PDFData, errandContext.formCurrentPage]
  );

  const renderSignatureAnchors = () => {
    if (!PDFData || !Array.isArray(PDFData.signers) || PDFData.signers.length === 0) {
      return null;
    }
    const anchors = PDFData.signers?.map((signer) => signer.anchorPositions);

    const flattenedAnchors = [].concat(...anchors);

    return flattenedAnchors.map((anchor, index) => {
      if (anchor.pageNumber === errandContext.formCurrentPage) {
        const scaledXpos = anchor.xpos * scaleState;
        const scaledYpos = anchor.ypos * scaleState;

        const style: React.CSSProperties = {
          // Explicitly declaring the type here
          position: 'absolute',
          left: `${scaledXpos}px`,
          top: `${scaledYpos}px`,
        };
        return (
          <div key={index} style={style}>
            {/* 'auth' + N represents a certain number signature */}
            {anchor.anchorType === 'dateSignedTab' &&
              DateComponent(scaleState, 'auth' + (index + 1).toString(), anchor.anchorId, anchor.signed)}
            {anchor.anchorType === 'signHereTab' &&
              signButtonWrapper(scaleState, 'auth' + (index + 1).toString(), anchor.anchorId, anchor.signed)}
            {anchor.anchorType === 'initialHereTab' &&
              signatureWrapper(scaleState, 'auth' + (index + 1).toString(), anchor.anchorId, anchor.signed)}
          </div>
        );
      }
      return null;
    });
  };

  const handleResize = useCallback(async () => {
    // Call the function to re-render the PDF with the new scale
    if (PDFData?.documentBase64) {
      await renderPDF(PDFData.documentBase64);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PDFData, errandContext.formCurrentPage]);

  useWindowDimensions(handleResize);

  useEffect(() => {
    if (PDFData) {
      if (PDFData?.documentBase64) {
        handleResize();
      }
      if (PDFData.submitted && submittedClicked) {
        errandContext.setEsignActionState(EsignActionStateType.Signed);
      }
      const anchors = PDFData.signers?.map((signer) => signer.anchorPositions)[0];
      let signedAnchorsCount = anchors?.filter((anchor) => anchor.signed === true).length;
      // all of the anchors have been signed and we can display the submit button.
      if (anchors && anchors.length !== 0 && anchors.length === signedAnchorsCount) {
        errandContext.newFormEvent(ElectronicSignatureEventType.ReadyToSendDocument);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    PDFData,
    PDFData?.documentBase64,
    errandContext.esignActionState,
    errandContext.formCurrentPage,
    rootContext.selectedIndex?.length,
  ]); // Dependency array includes PDFData

  const signButtonWrapper = (scale, input: string, id, signed) => {
    return (
      <div
        style={{
          height: `${20 * scale}px`, // Scale height
          width: `${100 * scale}px`,
        }}
      >
        {!signed && (
          <StyledSignatureButton
            id={input}
            onClick={() => {
              insertSignature(id);
            }}
            variant="outlined"
          >
            Signature
          </StyledSignatureButton>
        )}
      </div>
    );
  };

  const signatureWrapper = (scale, input: string, id, signed) => {
    return (
      <div
        style={{
          height: `${20 * scale}px`, // Scale height
          width: `${100 * scale}px`,
        }}
      >
        {!signed && (
          <StyledSignatureButton
            id={input}
            onClick={(e) => {
              insertSignature(id);
            }}
            variant="outlined"
          >
            <p style={{ whiteSpace: 'nowrap' }}>Print Name</p>
          </StyledSignatureButton>
        )}
      </div>
    );
  };

  const DateComponent = (scale, input, id, signed) => {
    // Adjust styles based on the scale
    const scaledStyle = {
      height: `${20 * scale}px`, // Scale height
      width: `${140 * scale}px`, // Scale font size, adjust as needed
    };

    return (
      <div>
        {!signed && (
          <div style={scaledStyle}>
            <StyledSignatureButton
              id={input}
              onClick={(e) => {
                insertSignature(id);
              }}
              variant="outlined"
            >
              <p style={{ whiteSpace: 'nowrap' }}>Sign Date</p>
            </StyledSignatureButton>
          </div>
        )}
      </div>
    );
  };

  const downloadForm = async () => {
    const config = abortController.get();
    // Assuming PDFData.documentBase64 contains your base64 string
    //make axioscall to retrieve the template.
    setLoading(true);
    const response = await axiosCall(
      {
        url: `ai-sign/forms`,
        method: 'POST',
        //passing in the chat var lets us query for a saved document.
        data: { templateId, chat: errandContext.errandId },
      },
      config
    );
    //it will be 'response' if the user has saved a form previously. [0] if it's directly from ai-sign
    const data = response.templates?.[0] || response;
    const base64String = data?.documentBase64;
    // disable download button
    errandContext.setEsignActionState(EsignActionStateType.Downloading);

    try {
      // Convert base64 to a Blob
      const cleanBase64 = base64String.replace(/(\r\n|\n|\r)/gm, '');
      const pdfBlob = base64ToBlob(cleanBase64, 'application/pdf');

      // Create a URL and download the file
      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `INFLUENCER - ${firstName} ${lastName}.pdf`);
      document.body.appendChild(link); // Required for Firefox
      link.click();
      document.body.removeChild(link); // Clean up
      URL.revokeObjectURL(url);

      // Set state to signed
      errandContext.setEsignActionState(EsignActionStateType.Signed);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      errandContext.setEsignActionState(EsignActionStateType.Failed);
    }
    setLoading(false);
  };

  // Helper function to convert a base64 string to a Blob
  function base64ToBlob(base64, mimeType) {
    // Remove any headers or spaces/newlines

    // Decode the base64 string
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  /**
   * Fill userAction's response manually so that workflow can continue
   */
  const continueWorkflow = async (actionFieldName: string, text: string) => {
    // validate parentId
    if (parentId === undefined || parentId === null) {
      console.error("parentId undefined!");
      return;
    }

    // fetch parent chats last 15 messages
    // rootContext doesn't have parent chat's message especially if parent chat is not in view
    const resp = await axiosCall({ url: `chat/${parentId}/message?order=desc&orderBy=createdAt&limit=15`, method: 'GET' });

    if (Array.isArray(resp) === false) {
      console.error("Invalid response!");
      return;
    }

    // find associated action
    const message = resp.reverse().find((x) => x?.action?.fieldName === actionFieldName);

    if(!message) {
      return;
    }

    // continue workflow
    const data = {
      sender: _id,
      senderType: 'User',
      accessType: AccessType.internal,
      message: text,
      messageType: 'Field',
      userAction: message?.userAction?._id,
    };

    const payload = { url: `chat/${parentId}/message`, method: 'POST', data: data };

    await axiosCall(payload);
  };

  const signForm = async () => {
    try {
      errandContext.setEsignActionState(EsignActionStateType.Signing);

      const requestData = {
        url: `chat/${errandContext.errandId}/signdocument`,
        method: 'post',
        data: {
          templateId,
          errandName: errandName,
          formName: EsignFormType.AISIGN,
          initialImageData: errandContext.wetInitial,
          signatureImageData: errandContext.wetSignature,
          borrowerName: `${firstName} ${lastName}`,
        },
      };
      await axiosCall(requestData);
      errandContext.setEsignActionState(EsignActionStateType.Signed);

      // Go to parent caht
      rootContext.leaveFormGoToParentChat(parentId);

      setSubmittedClicked(true);

      await continueWorkflow('INFLUENCER', EsignFormType.AISIGN);
      await continueWorkflow('brokerLandingPageAddendum', EsignFormType.AISIGN);
    } catch (error) {
      errandContext.setEsignActionState(EsignActionStateType.Failed);
      console.error('Error signing BCERTA', error);
    }
  };

  useEffect(() => {
    // subscribe event bus
    eventBus.on(FormClickEventType.FormDownload, downloadForm);
    eventBus.on(FormClickEventType.FormSign, signForm);

    return () => {
      eventBus.remove(FormClickEventType.FormDownload, downloadForm);
      eventBus.remove(FormClickEventType.FormSign, signForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // paint first initial as a green
    const element = document.getElementById('auth1');
    if (element && element.tagName === 'BUTTON') {
      element.style.background = 'var(--green700)';
      element.style.color = 'var(--gray000)';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const overlayStyle: React.CSSProperties = {
    position: 'absolute', // Make sure this is a string literal, not a variable
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'var(--shadow501)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  return (
    <div ref={wrapperRef} style={formWrapper}>
      {(loading || loadingPdf) && (
        <div style={overlayStyle}>
          <CircularProgress />
        </div>
      )}
      <div
        ref={pdfContainerRef}
        style={{ position: 'relative', top: 0, left: 0, right: 0, overflowY: 'hidden', height: '100%' }}
      >
        <canvas ref={canvasRef}></canvas>
        {renderSignatureAnchors()}
      </div>
    </div>
  );
};

export default PDFSign;
