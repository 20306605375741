import React, { Dispatch, MutableRefObject, SetStateAction, createContext, useContext } from 'react';

/**
 * This context will store data that should be shared amonst all messages
 * or to pass state without prop drilling.
 * 
 * NOTICE: Avoid adding useState variables when possible
 */

export interface IMessageContext {
  bodyRef: MutableRefObject<HTMLDivElement>;
  isPrivate?: boolean;
  isDisclaimerSeparate?: boolean;
  setPreviewUrl: Dispatch<SetStateAction<string>>;
  editMessageId?: string;
  disclaimerHeight?: number;
  setDisclaimerHeight?: Dispatch<SetStateAction<number>>;
  hasMoreMessages?: MutableRefObject<boolean>;
}

export const MessageContext = createContext<IMessageContext | null>(null);

export const useMessageContext = () => useContext<IMessageContext>(MessageContext);
