/**
 * @file This component is a stylized Button component
 * for generating the Resend component that appears
 * in the input box when triggering the SEND_EMAIL_OTP command
 * which sends the user an OTP
 * 
 * @author Harrison Fales <harrison.fales@swmc.com>
 */

import React, { useCallback, useRef } from 'react';
import { Button, Divider } from "@mui/material"
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { sendWorkflow } from '@common/WorkflowsUtils';
import ErrorBoundary from "@components/ErrorBoundary";
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';

const Resend = styled(Button)({
  border: "1px solid var(--peach900)",
  height: "25px",
  padding: "5px",
  textAlign: "center",
  marginLeft: "5px",
  minWidth: "max-content",
  whiteSpace: "nowrap",
  "&:hover": {
    backgroundColor: "var(--peach900)",
    border: "1px solid var(--gray000)",
    color: "var(--gray000)"
  },
  textTransform: "none"
});

const Line = styled(Divider)(({ theme }) => ({
  height: '25px',
  margin: '0 7px',
  backgroundColor: theme.palette.orange['600'],
}));


const ResendOTPButton = (props) => {
  const { t } = useTranslation();
  const { _id } = useUserContext();
  // useRef used for preventing too many repeated network calls by rapidly clicking Resend button
  // Make be converted into a timer, or something that further increases time required in between action requests
  const isSendingAction = useRef(false);

  const handleOnClick = useCallback(async () => {
    if (isSendingAction.current) {
      console.warn(`Cannot submit action, waiting for request to complete.`);
      return;
    }

    isSendingAction.current = true;
    const workflowName = 'Resend OTP';

    if (!props.errand?.action?.action?.fieldName) {
      console.warn(`Cannot generate resend button: No action fieldName`);
      isSendingAction.current = false;
      return;
    }

    if (!props.errand._id || props.errand._id === '') {
      console.warn(`Cannot submit workflow  ${workflowName}, invalid chat id: ${props.errand._id}`);
      isSendingAction.current = false;
      return;
    }

    if (!_id || _id === '') {
      console.warn(`Cannot submit workflow ${workflowName}, invalid user id: ${_id}`);
      isSendingAction.current = false;
      return;
    }

    // This is not sent with the props.errand.recipients due to the async nature of setState, and the fact that
    // the recipients updated property will not be immediately available.
    await sendWorkflow('', workflowName, props.errand._id, [_id], AccessType.public, _id, false, null, true);
    isSendingAction.current = false;
  }, [_id]);

  return <ErrorBoundary debug={`ResentOTPButton: ${JSON.stringify(props)}`}>
    <Resend onClick={handleOnClick}>{t('resendOTP')}</Resend>
    <Line orientation='vertical' />
  </ErrorBoundary>
}

export default ResendOTPButton;