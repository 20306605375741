import React, { useCallback } from 'react';
import { Fade, IconButton, Stack } from '@mui/material';

import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import AnimationStyles from "@styles/ChatBubbleAnimations.module.css";
import Sanitized from '@components/Sanitized';
import MessageTextStyle from '@styles/MessageTextStyle';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useTranslation } from 'react-i18next';
import { useErrandContext } from '@contexts/ErrandContext';
import { IMessage } from '@interfaces/Conversation';
import MessageTime from '@components/MessageTime';
import { useMessageContext } from '@contexts/MessageContext';
import { AccessType } from '@common/AccessType';
import { ValidatorFunctions } from '@common/Validators';
import { maskMessage, removeHtmlTags } from '@common/StringUtils';
import { getDropdownDescription, isNotOtpNorPassword } from '@common/StringUtils';
import { useRootContext } from '@contexts/RootContext';
import { MorphType } from '@common/MorphType';
import { ResetFooterUserAction } from '@common/common';

const WoodGrain = process.env.REACT_APP_MORGAN_CDN + '/Images/woodgrain.png';

type TFieldMessageContentProps = {
  message: IMessage;
}

const getEditedMessageText = (message) => {
  // if it has edited message data (for QID or other relevant msg types usage)
  if (ValidatorFunctions.isNotUndefinedNorNull(message.editedMessageData) === true) {
    return [message.editedMessageData.first, message.editedMessageData.second];
  } else {
    // default Arrow based edited message parsing
    const parts = message?.message.split(`<i class="messageArrow"/>`);
    return [removeHtmlTags(parts[0]), removeHtmlTags(parts[1])];
  }
};

// Determines whether the field value should be masked by checking if the field attribute has a mask
const shouldMaskValue = (message: IMessage): boolean => (message.action?.fieldAttribute?.mask?.length > 0);

const FieldMessageContent = ({
  message
}: TFieldMessageContentProps) => {
  const { t } = useTranslation();
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const messageContext = useMessageContext();
  const isEditing = messageContext?.editMessageId === message?._id;
  let editedMessageText: null | string[] = message?.messageStatus === 'edited' ? getEditedMessageText(message) : null;
  // If the message should be masked, then messageToDisplay will contain the masked value (masked using the Common
  // maskMessage function). Otherwise, it will contain the original message
  const messageToDisplay = shouldMaskValue(message)
    ? maskMessage(message?.message, message.action?.fieldAttribute?.mask)
    : message?.message;

  //we use the editMessageSet for messages that want to point out which messages are editable.
  const messageInEditArray = errandContext.editMessageSet.has(message._id);

  const handleClickEdit = useCallback(() => {
    // set up editable message value
    let msg = message.message || '';
    // Remove replies
    if (msg.indexOf('</blockquote>') !== -1) {
      // Remove the old message.
      msg = msg.split(`</blockquote>`)[1];
    }
    // Remove previous edits
    if (msg.indexOf('<i class="messageArrow"/>') !== -1) {
      // Remove the old message.
      msg = msg.split(`<i class="messageArrow"/>`)[1];
    }
    // Remove user message from operator corrections
    if (msg.indexOf(t('acceptedData')) !== -1) {
      // Remove the user message
      msg = msg.split(t('acceptedData'))[1];
      // Remove the ) at the end of the message
      msg = msg.substring(0, msg.length - 1).trim();
    }
    // Remove html
    if (msg.indexOf('href=') !== -1) {
      msg = msg.replace(/<\/?a[^>]*>/g, '');
    }

    // Parse the field data to show the description by extracting it from the message. 
    msg = getDropdownDescription(msg);

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(msg, 'text/html');

    msg = htmlDoc.body.textContent;

    // set states as needed
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === message.chat);

      if (chatObj) {
        if (message.messageType === 'Field') {
          chatObj.icon = message.icon;
          chatObj.placeholder = message?.action?.description;
          chatObj.action = {
            ...message?.userAction,
            action: {
              ...message?.action,
            },
            userActionId: message?.userAction?._id,
            active: true,
          };
          chatObj.recipients = message.intendedAudience ? [message.sender._id, ...message.intendedAudience].sort() : [];
        } else {
          ResetFooterUserAction(chatObj);
        }
  
        if (message.intendedAudience) {
          chatObj.recipients = [message.sender._id, ...message.intendedAudience].sort();
        } else {
          chatObj.recipients = [];
        }
      }

      return [...prev];
    });

    errandContext.setEditMessageId(message._id);
    if (isNotOtpNorPassword(message)) errandContext.setValue(msg);
    errandContext.footerInputRef.current?.focus();

    // set edit morph type logic
    const fieldAttributeObj = message?.action?.fieldAttribute as any;
    errandContext.setMorphType(MorphType.Edit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message?._id, message?.message, message?.sender?._id]);

  return (
    <Stack
      display='flex'
      flexDirection='column'
      alignItems={
        // On the user side, Team, and Group chats: only sender is on the right side
        // On the operator side, only sender (operator) and Morgan is on the right side
    message?.alignByCurrentUser
            ? 'flex-end'
            : 'flex-start'
      }
      width='fit-content'
      maxWidth={window.innerWidth > 900 ? "min(60%, calc(100vw - 50px))" : "min(90%, calc(100vw - 50px))"}
      minWidth={'85px'}>
      {/* Added an additional stack wrapper because the translate sub bubble will position itself
                in the center of the entire stack, and become off center, if a thumbnail is rendered */}
      <button onClick={handleClickEdit} style={{ border: 'none', display: 'flex', flexDirection: 'row', background: 'none', cursor: 'default', padding: '0' }}>
        <ChatBubbleStyle
          className={messageInEditArray ? AnimationStyles.pulsing : ''}
          sx={{
            minWidth: '85px',
            borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : isEditing ? 'var(--blue050)' : 'var(--orange700)',
            background: message.accessType === AccessType.internal
              ? 'var(--peach020)'
              : message.sentByCurrentUser
                ? 'var(--gray000)'
                : 'var(--peach600)',
            pointerEvents: 'all',
            overflow: 'hidden',
          }}>
          <Stack flexDirection='column' width='100%'>
            <Stack flexDirection='row'>
              {/* If the message is of type FIELD, render an svg icon at the END of the chat bubble */}
              {/* FOR USER SCREEN ONLY - OPERATOR SIDE HAS THIS ON THE END, USER AT THE BEGINNING */}
              {!message.operatorView && (
                <IconButton sx={{ p: 0 }}>
                  <Stack
                    alignItems='stretch'
                    justifyContent='center'
                    flexDirection='column'
                    sx={{
                      flexGrow: 1,
                      pr: 0.5,
                      height: '100%',
                      backgroundColor: 'var(--orange700)',
                      backgroundImage: `url(${WoodGrain})`,
                      border: 'none',
                      p: '5px',
                      cursor: 'pointer',
                    }}>
                    {typeof message?.icon === 'string' && message?.icon ? (
                      <img
                        style={{
                          height: '20px',
                          width: '20px',
                          filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
                        }}
                        src={message?.icon}
                        alt={`Action Icon`}
                      />
                    ) : (
                      <QuestionMarkIcon
                        fontSize='small'
                        sx={{
                          color: 'var(--gray000)',
                          height: '20px',
                          width: '20px',
                          filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
                        }}
                      />
                    )}
                  </Stack>
                </IconButton>
              )}
              <Stack
                alignItems='center'
                sx={{
                  d: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  p: '6px 7px 8px 9px',
                  maxWidth: '100%',
                  position: 'relative',
                  width: '100%',
                }}>
                  {message.messageStatus === 'edited' ? (
                    <>
                      <Stack direction='row' justifyContent='flex-start' alignItems='flex-start' flexWrap='wrap'>
                        <Fade in={true} appear={false}>
                          {message.visible ? (
                            // innerText doesn't exist on non HTML elements
                            <MessageTextStyle
                              sx={{
                                wordWrap: 'break-word',
                                color: 'var(--red400)',
                                textDecorationColor: 'var(--red400)',
                                textDecorationLine: 'line-through',
                                display: 'inline-flex'
                              }}>
                                {/* {message?.message.split(`<i class="messageArrow"/>`)[0]?.replace(/(<([^>]+)>)/gi, '')} */}
                                {/* editedMessageText[0]} */}
                                <Sanitized
                                  tag="s"
                                  highlight={message?.searchWords}
                                  html={shouldMaskValue(message) ? maskMessage(editedMessageText[0], message.action?.fieldAttribute?.mask) : editedMessageText[0]}
                                  visible={message?.visible}
                                />
                              </MessageTextStyle>
                          ) : (
                            <MessageTextStyle>{messageToDisplay}</MessageTextStyle>
                          )}
                        </Fade>
                        <ArrowCircleRightRoundedIcon
                          sx={{
                            color: 'var(--orange700)',
                            marginBottom: '-7px',
                            display: 'inline-flex'
                          }}/>
                        <Fade in={true} appear={false}>
                          {message.visible ? (
                            // innerText doesn't exist on non HTML elements
                            <MessageTextStyle
                              sx={{
                                wordWrap: 'break-word',
                                fontStyle: 'italic',
                                display: 'inline-flex'
                              }}>
                                {/* {message?.message?.split(`<i class="messageArrow"/>`)[1]} */}
                                {/* {editedMessageText[1]} */}
                                <Sanitized
                                  highlight={message?.searchWords}
                                  html={shouldMaskValue(message) ? maskMessage(editedMessageText[1], message.action?.fieldAttribute?.mask) : editedMessageText[1]}
                                  visible={message?.visible}
                                />
                              </MessageTextStyle>
                          ) : (
                            <MessageTextStyle>{messageToDisplay}</MessageTextStyle>
                          )}
                        </Fade>
                      </Stack>
                    </>
                  ) : (
                    <Fade in={true} appear={false}>
                      <Stack direction='row' justifyContent='flex-start' alignItems='flex-start'>
                        <MessageTextStyle
                          sx={{
                            wordWrap: 'break-word',
                            justifyContent: 'flex-start',
                          }}>
                          <Sanitized
                            highlight={message?.searchWords ? message?.searchWords : undefined}
                            html={
                              message.visible
                                ? message?.message === undefined ? "" : messageToDisplay
                                : message?.message === undefined ? "" : message?.message.replace(/./g, '*')
                            }
                          />
                        </MessageTextStyle>
                      </Stack>
                    </Fade>
                  )}
                  &nbsp;
                  <MessageTime message={message} />
              </Stack>
              {/* If the message is of type FIELD, render an svg icon at the END of the chat bubble */}
              {/* FOR OPERATOR SCREEN ONLY - USER SIDE HAS THIS ON THE END, OPERATOR AT THE BEGINNING */}
              {message.operatorView && (
                <IconButton sx={{ p: 0, overflow: 'hidden', borderRadius: message.alignByCurrentUser ? '9px 0 0 9px' : '0 9px 9px 0' }}>
                  <Stack
                    alignItems='stretch'
                    justifyContent='center'
                    flexDirection='column'
                    sx={{
                      flexGrow: 1,
                      pr: 0.5,
                      height: '100%',
                      backgroundColor: 'var(--orange700)',
                      backgroundImage: `url(${WoodGrain})`,
                      border: 'none',
                      p: '5px',
                      cursor: 'default',
                    }}>
                    {typeof message?.icon === 'string' && message?.icon ? (
                      <img
                        style={{
                          height: '20px',
                          width: '20px',
                          filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
                        }}
                        src={message?.icon}
                        alt={`Action Icon`}
                      />
                    ) : (
                      <QuestionMarkIcon
                        fontSize='small'
                        sx={{
                          color: 'var(--gray000)',
                          height: '20px',
                          width: '20px',
                          filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
                        }}
                      />
                    )}
                  </Stack>
                </IconButton>
              )}
            </Stack>
          </Stack>
        </ChatBubbleStyle>
      </button>
    </Stack>
  );
};

export default FieldMessageContent;