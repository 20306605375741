import {
  Avatar,
  Badge,
} from '@mui/material';
import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import axiosCall from '@services/axios';
import eventBus from '@common/eventBus';
import { getUserConsent, getUserConsentIntro } from '@storage/userStorage';
import { useRootContext } from '@contexts/RootContext';
import { getCurrentParticipant } from '@common/errandUtils';
import { MorphType } from '@common/MorphType';
import Styles from '@styles/Errand.module.css';
import { updateChildErrands } from './MorphErrand';
import { useUserContext } from '@contexts/user';
import { DragIndicator, BorderColor, Delete } from '@mui/icons-material';
import Ballerina1 from '../Assets/Icons/ballerina1.svg';
import Ballerina2 from '../Assets/Icons/ballerina2.svg';
import Ballerina3 from '../Assets/Icons/ballerina3.svg';
import Ballerina4 from '../Assets/Icons/ballerina4.svg';
import Ballerina5 from '../Assets/Icons/ballerina5.svg';
import useAbortController from '@common/hooks/useAbortController';
import { handlePreviews } from '@common/loadPreview';

const BALLERINA_IMAGES = [Ballerina1, Ballerina2, Ballerina3, Ballerina4, Ballerina5];
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';

const PREVIEW_DEFAULT = '...';

/*
 *  This component renders a message in the conversation. This includes not only the chat bubble, but the Author and
 *
 *  This component has the following properties:
 *    - name - The name of the errand
 *    - preview - A preview of the most recently sent message in that errand's chat
 *    - active - A boolean value representing wether or not the errand is currently selected
 *    - isDefault - A boolean value telling representing if this is the default morgan chat,
 *                  This will hide the close butt on the errand in the sidebar, as well as rendering
 *                  The morgan logo for the avatar icon. This is only for the first and default errand
 */
const Errand: React.FC<PropsWithChildren<any>> = (props: any) => {
  const { t } = useTranslation();
  const { _id } = useUserContext();
  const { reset } = useAbortController();
  const { drawerRef, editErrandId, errandColorRef, morphedId, setChildErrands, setRootMorphType } = useRootContext();
  const currentParticipant = getCurrentParticipant(props.errand, _id);
  const messageHistoryAllowed = useMemo(() => currentParticipant && currentParticipant.messageHistoryAllowed, [currentParticipant]);
  const isChecked = useMemo(() => props.checkedErrands.findIndex((checkedErrand) => checkedErrand._id === props.errand._id) !== -1, [props.checkedErrands, props.errand]);
  const avatar = useMemo(() => props.errand.isDefault ? AngelAi : BALLERINA_IMAGES[(props.index as number) % BALLERINA_IMAGES.length], [props.errand, props.index]);
  
  const lastMessageData = props.errand.lastMessageData;

  /**
   * User side preview
   */
  const preview = useMemo(() => {
    // 1: Show preview based on message history allowed status
    if (messageHistoryAllowed === false) {
      // replace preview with stars
      return "******";
    }

    // 2: Show preview based on lastMessageData
     const showData = handlePreviews(lastMessageData) || PREVIEW_DEFAULT;

     if (showData !== PREVIEW_DEFAULT) {
      return showData;
     }

    // 3: chat preview field as a backup
    return props.errand.preview || PREVIEW_DEFAULT;
  }, [props.errand, _id, reset, messageHistoryAllowed, lastMessageData]);

  const handleCloseErrand = async (e) => {
    e.stopPropagation();
    if (!props.errand._id || props.errand._id === '') {
      console.error('Cannot close errand, invalid chat id');
      return;
    }

    // show consent notification if needed
    if (getUserConsentIntro() !== 'true') {
      eventBus.dispatch('showConsentIntro');
      return;
    }
    if (getUserConsent() !== 'true') {
      eventBus.dispatch('showConsentContent');
      return;
    }

    try {
      await axiosCall({
        url: `chat/${props.errand._id}`,
        method: 'put',
        data: {
          status: 'closed',
          position: 0,
        },
      });
    } catch (err) {
      console.error(err);
    }

    //Update childErrands so that morphErrand can be updated
    updateChildErrands(props.errand.parentId, setChildErrands, true, _id);
    setRootMorphType(MorphType.None);
  };
  const handleLeaveErrand = async (e) => {
    e.stopPropagation();
    if (!props.errand._id || props.errand._id === '') {
      console.error('Cannot leave errand, invalid chat id');
      return;
    }
    if (!currentParticipant || currentParticipant._id === '') {
      console.error('Cannot leave errand, invalid participant id');
      return;
    }
    try {
      await axiosCall({
        url: `chat/${props.errand._id}/participant/${currentParticipant._id}`,
        method: 'delete',
      });
    } catch (err) {
      console.error(err);
    }

    //Update childErrands so that morphErrand can be updated
    updateChildErrands(props.errand.parentId, setChildErrands, true, _id);
    setRootMorphType(MorphType.None);
  };
  const handleEditErrand = (e) => {
    e.stopPropagation();
    // hide the errands drawer on mobile devices. On desktop it is always visible
    drawerRef.current?.click();
    if (getUserConsentIntro() !== 'true') {
      eventBus.dispatch('showConsentIntro');
      return;
    }
    if (getUserConsent() !== 'true') {
      eventBus.dispatch('showConsentContent');
      return;
    } else {
      editErrandId.current = props.errand._id;
      errandColorRef.current = props.errand.color ?? null;
      setRootMorphType((prev): MorphType => {
        // Get the ID of the errand that is currently selected or use the id of the primary errand if split screen
        // so that we know which screen to have the footer be morphed on
        const errand = props.errands[props.selectedIndex[props.selectedIndex.length - 1]];
        if (!errand) return prev;
        const primaryErrand = props.errands[0];
        if (!primaryErrand) return errand._id;
        morphedId.current = props.selectedIndex.length === 2 ? primaryErrand._id : errand._id;
        if (prev === MorphType.ErrandEdit) {
          return MorphType.None;
        }
        return MorphType.ErrandEdit;
      });
    }
  };

  return (
    <section className={[
      Styles.errand,
      ...(currentParticipant && currentParticipant.messageHistoryAllowed ? [Styles.messageHistoryAllowed] : []),
      ...(currentParticipant && currentParticipant.primary ? [Styles.primary] : []),
      ...(props.errand.isDefault ? [Styles.isDefault] : []),
      ...(props.isSelected ? [Styles.isSelected] : []),
      ...(props.isDragging ? [Styles.isDragging] : []),
      ...(isChecked ? [Styles.isChecked] : []),
      ...(props.isMatch ? [Styles.isMatch] : []),
    ].join(' ')}>
      <div>
        <button onClick={() => props.toggleCheckedErrands(props.errand, props.index, currentParticipant)}>
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={
              props.notifications?.filter((x) => [x.chat?._id, x.messageId?.chat].includes(props.errand._id)).length
            }
            color="error"
            max={9}>
            <Avatar src={avatar} sx={{ width: '30px', height: '30px', background: props.errand.isDefault ? 'var(--shadow000)' : props.errand.color ? `${props.errand.color}` : 'var(--gray200)' }}></Avatar>
          </Badge>
          <div className={Styles.waveBg}><span /></div>
          <div className={Styles.waveMiddle}></div>
        </button>
        <button onClick={() => props.handleSelectErrand(props.index)}>
          <h2>{(props.errand.type === 'form' && props.errand.name.includes('TEMPLATEMGAI') ? 'Ai-Sign' : props.errand.displayName) || 'AngelAi'}</h2>
          <p className={Styles.errandPreview}>{preview}</p>
        </button>
      </div>
      <footer>
        <button {...props.dragHandleProps}><svg/><DragIndicator /> {t('tMove')}</button>
        <button onClick={handleEditErrand}><BorderColor /> {t('tEdit')}</button>
        <button onClick={currentParticipant && currentParticipant.primary ? handleCloseErrand : handleLeaveErrand}>
          <Delete />
          {currentParticipant && currentParticipant.primary ? t('closeButton') : t('leaveButton')}
        </button>
      </footer>
    </section>
  );
};

export default Errand;
