import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Fab, Stack } from '@mui/material';
import ScrollBox from './ScrollBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { FormBodyType, PaymentActionStateType } from '../Forms/commonForms';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { useMessageContext } from '@contexts/MessageContext';
import loadMessages from '@common/loadMessages';
import type { IErrand, IMessage, IUserChatAction, IUserData } from '@interfaces/Conversation';
import { useRootContext } from '@contexts/RootContext';
import { useSocketContext } from '@contexts/socket';
import { getMessageUserAction } from '@common/errandUtils';
import { shouldCurrUserReplyTo } from '@common/userMessagesUtils';
import { AccessType } from '@common/AccessType';
import { getAudienceString } from '@common/msgUtils';
import { useUserContext } from '@contexts/user';

type TScrollHandlerProps = {
  action: IUserChatAction;
  children: ReactNode;
  errand: IErrand;
  isPrivate: boolean;
  isSearching: boolean;
}

const ScrollHandler = ({
  action, children, errand, isPrivate, isSearching,  
}: TScrollHandlerProps) => {
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const { isMessagesConnected, messagesSocket } = useSocketContext();
  const messageContext = useMessageContext();
  const windowDimensions = useWindowDimensions();
  const { _id, isOperator } = useUserContext();
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [scrollTriggerCount, setScrollTriggerCount] = useState(0);
  const animationFrameRef = useRef(null);
  const scrollTopRef = useRef<number>(0);
  const scrollHeightRef = useRef<number>(9001);
  const clientHeightRef = useRef<number>(9001);
  const isScrollingUpRef = useRef<boolean>(false);
  const throttleRef = useRef<number>(0);
  const showScrollRef = useRef<boolean>(false);
  const fieldAttribute = action?.action?.fieldAttribute;

  const updateShowScrollToBottom = useCallback((e) => {
    setShowScrollToBottom(e);
    showScrollRef.current = e;
  }, []);

  const handleScroll = useCallback((e = null) => {
    const offset = 3;
    const now = Date.now();
    const container = messageContext.bodyRef.current;
    const scrollTop = container?.scrollTop || 0;
    const scrollHeight = container?.scrollHeight || 0;
    const clientHeight = container?.clientHeight || 0;
    const isScrollingUp = e?.deltaY < 0 || isSearching || (scrollTop < scrollTopRef.current);
    const isScrolledDown = scrollHeight <= scrollTop + clientHeight + offset + (isScrollingUp ? 2 : 0);
    const isThrottled = now < throttleRef.current;
    const isSameScrollHeight = scrollHeight === scrollHeightRef.current;
    const isSameClientHeight = clientHeight === clientHeightRef.current;

    if (e?.deltaY) {
      throttleRef.current = now + 500;

      if (isScrollingUp) {
        updateShowScrollToBottom(true);
        isScrollingUpRef.current = true;
      } else if (isScrolledDown) {
        updateShowScrollToBottom(false);
        isScrollingUpRef.current = false;
      }
      return;
    }
    if (isThrottled) return;
    throttleRef.current = isScrolledDown ? now + 200 : 0;
    scrollTopRef.current = scrollTop;
    scrollHeightRef.current = scrollHeight;
    clientHeightRef.current = clientHeight;

    if (isScrollingUp && isSameScrollHeight && isSameClientHeight) {
      updateShowScrollToBottom(true);
      isScrollingUpRef.current = true;
    } else if (isScrolledDown) {
      updateShowScrollToBottom(false);
      isScrollingUpRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching, scrollTriggerCount]);

  useEffect(() => {
    const container = messageContext.bodyRef?.current;

    container?.addEventListener('wheel', handleScroll);
    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('wheel', handleScroll);
      container?.removeEventListener('scroll', handleScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll]);

  useEffect(() => {
    const scrollStep = () => {
      if (isScrollingUpRef.current) {
        animationFrameRef.current = requestAnimationFrame(scrollStep);
        return;
      };
      const container = messageContext.bodyRef.current;
      container.scrollTop = container.scrollHeight;
      animationFrameRef.current = requestAnimationFrame(scrollStep);
    };

    animationFrameRef.current = requestAnimationFrame(scrollStep);

    return () => cancelAnimationFrame(animationFrameRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const container = messageContext.bodyRef.current;
    if (!showScrollToBottom && container?.scrollTop && scrollTriggerCount > 0) {
      container.scrollTop = container.scrollHeight - container.clientHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showScrollToBottom, scrollTriggerCount]);

  const combinedRef = (el) => {
    if (el) {
      messageContext.bodyRef.current = el;
      errandContext.bodyRef.current = el;
    }
  };

  useEffect(() => {
    if (isSearching) {
      updateShowScrollToBottom(true);
    }
  }, [isSearching]);

  useEffect(() => {
    if (!isMessagesConnected) return;

    // Register to receive real time messages on this chat
    const onChatMessageUpdate = async (payload) => {
      if (!errand || !errand._id || !errand.type) return;
      if (!payload || !payload.data || !payload.data.accessType) return;
      // don't process regulard messages in private chat
      if (isPrivate && payload.data.accessType !== AccessType.private) return;
      if (payload.data.chat !== errand._id) return;

      console.log(`Messages Socket - ScrollHandler - (chat-message-update)`, payload);

      const message: IMessage = await loadMessages(null, _id, errand._id, errand.type, true, isOperator, payload?.data);
      // guard statement reduces the need for the optional chaining, hence improving performance
      if (!message || !message._id) return;

      const userAction: IUserChatAction = await getMessageUserAction(message, isPrivate);
      rootContext.setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        let index = prev.findIndex((e) => e._id === errand._id);
        if (index === -1) return prev;
        let messagesArray = isPrivate ? prev[index].privateMessages || [] : prev[index].messages || [];
        // For a very odd reason, that I have yet to determine, when a user restores chats and the operator is viewing that chat
        // the messages that are returned to the prevMessagesState contain an undefined message in the final element.
        // This guard prevents the page from crashing on the operator side due to this strange behavior.
        let prevMessagesState = Array.isArray(messagesArray) ? messagesArray.filter((m) => m !== undefined) : [];
        // Save copy of messages state before inserting new message.
        const messageIndex = prevMessagesState.findIndex((m) => m._id === message._id);
        // validate
        const audienceString = getAudienceString(message.sender._id, message.intendedAudience, prev[index].participants, 'id', message.operatorView, false, message.userId, true);
        if (isPrivate) {
          const errandAudienceString = getAudienceString('', prev[index].recipients, prev[index].participants, 'id', message.operatorView, false, message.userId, true);
          if (audienceString !== errandAudienceString) return prev;
        } else if (messageIndex === -1 && message.accessType === AccessType.private) {
          // slice out previous private message preview from this chat
          const previousPrivateMessageIndex = prevMessagesState.findIndex((prevMessage: IMessage) => {
            if (prevMessage.accessType !== AccessType.private) return false;
            const prevAudienceString = getAudienceString(prevMessage.sender._id, prevMessage.intendedAudience, prev[index].participants, 'id', prevMessage.operatorView, false, prevMessage.userId, true);
            if (audienceString !== prevAudienceString) return false;
            return true;
          });
          if (previousPrivateMessageIndex !== -1) {
            prevMessagesState.splice(previousPrivateMessageIndex, 1);
          }
        }

        prev[index].previewAllowed = isOperator || prev[index].participants.find((p) => p.userData?._id === message.userId)?.messageHistoryAllowed;

        // Update existing messages if it's a(n) edit/delete message
        // bold: 6/12/2023 - notification field was overwritten by updated field and double tick was lost on some messages
        // index is last one IF not found, else the foundIndex
        const prevMessagesStateIndex = messageIndex === -1 ? prevMessagesState.length : messageIndex;
        const prevMessageStateNotifications = Array.isArray(prevMessagesState[messageIndex]?.notifications) ? prevMessagesState[messageIndex].notifications : Array.isArray(message.notifications) ? message.notifications : [];
        const prevMessagesStateObject = messageIndex === -1 ? message : {...prevMessagesState[messageIndex], ...message, notifications: prevMessageStateNotifications?.[Symbol.iterator] ? [ ...prevMessageStateNotifications ] : []};
        // don't process private message previews sent by the current user
        if (!(!isPrivate && message.accessType === AccessType.private && message.sentByCurrentUser)) {
          prevMessagesState[prevMessagesStateIndex] = prevMessagesStateObject;
        }
        // Old way of sorting by date. It was deprecated as it does not account for good precision. Sometimes the messages were out of order because the precision value was lost and resulting comparison was equal.
        // const sortedMessages = prevMessagesState.sort((x, y) => Date.parse((new Date(x?.createdAt)).toString()) - Date.parse((new Date(y?.createdAt)).toString()));
        const sortedMessages = prevMessagesState.sort((x, y) => new Date(x?.createdAt)?.getTime() - new Date(y?.createdAt)?.getTime());
        const truncatedMessages = showScrollRef.current || prevMessagesState[0]?.searchWords ? sortedMessages : sortedMessages.slice(-25);
        if (isPrivate) {
          prev[index].privateMessages = truncatedMessages;
        } else {
          prev[index].messages = truncatedMessages;
        }
        if (truncatedMessages.length !== sortedMessages.length) {
          messageContext.hasMoreMessages.current = true;
        }

        const shouldReply = shouldCurrUserReplyTo(message, _id);
        if (userAction && !message.operatorView && shouldReply) {
          prev[index].icon = message.icon;
          prev[index].placeholder = message.action.description;
          prev[index].action = userAction;
          const participantIndex = prev[index].participants.findIndex((p) => p.userData?._id === message.userId);
          if (participantIndex !== -1) {
            prev[index].participants[participantIndex].userActions = [userAction];
          }
          prev[index].action.userActionId = userAction._id;
          prev[index].action.active = true;
        }

        prev[index].lastMessageData = message;
        setScrollTriggerCount((prev) => prev + 1);
        return [...prev];
      });
    };

    console.log('Messages Socket - ScrollHandler - (on)');
    messagesSocket.current?.on('chat-message-update', onChatMessageUpdate);
    return () => {
      console.log('Messages Socket - ScrollHandler - (off)');
      messagesSocket.current?.off('chat-message-update', onChatMessageUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errand._id]);

  const paddingBottom = errandContext?.morphType 
  ? errandContext?.morphType === MorphType.Errand 
    ? '61px'
    : errandContext?.morphType === MorphType.Contacts
    ? '37px'
    : errandContext?.morphType === MorphType.LoanProductPriceTable
    ? '260px'
    : errandContext?.morphType === MorphType.Attachment
    ? '98px'              
    : errandContext?.morphType === MorphType.MessageOptions
    ? '98px'
    : errandContext?.morphType === MorphType.UserPromptsMenu
      || errandContext?.morphType === MorphType.VideoListMenu
      || errandContext?.morphType === MorphType.CreditRepairDisputeAccountType
    ? '130px'
    // Only add padding bottom when payment is not in the preview state (when only the floating card appears)
    : (errandContext?.morphType === MorphType.Payment && errandContext?.paymentActionState !== PaymentActionStateType.Preview)
    ? '200px'
    : errandContext?.morphType === MorphType.PrivateChat && !isPrivate
    ? '250px'
    : fieldAttribute?.description === 'DROPDOWN'
    ? '37px' 
    : '23px'
  : '23px';

  return (
    <>
      <ScrollBox
        flexDirection="column"
        sx={{
          height: 'fit-content',
          minHeight: '100%',
          //66 is the height of the footer by default and 56 is the height of the conv title. Greater heights are used to account for when the footer is morphed or
          // for smaller window sizes like mobile. The minHeight will always take priority so it will never be too small. We just need to ensure it doesn't extend past the bottom of
          // the screen/conv body
          maxHeight: `calc(100vh - 56px - ${paddingBottom} - ${errandContext.footerRef.current?.innerHeight ?? '66px'} - ${windowDimensions.isDesktop ? isOperator ? '500px' : '56px' : '166px'})`,
          width: 'calc(100% - 1px)',
          padding: windowDimensions.isDesktop ? '0 32px 23px 39px' : '0 22px 23px 29px',
          margin: '0',
          overflowY: errandContext.formBody === FormBodyType.CreateSignatureMobile ? 'hidden' : 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
          paddingBottom: paddingBottom,
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px var(--shadow000)',
            webkitBoxShadow: 'inset 0 0 6px var(--shadow000)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'var(--shadow110)',
            outline: '1px solid slategrey',
            borderRadius: '0.2em',
          },
          '&>div': {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
          },
        }}
        ref={combinedRef}
      >
        {children}
      </ScrollBox>
      {showScrollToBottom && (
        <Stack justifyContent="center" alignItems="flex-end">
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {
              updateShowScrollToBottom(false);
              isScrollingUpRef.current = false;
            }}
            sx={{
              backgroundColor: 'var(--orange000)!important',
              position: 'absolute',
              mr: '16px',
              borderRadius: '100%',
              width: '40px',
              height: '40px',
              boxShadow: 'none',
              bottom: '8px',
              zIndex: '1000',
            }}
          >
            <KeyboardArrowDownIcon sx={{ color: 'var(--gray000)', width: '1.55em', height: '1.55em' }} />
          </Fab>
        </Stack>
      )}
    </>
  );
};

export default ScrollHandler;
