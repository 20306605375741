import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Styles from '@styles/MessageContent/CreditRepairWelcomeMessageContent.module.css'
import { useTranslation } from 'react-i18next';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { useUserContext } from '@contexts/user';
import Sanitized from '@components/Sanitized';
import { AccessType } from '@common/AccessType';
import { sendWorkflow } from '@common/WorkflowsUtils';
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAiDownloadAppBanner.png';

const CreditRepairWelcomeMessageContent = ({
  message
}) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const { isOperator } = useUserContext();
  const [footerIsMorphed, setFooterIsMorphed] = useState(true);
  const messageRef = useRef<HTMLDivElement | null>(null);
  const allowMessageClick =
    [MorphType.CreditRepairWelcome, MorphType.RefinanceCalculatorWelcome].includes(errandContext.morphType) &&
    !footerIsMorphed;

  useEffect(() => {
    if ([MorphType.CreditRepairWelcome, MorphType.RefinanceCalculatorWelcome].includes(errandContext.morphType)){
      setFooterIsMorphed(true);
    }else{
      setFooterIsMorphed(false);
    }
  }, [errandContext.morphType])

  // This useEffect handles workflow links in the message to trigger the proper workflow in the chat
  useEffect(() => {
    if (messageRef.current === null) {
      return;
    }

    const sendWorkflowWrapper = (calculatorName: string, checkDup: boolean = false) =>
      sendWorkflow(
        '',
        calculatorName,
        message?.chat,
        message?.intendedAudience,
        AccessType.public,
        message?.userId,
        message?.operatorView,
        checkDup
      );

    const handleLink = (e) => {
      if (e.target.tagName === 'A') {
        e.preventDefault();
        e.stopPropagation();
        let href = e.target.getAttribute('href');
        if (href.toLowerCase().includes('calculator')) {
          const lowerCasedHref = href.toLowerCase();
          if (lowerCasedHref.includes('affordability')) {
            sendWorkflowWrapper('Affordability Calculator');
          } else if (lowerCasedHref.includes('appraisal')) {
            sendWorkflowWrapper('Appraisal Calculator');
          } else if (lowerCasedHref.includes('fixedrate')) {
            sendWorkflowWrapper('Fixed Rate Calculator');
          } else if (lowerCasedHref.includes('refinance')) {
            sendWorkflowWrapper('Refinance Calculator');
          } else if (lowerCasedHref.includes('rentvsbuy')) {
            sendWorkflowWrapper('Rent Vs Buy Calculator');
          } else if (lowerCasedHref.includes('zestimate')) {
            sendWorkflowWrapper('Zestimate');
          }
        } else if (href === '#creditBoost') {
          sendWorkflowWrapper('Credit Boost', true);
        }
      }
    };

    const msg = messageRef.current;
    msg.addEventListener('click', handleLink);
    return () => {
      msg.removeEventListener('click', handleLink);
    };
  }, [message?.chat, message?.intendedAudience, message?.userId, message?.operatorView]);

  if (!message) {
    console.info('Message is undefined');
    return <></>;
  }

  let messageTitle = message?.title;
  let messageSubtitle = message?.subtitle;
  let steps = message?.steps;
  let footer = message?.footer;

  // Resets the morph type to the appropriate value when the message is clicked on. The morph type may be different than
  // the two types below if the user clicked cancel or has received other messages/actions since this message was sent.
  const handleClick = () => {
    if (message.messageType === 'RefinanceCalculatorWelcome') {
      errandContext.setMorphType(MorphType.RefinanceCalculatorWelcome);
      return;
    } else if (message.messageType === 'CreditRepairWelcome') {
      errandContext.setMorphType(MorphType.CreditRepairWelcome);
    }
  };

  return (
    <div className={[
      Styles.welcomeContainer,
      ...(allowMessageClick ? [Styles.pointer] : []),
      ...(isOperator ? [Styles.operatorView] : [])
      ].join(' ')} onClick={handleClick} ref={messageRef}>
      <div className={Styles.topCircle}>
        <div className={Styles.innerCircle}/>
        <img src={AngelAi} alt="AngelAi logo" className={Styles.image} />
      </div>
      <div className={Styles.top}>{messageTitle}</div>
      <div className={Styles.bottom}>
        <div className={Styles.bottomTop}>
          <div className={Styles.bottomTopLeft}>{messageSubtitle}</div>
          <div className={Styles.bottomTopMiddle} />
          <div className={Styles.bottomTopRight} />
        </div>
        <div className={Styles.bottomBottom}>
          {Array.isArray(steps) &&
            steps.map((step, idx) => (
              <div key={idx} className={idx === 0 ? Styles.step1 : Styles.stepAfter}>
                <div className={Styles.stepCircle}>
                  <div className={Styles.circleNumber}>
                    <p>{idx + 1}</p>
                  </div>
                </div>
                <div>
                  <Sanitized html={step} />
                </div>
              </div>
            ))}
        </div>
        {footer && <div className={Styles.footer}>{footer}</div>}
      </div>
    </div>
  );
};

export default CreditRepairWelcomeMessageContent;
