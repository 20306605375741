import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  TextField,
  InputAdornment
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { MorganTheme, useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import SlotMachineHeaderBar from './SlotMachineHeaderBar';
import JsonPrizes from '../Assets/prizes.json'
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useInitialMount from '@common/hooks/useInitialMount'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

// Load the prizes from json and convert to a map, filling rate with 0s
// This is so that only prizes defined in the json can be added
const existingPrizesMap = JsonPrizes.reduce(function (map, obj) {
  map[obj.name] = 0
  return map;
}, {});

const SlotMachinePrizes = (props) => {
  const { t } = useTranslation();
  const theme: MorganTheme = useTheme();
  const [loading, setLoading] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [fetchedPrizeRate, setFetchedPrizeRate] = useState([]);
  const [totalRate, setTotalRate] = useState(100);
  const [prizes, setPrizes] = useState(existingPrizesMap);
  const minRate = 0;
  const maxRate = 100;

  const loadPrizeRateData = async () => {
    setLoading(true);
    const lenderName = theme.system.name;
    try {
      const result = await axiosCall({ url: `system/data?lender=${lenderName}&fields=message,status,lender,translationEnabled,contacts,prizeRates` });
      if (result) {
        console.log('System Settings Fetched. Results Below...', result);
        setFetchedPrizeRate(result?.prizeRates);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const checkNoResults = (rates) => {
    if (Object.keys(rates).length === 0) {
      setNoResults(true)
    } else {
      return;
    }
  }

  const numOrZero = (value: number) => {
    if (Number.isFinite(value)) { return value }
    else { return minRate }
  }

  const handleValueChange = event => {
    if (!Object.keys(prizes).includes(event.target.name)) { return };

    let value = parseFloat(event.target.value);
    console.log("Value: ", value)

    if (Number.isFinite(value)) {
      // Keep value in range
      if (value < minRate) { value = minRate }
      else if (value > maxRate) { value = maxRate }
    }

    // Update prizes map
    let newObj = {};
    newObj[event.target.name] = value;
    const newPrizes = { ...prizes, ...newObj };
    // Sum the rates
    let localTotalRate = Object.keys(newPrizes).reduce(function (sum, current) {
      return sum + numOrZero(newPrizes[current]);
    }, 0);
    setTotalRate(localTotalRate);
    setPrizes(newPrizes);
    checkNoResults(newPrizes);
  }

  const handleSave = async () => {
    // First we need to scale the rates to ensure the total is 100%
    // Create a copy of prizes and scale each rate
    let newPrizes = { ...prizes };
    if (totalRate !== 100) {
      const scale = 100 / totalRate;

      Object.keys(newPrizes).forEach(function (name) {
        // Scale each rate proportionally and round to 2 decimal places
        const newRate = Math.round((numOrZero(newPrizes[name]) * scale) * 100) / 100;
        newPrizes[name] = newRate;
      })
      setTotalRate(100);
      setPrizes(newPrizes);
      checkNoResults(newPrizes)
    }

    // Convert prizes (map) to the way prizeRates is stored in db (array)
    // {'Cap': 0.3, 'T-Shirt': 0.4} --> [{'name': 'Cap', 'rate': 0.3}, {'name': 'T-Shirt', 'rate': 0.4}]  
    const newPrizeRatesArray = Object.keys(newPrizes).map(name => {
      return { 'name': name, 'rate': newPrizes[name] }
    })

    //Send the new rates to db
    let result = null;
    try {
      result = await axiosCall({
        url: `system`,
        method: 'PUT',
        data: {
          lender: theme.system.name,
          prizeRates: newPrizeRatesArray,
        },
      });

      setFetchedPrizeRate(result?.prizeRates);
    } catch (error) {
      console.log(error);
    }
  }

  // Run once on load
  const loadPrizeRatesOnMount = useCallback(() => {
    loadPrizeRateData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInitialMount(loadPrizeRatesOnMount);

  // Run when data is fetched from db
  const updatePrizeRate = (fetchedPrizeRate) => {
    if (fetchedPrizeRate) {
      // Convert the fetched prizeRates array to a map
      // [{'name': 'Cap', 'rate': 0.3}, {'name': 'T-Shirt', 'rate': 0.4}] --> {'Cap': 0.3, 'T-Shirt': 0.4}
      const fetchedPrizeMap = fetchedPrizeRate.reduce(function (map, obj) {
        if (obj.rate !== null) { map[obj.name] = obj.rate }
        else { map[obj.name] = 0 }

        return map;
      }, {});

      const updatedRates = { ...prizes, ...fetchedPrizeMap };
      setPrizes(updatedRates);
      checkNoResults(updatedRates);
      console.log('Loaded Rates: ', updatedRates)
      setLoading(false);
    }
  }

  useEffect(() => {
    updatePrizeRate(fetchedPrizeRate);
  }, [fetchedPrizeRate])

  return (
    <Box display="flex" flexDirection="column">
      <SlotMachineHeaderBar handleSave={handleSave} onRefresh={loadPrizeRateData} incorrectSum={totalRate !== 100}></SlotMachineHeaderBar>
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="100px">{t("nameCol")}</StyledTableCell>
                    <StyledTableCell width="200px">{t("winRate")}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(prizes)?.map(name => {
                    const rate = prizes[name];
                    return (
                      <TableRow
                        sx={{ backgroundColor: 'var(--gray000)' }}
                        hover
                        key={name}>
                        <StyledTableCell>{name}</StyledTableCell>
                        <StyledTableCell>
                          <TextField type="number" inputProps={{ min: minRate, max: maxRate }} size="small"
                            InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                            name={name}
                            value={rate.toString()}
                            error={!Number.isFinite(rate)}
                            onChange={handleValueChange}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                </TableFooter>
              </Table>
            )}
          </>
        )}
      </TableContainer>
    </Box>
  );
}

export default SlotMachinePrizes;