import i18n from 'i18next';
import axiosCall from '../Services/axios';
import getProfileBubbleMessage from '@common/getProfileBubbleMessage';
import { uiTranslationController } from '@common/common';

/*
========================================================================================================================
Private functions
========================================================================================================================
*/

/**
 * This function loads the preview for a single errand, handles translations and masking at the core level, and returns the preview.
 */
const loadPreview = async (errand, userId, abortController = {}) => {
  try {
    const preview = await axiosCall(
      {
        url: `chat/loadChatPreview`,
        method: 'POST',
        data: {
          errand: errand,
          userId: userId,
        },
      },
      abortController
    );

    return await handleTranslations(preview);
  } catch (e) {
    console.error('Error retrieving chat preview');
    console.error(e);
    return '';
  }
};

/**
 * This function calls the bulk retrieval endpoint to get all message preview updates for the supplied list of errands.
 * This is helpful in prepareErrands for one call to load all previews.
 */
const loadBulkPreviews = async (errands, userId, abortController = {}) => {
  try {
    const previews = await axiosCall(
      {
        url: `chat/loadBulkPreviews`,
        method: 'POST',
        data: {
          errands: errands,
          userId: userId,
        },
      },
      abortController
    );

    for (const elm of previews) {
      elm.preview = await handleTranslations(elm);
    }

    return previews;
  } catch (e) {
    console.error('Error retrieving chat previews');
    console.error(e);
    return errands.map((e) => {
      return { _id: e._id, preview: '' };
    });
  }
};

/**
 * This function handles the masking and management of message previews that need special modification.
 * @param {*} lastMessageData
 * @returns
 */
const handlePreviews = (lastMessageData) => {
  const HASH_STR = '********';

  if (lastMessageData === undefined || lastMessageData === null) {
    return '';
  }

  const { messageType, messageStatus, message, userAction, action, accessType } = lastMessageData;
  const { dispMessage } = uiTranslationController(lastMessageData);

  // if somehow chat lastMessage field accidentally set system/internal message hide it
  if (accessType === 'internal' || accessType === 'system') {
    return '';
  }

  let constructedMessage = dispMessage || message || '';

  if (messageStatus === 'edited') {
    // split by edited message identifer
    let temp = constructedMessage.split(`<i class="messageArrow"/>`);

    // get latest element and do replace
    constructedMessage = temp[temp.length - 1].replace(/(<([^>]+)>)/gi, '');
  }

  switch (messageType) {
    case 'Field':
      /**
       * There are cases where the lastMessageDataData would not exist on the database.
       * One such instance is when the user is invited to Morgan by an operator.
       * Since a new chat is being created, when the user joins there are no
       * preexisting messages, thus making the optional operator ? a valid approach
       * to resolving the undefined userAction error occuring in the next line.
       */
      const fieldsToHide = ['brwrLast4Ssn'];

      // Case sensitive
      const fieldAttributesToHide = ['PASSWORD', 'SSN Last 4', 'OTP'];

      // Bold: currently message object has 2 different structure
      const fieldAttribute = userAction?.action?.fieldAttribute || action?.fieldAttribute;
      const fieldName = userAction?.action?.fieldName || action?.fieldName;

      const description = fieldAttribute?.description;

      if (
        // first condition short circuits on matching field attribute desc
        // second condition specifically for matching certain fields if
        // field attribute desc not in fieldAttributesToHide arr
        fieldAttributesToHide.includes(description) ||
        fieldsToHide.includes(fieldName)
      ) {
        // hide hash if field attribute or field name exist in the arrs
        return fieldAttribute?.mask?.repeat(8) || HASH_STR;
      }

      break;
    case 'Song':
      constructedMessage = `Song of the Day: https://www.youtube.com/?v=${constructedMessage}`;
      break;
    case 'LoanConditions':
      return (
        constructedMessage.includes('&lt;&gt;') ? constructedMessage.split('&lt;&gt;') : constructedMessage.split('<>')
      )[0];
    case 'Document':
      return i18n.t('documentUploaded');
    case 'CustomLink':
      return i18n.t('shareCustomLink');
    case 'CreditRepairWelcome':
      return i18n.t('creditRepairWelcomeExcited');
    case 'RefinanceCalculatorWelcome':
      return i18n.t('refinanceCalculatorWelcomeMessage');
    case 'CalculatorsWelcome':
      return i18n.t('calculatorsWelcomePreview');
    case 'Appointment':
      return i18n.t('appointmentConfirmed');
    case 'PropertyListing':
      return i18n.t('propertyInMyDatabase');
    case 'Errand':
      return i18n.t('closedErrand');
    case 'PausedWorkflow':
      return i18n.t('pausedWorkflow');
    case 'ProfileBubble':
      const profileBubbleMessage = getProfileBubbleMessage(constructedMessage);
      if (profileBubbleMessage && profileBubbleMessage.title) {
        return profileBubbleMessage.title;
      } else {
        return '';
      }
    case 'ImagingUploadStatus':
      return `${i18n.t('documentUpload')}`;
    case 'Referrer':
      const lastname = constructedMessage.split('<>')[1] || '';
      return i18n
        .t('referrerGreeting')
        .replace(/{Referee}/, lastname.charAt(0).toUpperCase() + lastname.slice(1).toLowerCase());
    case 'SignatureConfirmation':
      const cleanedString = constructedMessage?.slice(1, -1) || ':,,,,:';
      const cleanedStringArray = cleanedString.split(',');

      const formName = cleanedStringArray[0].replaceAll('"', '').split(':')[1];
      //cleanedStringArray[3] === one 'borrower' for the influencer agreements. [4] is the second borrower for bcerta
      const borrowerName =
        cleanedStringArray[4]?.replaceAll('"', '').split(':')[1] ||
        cleanedStringArray[3]?.replaceAll('"', '').split(':')[1];
      return `${formName} ${i18n.t('signatureConfirmation')} ${borrowerName}.`;
    case 'LoanProductComparison':
      return i18n.t('loanProducts');
    default:
      break;
  }

  constructedMessage = constructedMessage?.replace(/(<([^>]+)>)/gi, '');

  return constructedMessage;
};

const handleTranslations = async (messageEntry, translateMessage = false) => {
  const { message, preview, messageType } = messageEntry;
  const constructedMessage = translateMessage ? message : preview;

  switch (messageType) {
    case 'Document':
      return i18n.t('documentUploaded');
    case 'CustomLink':
      return i18n.t('shareCustomLink');
    case 'CreditRepairWelcome':
      return i18n.t('creditRepairWelcomeExcited');
    case 'RefinanceCalculatorWelcome':
      return i18n.t('refinanceCalculatorWelcomeMessage');
    case 'Appointment':
      return i18n.t('appointmentConfirmed');
    case 'PropertyListing':
      return i18n.t('propertyInMyDatabase');
    case 'Errand':
      return i18n.t('closedErrand');
    case 'PausedWorkflow':
      return i18n.t('pausedWorkflow');
    case 'Referrer':
      const lastname = constructedMessage.split('<>')[1] || '';
      return i18n
        .t('referrerGreeting')
        .replace(/{Referee}/, lastname.charAt(0).toUpperCase() + lastname.slice(1).toLowerCase());
    case 'SignatureConfirmation':
      const cleanedString = constructedMessage?.slice(1, -1) || ':,,,,:';
      const cleanedStringArray = cleanedString.split(',');

      const formName = cleanedStringArray[0].replaceAll('"', '').split(':')[1];
      //cleanedStringArray[3] === one 'borrower' for the influencer agreements. [4] is the second borrower for bcerta
      const borrowerName =
        cleanedStringArray[4]?.replaceAll('"', '').split(':')[1] ||
        cleanedStringArray[3]?.replaceAll('"', '').split(':')[1];
      return `${formName} ${i18n.t('signatureConfirmation')} ${borrowerName}.`;
    case 'ImagingUploadStatus':
      return `${i18n.t('documentUpload')}`;
    case 'LoanConditions':
      return (
        constructedMessage.includes('&lt;&gt;') ? constructedMessage.split('&lt;&gt;') : constructedMessage.split('<>')
      )[0];
    case 'ProfileBubble':
      const profileBubbleMessage = getProfileBubbleMessage(constructedMessage);
      if (profileBubbleMessage && profileBubbleMessage.title) {
        return profileBubbleMessage.title;
      } else {
        return '';
      }
    case 'LoanProductComparison':
      return i18n.t('loanProducts');
    default:
      break;
  }
  return constructedMessage;
};

export { loadPreview, loadBulkPreviews, handleTranslations, handlePreviews };
