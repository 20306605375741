import axiosCall from "@services/axios";
import { TWorkflowDataFetched_UPmenu } from "./types";
import axios from "axios";

const fetchWorkflows = async (abortController): Promise<TWorkflowDataFetched_UPmenu> => {
  // Retrieve at most 12 workflows, only active, visible, and userVisible
  try {
    const config = abortController;
    let workflows = await axiosCall(
      {
        url: `workflow?limit=20&fields=_id,displayName,description,name,workflowIcon&active=true&visibility.user=true`,
      },
      config
    );
    return workflows;
  } catch (e) {
    return e;
  }
};

const fetchSampleTextData = async (wfIdName: string) => {
  return axios.get(
    `${process.env.REACT_APP_MORGAN_CELLS}/intents/celligence/${wfIdName}/randomSample?matchFirst=true`
  )
}

export { fetchWorkflows, fetchSampleTextData };