import React, { PropsWithChildren, useCallback, useRef, useState, useEffect } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import axiosCall from '@services/axios';
import { useRootContext } from '@contexts/RootContext';
import { useSwipeable } from 'react-swipeable';

import Errand from '@components/Errand';
import ErrandsTitle from '@components/ErrandsTitle';
import ErrandsFooter from '@components/ErrandsFooter';
import { MorphType } from '@common/MorphType';
import eventBus from '@common/eventBus';
import { getUserConsent, getUserConsentIntro } from '@storage/userStorage';
import { IErrand } from '@interfaces/Conversation';
import ErrorBoundary from '@components/ErrorBoundary';
import UserPromptList from './UserPromptList';
import { StrictModeDroppable } from './EditWorkflowDialog';
import Styles from '@styles/ErrandsContainer.module.css';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { useUserContext } from '@contexts/user';
import { updateChildErrands } from '@components/MorphErrand';
import { useTranslation } from 'react-i18next';
import { ValidatorFunctions } from '@common/Validators';

/*
 *  This component renders the errands box on the right side of the page
 *
 *  This component has the following properties:
 *    - author - The name of the person that composed the message
 *    - time - Time the message was sent/recieved
 *    - message - The actual content of the message
 *    - isOperator - A boolean value telling us if the author was an operator or not ,
 *     this will decide if the message is rendered on the left or right of the screen and
 *     in the appropriate
 */

const ErrandsContainer: React.FC<PropsWithChildren<any>> = (props) => {
  const { t } = useTranslation();
  const { _id, activeErrandCount } = useUserContext();
  const rootContext = useRootContext();
  const endOfErrands = useRef(null);
  const startOfErrands = useRef(null);
  const [, setDraggedErrand] = useState(null);
  const { isDesktop } = useWindowDimensions();

  const [showUserPromptList, setShowUserPromptList] = useState(false);
  const [checkedErrands, setCheckedErrands] = useState([]);

  const closeCheckedErrands = useCallback(async () => {
    // show consent notification if needed
    if (getUserConsentIntro() !== 'true') {
      eventBus.dispatch('showConsentIntro');
      return;
    }
    if (getUserConsent() !== 'true') {
      eventBus.dispatch('showConsentContent');
      return;
    }
    if (checkedErrands.length === 0) return;
    const promises = [];
    for await(const checkedErrand of checkedErrands) {
      try {
        if (checkedErrand.currentParticipantIsPrimary) {
          await axiosCall({ url: `chat/${checkedErrand._id}`, method: 'put', data: { status: 'closed', position: 0 } });
        } else {
          await axiosCall({ url: `chat/${checkedErrand._id}/participant/${checkedErrand.currentParticipantId}`, method: 'delete' });
        }
      } catch (error) {
        console.error(`ErrandsContainer.closedCheckedErrands caught`, error.message);
      }
    }
    await updateChildErrands(props.errands[0].parentId, rootContext.setChildErrands, true, _id);

    setCheckedErrands([]);
  }, [checkedErrands]);

  const toggleCheckedErrands = useCallback((errand, index, currentParticipant) => {
    if (!errand._id) return;
    if (errand.isDefault) return handleSelectErrand(index);
    // show consent notification if needed
    if (getUserConsentIntro() !== 'true') {
      eventBus.dispatch('showConsentIntro');
      return;
    }
    if (getUserConsent() !== 'true') {
      eventBus.dispatch('showConsentContent');
      return;
    }
    setCheckedErrands((prev) => {
      const index = prev.findIndex((e) => e._id === errand._id);
      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return [...prev, { ...errand, currentParticipantIsPrimary: currentParticipant.primary, currentParticipantId: currentParticipant._id }];
    });
  }, []);

  const handlers = useSwipeable({
    onSwipedRight: () => {
      // hide the errands drawer on mobile devices. On desktop it is always visible
      rootContext.drawerRef.current?.click();
    }
  })

  const handleOnDragEnd = async (result, errandsList: IErrand[]) => {
    try {
      if (!result.destination) {
        return;
      }

      const { source, destination } = result;

      if (source.index === destination.index) {
        return;
      }

      const movedErrand = errandsList[source.index];

      if (destination.index > source.index) {
        // errand moving up ---> shift errands down
        for (let i = source.index; i < destination.index; i++) {
          errandsList[i] = errandsList[i + 1];
        }
      } else {
        // errand moving down ---> shift errands up
        for (let i = source.index; i > destination.index; i--) {
          errandsList[i] = errandsList[i - 1];
        }
      }

      errandsList[destination.index] = movedErrand;

      // update positions according to current view
      errandsList.forEach((errand, i) => {
        errand.position = i;
      });

      props.setErrands(errandsList);

      if (props.selectedIndex.length === 1) {
        // single screen view
        if (props.selectedIndex[0] === source.index) {
          props.setSelectedIndex([destination.index]);
        } else if (props.selectedIndex[0] === destination.index) {
          props.setSelectedIndex([source.index]);
        } else {
          console.info('selectedIndex is not affected by the drag and drop');
        }
        if (
          (props.selectedIndex[0] < source.index && destination.index <= props.selectedIndex[0]) ||
          (props.selectedIndex[0] > source.index && destination.index >= props.selectedIndex[0])
        ) {
          props.setSelectedIndex([props.selectedIndex[0] + (destination.index > source.index ? -1 : 1)]);
        }
      } else if (props.selectedIndex.length === 2) {
        // split screen view
        const [firstIndex, secondIndex] = props.selectedIndex;
        let updatedSelectedIndexes = [firstIndex];

        if (secondIndex === source.index) {
          updatedSelectedIndexes.push(destination.index);
        } else if (secondIndex === destination.index) {
          updatedSelectedIndexes.push(source.index);
        } else {
          updatedSelectedIndexes.push(secondIndex);
        }

        if (
          (secondIndex < source.index && destination.index <= secondIndex) ||
          (secondIndex > source.index && destination.index >= secondIndex)
        ) {
          updatedSelectedIndexes = [firstIndex, secondIndex + (destination.index > source.index ? -1 : 1)];
        }

        props.setSelectedIndex(updatedSelectedIndexes);
      } else {
        console.error('selectedIndex has more than 2 elements!');
      }

      await rootContext.syncErrandsSequence(errandsList.map((x) => x._id));
    } catch (err) {
      console.error('Something is wrong with handleOnDragEnd', err);
    }
  };

  const handleDragStart = (start) => {
    const { draggableId } = start
    setDraggedErrand(draggableId)
  }

  const handleSelectErrand = (index) => {
    if (index < 0) {
      console.info('Unable to select errand, index is invalid');
      return;
    }

    // show consent notification if needed
    if (getUserConsentIntro() !== 'true') {
      return eventBus.dispatch('showConsentIntro');
    }
    if (getUserConsent() !== 'true') {
      return eventBus.dispatch('showConsentContent');
    }

    // hide the errands drawer on mobile devices. On desktop it is always visible
    rootContext.drawerRef.current?.click();

    // close new errand morphed footer if it is open
    rootContext.setRootMorphType(MorphType.None);
    rootContext.setMessagesAreLoading(true);
    props.setSelectedIndex((prev) => {
      if (!Array.isArray(prev)) return [index];
      prev[prev.length - 1] = index;
      return [...prev];
    });
  };

  const checkIfIdMatches = (_id) => {
    if (props.errands[props.selectedIndex[0]] === undefined) {
      return false;
    }

    return props.errands[props.selectedIndex[0]]._id === _id;
  }

  const droppableRef = useRef(null);

  const handleScroll = () => {
    const parameters = droppableRef.current;

    if (ValidatorFunctions.isUndefinedOrNull(parameters)) {
      return;
    }

    const scrollTop = parameters.scrollTop;
    const scrollHeight = parameters.scrollHeight;
    const { height } = parameters.getBoundingClientRect();

    const viewRatio = (scrollTop + height) / scrollHeight;

    if (viewRatio > 0.9) {
      rootContext.loadMoreErrands();
    }
  };

  // Following useEffect used to check if fetched Data ocuppied area is less than scroll size
  useEffect(() => {
    handleScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errands]);

  return (
    <ErrorBoundary debug={`ErrandsContainer: ${JSON.stringify(props)}`}>
      <aside className={[Styles.aside, ...(isDesktop ? [Styles.isDesktop] : [])].join(' ')} {...handlers}>
        <UserPromptList
          setShowUserPromptList={setShowUserPromptList}
          showUserPromptList={showUserPromptList}
        />
        <ErrandsTitle />
        <DragDropContext onDragStart={handleDragStart} onDragEnd={(result) => handleOnDragEnd(result, props.errands)}>
          <StrictModeDroppable droppableId="errands-list">
            {(provided) => (
              <ul className={Styles.ul}
                ref={(node) => {
                  provided.innerRef(node);
                  droppableRef.current = node; // Set the droppableRef to the current node
                }}
                {...provided.droppableProps}
                onScroll={handleScroll}
              >
                <li>
                  <button
                    onClick={() => endOfErrands?.current?.focus()}
                    ref={startOfErrands}
                    id="startOfErrands"
                    aria-label="Skip errands list"
                    style={{
                      backgroundColor: 'var(--gray000)',
                      border: 'none',
                      height: '1px',
                      margin: 0,
                      outlineColor: 'var(--peach900)',
                      padding: 0,
                      width: '100%',
                      '&:hover': {
                        backgroundColor: 'var(--peach900)',
                      },
                    } as React.CSSProperties}
                  />
                </li>
                {props.errands.map(
                  (
                    errand: IErrand,
                    index: number
                  ) => {
                    if (errand.isDefault) {
                      return (
                        <li
                          className={[
                            Styles.li
                          ].join(' ')}
                        >
                          <ErrorBoundary key={index} debug={`Errand${errand.isDefault ? '.isDefault' : ''}${props.selectedIndex.includes(index) ? '.isSelected' : ''}: ${JSON.stringify(props)}`}>
                            <Errand
                              {...props}
                              isSelected={props.selectedIndex.includes(index)}
                              checkedErrands={checkedErrands}
                              toggleCheckedErrands={toggleCheckedErrands}
                              isMatch={checkIfIdMatches(errand._id)}
                              errand={errand}
                              dragHandleProps={{}}
                              isDragging={false}
                              handleSelectErrand={handleSelectErrand}
                              index={index}
                            />
                          </ErrorBoundary>
                        </li>
                      );
                    }
                    return (
                      <Draggable key={errand._id} draggableId={errand._id} index={index}>
                        {(provided, snapshot) => (
                          <li
                            className={[
                              Styles.li
                            ].join(' ')}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            {provided.placeholder}
                            <ErrorBoundary key={index} debug={`Errand${errand.isDefault ? '.isDefault' : ''}${props.selectedIndex.includes(index) ? '.isSelected' : ''}: ${JSON.stringify(props)}`}>
                              <Errand
                                {...props}
                                isSelected={props.selectedIndex.includes(index)}
                                checkedErrands={checkedErrands}
                                toggleCheckedErrands={toggleCheckedErrands}
                                isMatch={checkIfIdMatches(errand._id)}
                                errand={errand}
                                dragHandleProps={provided.dragHandleProps}
                                isDragging={snapshot.isDragging}
                                handleSelectErrand={handleSelectErrand}
                                index={index}
                              />
                            </ErrorBoundary>
                          </li>
                        )}
                      </Draggable>
                    )
                  }
                )}

                {provided.placeholder}
                <li>
                  <button
                    onClick={() => startOfErrands?.current?.focus()}
                    ref={endOfErrands}
                    id="endOfErrands"
                    aria-label="Back to start of errands list"
                    style={{
                      backgroundColor: 'var(--gray000)',
                      border: 'none',
                      height: '1px',
                      margin: 0,
                      outlineColor: 'var(--peach900)',
                      padding: 0,
                      width: '100%',
                      '&:hover': {
                        backgroundColor: 'var(--peach900)',
                      },
                    } as React.CSSProperties}
                  />
                </li>
                {activeErrandCount > 150 && (
                  <li className={Styles.errandNotice}>{t('errandNotice')}{activeErrandCount}</li>
                )}
              </ul>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        <ErrandsFooter
          isDesktop={isDesktop}
          selectedIndex={props.selectedIndex}
          setSelectedIndex={props.setSelectedIndex}
          setShowUserPromptList={setShowUserPromptList}
          closeCheckedErrands={closeCheckedErrands}
          checkedErrands={checkedErrands.length > 0}
          errands={props.errands}
        />
      </aside>
    </ErrorBoundary>
  );
};

export default ErrandsContainer;