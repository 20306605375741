import React, { useMemo } from 'react';
import { addHook, sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';
import LanguageUtils from '../Common/LanguageUtils';
import Styles from '@styles/Sanitized.module.css';

interface sanitizedProps<T extends keyof JSX.IntrinsicElements = any> {
  html: string;
  translate?: boolean;
  highlight?: string;
  id?: string;
  className?: string;
  tag?: T;
  visible?: boolean;
}

interface nodeProps {
  tagName: string;
  setAttribute: (arg0: string, arg1: string) => void;
}

const escape = (str: String) => {
  var specials = /[.*+?|()[\]{}\\$^]/g;
  return str.replace(specials, "\\$&");
}

const highlightWordsNoCase = (str: String, substr: String) => {
  var regex = new RegExp("(" + escape(substr) + ")", "gi");
  return str.replace(regex, "<mark>$1</mark>");
}

const highlightTextContent = (node: Node, substr: string) => {
  if (node.nodeType === Node.TEXT_NODE) {
    const textNode = node as Text;
    const highlighted = highlightWordsNoCase(textNode.nodeValue || '', substr);
    const tempElement = document.createElement('span');
    tempElement.innerHTML = highlighted;
    const fragment = document.createDocumentFragment();
    while (tempElement.firstChild) {
      fragment.appendChild(tempElement.firstChild);
    }
    node.replaceWith(fragment);
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    const elementNode = node as Element;
    for (let child of Array.from(elementNode.childNodes)) {
      highlightTextContent(child, substr);
    }
  }
}

const Sanitized = ({
  html, highlight, id, className, tag, translate, visible = true
}: sanitizedProps) => {
  const { t } = useTranslation();
  const language = LanguageUtils.fetchLocalizationLanguageSetting();
  const sanitized = useMemo(() => {
    addHook('afterSanitizeAttributes', function (node: nodeProps) {
      if (node.tagName === 'A') {
        // force links to open in a new tab
        node.setAttribute('target', '_blank');
        // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
        node.setAttribute('rel', 'noreferrer');
      }
    });
    let content = (html || '').replace(/<i\s+class="messageArrow"\/>/g, '');

    //Some messages that come in from AI will have the useTranslate key embedded in them.
    //The delimiter for this is {{translate}}someText{{/translate}}
    if (translate) {
      const translationRegex = /<translate>(.*?)<\/translate>/g;
      content = content.replace(translationRegex, (match, translatableText) => t(translatableText));
    }

    // Replace <bubblelist> elements with an <ol> tag with the class name "bubbleList" to apply styling (number bubbles)
    const bubbleListRegex = /<bubblelist>(.*?)<\/bubblelist>/gs;
    content = content.replace(bubbleListRegex, (_, children) => `<ol class=${Styles.bubbleList}>${children}</ol>`);

    if (highlight) {
      // Use a DOM parser to highlight text content without destroying the styling and structure
      let parser = new DOMParser();
      let parsedDoc = parser.parseFromString(content, 'text/html');
      highlightTextContent(parsedDoc.body, highlight);
      content = parsedDoc.body.innerHTML;
    }

    // Replace text content with asterisks if visible is false
    if (!visible) {
      content = content.replace(/<(\w+)[^>]*>(.*?)<\/\1>/g, (match, tagName, content) => {
        return `<${tagName}>${'*'.repeat(content.length)}</${tagName}>`;
      });
    }
    
    // allows specific tags / attributes that we use in sanitizing html
    return sanitize(content, { ALLOWED_TAGS: ['a', 'blockquote', 'table', 'tr', 'td', 'thead', 'th', 'tbody', 'div', 'h1', 'i', 'input', 'button', 'br', 'p', 'span', 'b', 'mark', 's', 'u', 'wbr', 'select', 'option', 'ol', 'li'] });
  }, [html, highlight, language, visible]);

  const Tag = tag || 'span';

  return (
    <Tag id={id} className={className} dangerouslySetInnerHTML={{ __html: sanitized }} />
  );
};

export default Sanitized;
