/*
This component renders a prompt in the Conversation Footer to ask the user for consent for humans to monitor their chats.
In the future, this component will need to be generalized to match any other text that uses this format.
*/
import React, { Dispatch, SetStateAction, useMemo, useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import eventBus from '../Common/eventBus';
import useWindowDimensions from '../Common/hooks/useWindowDimensions';
import useWidget from '@common/hooks/useWidget';
import Styles from '@styles/ConsentContent.module.css';
import { MorganTheme, useTheme } from '@mui/material';
import axiosCall from '@services/axios';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import useControllerCallback from '@common/hooks/useControllerCallback';
import { setUserConsent as setLocalUserConsent } from '@storage/userStorage';
import { useCaptchaContext } from '@contexts/captcha';
import useInitialMount from '@common/hooks/useInitialMount';
import { useLocation } from 'react-router-dom';
const Logo = process.env.REACT_APP_MORGAN_CDN + '/Images/angelConsentLogo.png';
const ConsentSlide1 = process.env.REACT_APP_MORGAN_CDN + '/Images/consentSlide1.png';
const ConsentSlide2 = process.env.REACT_APP_MORGAN_CDN + '/Images/consentSlide2.png';
const ConsentSlide3 = process.env.REACT_APP_MORGAN_CDN + '/Images/consentSlide3.png';
const ConsentSlide4 = process.env.REACT_APP_MORGAN_CDN + '/Images/consentSlide4.png';

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme: MorganTheme = useTheme();

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentIndex((currentIndex + 1) % items.length),
    onSwipedRight: () => setCurrentIndex((currentIndex - 1 + items.length) % items.length),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className={Styles.carouselContainer} {...handlers}>
      <div className={Styles.carouselContent}>
        <link rel='prefetch' href={ConsentSlide4} />
        <img src={items[currentIndex].image} alt={items[currentIndex].text} />
        <h1 className={Styles.carouselHeader}>{items[currentIndex].header}</h1>
        { items[currentIndex].id === '1' ? 
          <p>
            <Trans i18nKey="consentSlide1">
              links
            <a target="_blank" href={theme.termsAndConditions.disclaimerURL} rel="noreferrer" tabIndex={-1}>
              regulated financial entity
            </a>
            <a target="_blank" href="https://en.wikipedia.org/wiki/Gramm%E2%80%93Leach%E2%80%93Bliley_Act" rel="noreferrer" tabIndex={-1}>
              GLB
            </a>
            <a target="_blank" href="https://www.investopedia.com/terms/a/aml.asp" rel="noreferrer" tabIndex={-1}>
              AML
            </a>
            <a target="_blank" href="https://en.wikipedia.org/wiki/Bank_Secrecy_Act" rel="noreferrer" tabIndex={-1}>
              BSA
            </a>
            </Trans>
          </p>
        : items[currentIndex].id === '3' ? 
        <>
          <p className={Styles.slide3p}>{items[currentIndex].text}</p>
          <p>
            <Trans i18nKey="consentSlide3-2">
              links
            <a target="_blank" href="https://www.swmc.com/" rel="noreferrer" tabIndex={-1}>
              Sun West Mortgage Company, Inc. NMLS 3277
            </a>
            </Trans>
          </p>
        </>
        : items[currentIndex].id === '4' ? 
        <p>
          <Trans i18nKey="consentSlide4">
            links
          <a target="_blank" href="https://www.angelai.com/warranty" rel="noreferrer" tabIndex={-1}>
            warranty
          </a>
          </Trans>
        </p>
        :
          <p>{items[currentIndex].text}</p>
        }
      </div>
      <div className={Styles.carouselIndicators}>
        {items.map((item, index) => (
          <div className={Styles.carouselIndicatorBox} 
          onClick={() => setCurrentIndex(index)} key={item.id}>
            <span
            className={`${Styles.indicator} ${index === currentIndex ? Styles.active : ''}`}
          ></span>
          </div>
        ))}
      </div>
    </div>
  );
};

enum ConsentType {
  None,
  Intro,
  Terms,
}

type TConsentContentProps = {
  setUserConsent: Dispatch<SetStateAction<string>>;
  userConsent: string;
}

const ConsentContent = ({
  setUserConsent, userConsent, 
}: TConsentContentProps) => {
  const { isWidget } = useWidget();
  const { referrer, hashkey } = useParams();
  const { t } = useTranslation();
  const theme: MorganTheme = useTheme();
  const navigate = useNavigate();
  const { resetCaptcha, testCaptcha } = useCaptchaContext();

  // renders the scroll button or no / yes as needed
  const [consentState, setConsentState] = useState<ConsentType>(ConsentType.None);
  const [hasClickedConsent, setHasClickedConsent] = useState<boolean>(false);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(true);
  const [response, setResponse] = useState(null); 
  const [hasScrolled, setHasScrolled] = useState(isWidget);
  const [atBottom, setAtBottom] = useState(false);
  const [isCreditRepair, setIsCreditRepair] = useState(false);
  const [hasCheckedSearchParams, setHasCheckedSearchParams] = useState(false);
  const location = useLocation();

  const consentContentRef = useRef<HTMLDivElement>(null);
  const paragraph = useRef<HTMLDivElement>(null);
  const checkedParagraphHeight = useRef<boolean>(false);
  const captchaFailCount = useRef<number>(0);

  const isMobileWidget: boolean = useMemo(() => isMobileOrTablet() && isWidget, [isWidget]);
  const isSmallHeight = (window.innerHeight < 530) 
  || (window.innerWidth < 1200 && window.innerHeight < 650);
  const isBigHeight = (window.innerHeight > 700)
  const isReferred: boolean = useMemo(() => Boolean(response?.data?.fullName), [response?.data?.fullName]);

  const phone:string = theme.system.phone;
  const disclaimer:string = theme.termsAndConditions.disclaimerURL?.replace(/^(https?:\/\/)?(www\.)?/i, '');

  // Check to see if the URL used involved credit repair
  const checkLink = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const welcomeParam = searchParams.get('welcome');

    if (!hasCheckedSearchParams) {
      setHasCheckedSearchParams(true);
      if (welcomeParam && ['creditrepair', 'creditboost'].includes(welcomeParam.toLowerCase())) {
        setIsCreditRepair(true);
        return;
      }else{
        setIsCreditRepair(['/creditrepair', '/creditboost'].includes(location.pathname.toLowerCase()));
      }
    }
  }, [hasCheckedSearchParams, isCreditRepair, location.search, location.pathname]);

  useInitialMount(checkLink)


  const defaultFirstPage = {
      id: '1',
      image: ConsentSlide1,
      header: `${t('consentSlideTitle1')}`,
      text: `${t('consentSlide1')}`
  }

  const creditRepairPage = {
    id: '2',
    image: ConsentSlide2,
    header: `${t('consentSlideTitle2')}`,
    text: `${t('consentSlide2')}`,
  }

  const items = [
    isCreditRepair ? creditRepairPage : defaultFirstPage,
    isCreditRepair ? defaultFirstPage : creditRepairPage,
    {
      id: '3',
      image: ConsentSlide3,
      header: `${t('consentSlideTitle3')}`,
      text: `${t('consentSlide3')}`,
    },
    {
      id: '4',
      image: ConsentSlide4,
      header: `${t('consentSlideTitle4')}`,
      text: `${t('consentSlide4')}`,
    },
  ];

  const acceptConsent = useCallback(() => {
    setHasClickedConsent(true);
    setConsentState(ConsentType.None);
    if (isWidget) {
      window.parent.postMessage({eventType: 'closeConsentPopup'}, '*');
    }

    setTimeout(() => {
      eventBus.dispatch('consentGiven');
      setUserConsent('true');
      setLocalUserConsent('true');
    }, 0);
  }, [isWidget]);

  const denyConsent = useCallback(() => {
    setHasClickedConsent(true);
    setConsentState(ConsentType.None);
    if (isWidget) {
      window.parent.postMessage({eventType: 'closeConsentPopup'}, '*');
    }

    setTimeout(() => {
      setUserConsent('true');
      setLocalUserConsent('false');
    }, 250);
  }, [isWidget]);

  const handleResize = useCallback(() => {
    if (!paragraph.current) return;

    if (paragraph.current.scrollTop + paragraph.current.clientHeight >= paragraph.current.scrollHeight - 10) {
      setShowScrollButton(false);
    } else {
      setShowScrollButton(true);
    }
  }, []);

  useWindowDimensions(handleResize);

  const handleUpButton = () => {
    paragraph.current.scrollTo({behavior: 'smooth', top: 0 });
  }

  const handleScroll = useCallback((e = null) => {
    if (!hasScrolled) setHasScrolled(true);
    if (e === true) {
      paragraph.current.scrollTo({behavior: 'smooth', top: paragraph.current.scrollHeight + 300 });
      return setShowScrollButton(false);
    }else{
      setAtBottom(false);
    }

    if (paragraph.current.scrollTop + paragraph.current.clientHeight >= paragraph.current.scrollHeight - 2) {
      setAtBottom(true);
      return setShowScrollButton(false);
    }
  }, []);

  useEffect(() => {
    const container = paragraph.current;
    if (!container || consentState === ConsentType.None) return;

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll, consentState]);

  useEffect(() => {
    if (userConsent !== 'true') {
      setTimeout(() => {
        setConsentState(ConsentType.Intro);
      }, 0)
    }
  }, []);

  const onCaptchaResult = useCallback((passed) => {
    if (!passed) return;
    resetCaptcha();
    setConsentState(ConsentType.Terms);
    if (isWidget) {
      window.parent.postMessage({eventType: 'closeConsentPopup'}, '*');
    }
  }, [isWidget, resetCaptcha]);

  const getReferrerData = useCallback( async (controller) => {
    if (!referrer) return;
    try {
      const referrerSearchRequest = {
        url: `referrer/getPathDetails/${referrer}`,
      };
      const referrerSearch = await axiosCall(referrerSearchRequest, controller);

      const request = {
        url: `user/loanOfficerDetailsAndImage/${referrerSearch?.userId}`,
      };
      const response = await axiosCall(request, controller);
      if (response) {
        setResponse({ data: response });
      }
    } catch (err) {
      console.log('ConsentContent: referrerSerach error: ' + err);
    }
  }, [referrer]);

  useControllerCallback(getReferrerData);

  useEffect(() => {
    if (isWidget) setHasScrolled(true);
    const handleIframeMessage = (event) => {
      if(event.data.eventType === 'closeConsentPopup') {
        denyConsent();
      }
    };

    const handleConsentContent = (e) => {
      setUserConsent('null');
      setConsentState(ConsentType.Terms);
      setHasClickedConsent(false);
      if (isWidget) {
        window.parent.postMessage({eventType: 'openConsentPopup'}, '*');
      }
    };

    eventBus.on('showConsentContent', handleConsentContent);
    window.addEventListener('message', handleIframeMessage);
    return () => {
      eventBus.remove('showConsentContent', handleConsentContent);
      window.removeEventListener('message', handleIframeMessage);
    }
  }, [isWidget, denyConsent]);

  useEffect(() => {
    if (!isWidget && !checkedParagraphHeight.current && paragraph.current && paragraph.current?.scrollHeight === paragraph.current?.clientHeight) {
      checkedParagraphHeight.current = true;
      setShowScrollButton(false);
      paragraph.current.focus();
    }
    else{
      setTimeout(() => {
        if (!checkedParagraphHeight.current && paragraph.current && paragraph.current?.scrollHeight === paragraph.current?.clientHeight) {
          checkedParagraphHeight.current = true;
          setShowScrollButton(false);
          paragraph.current.focus();
        }
      }, 500)
    }
  }, [isWidget]);

  const renderTopContent = () => {
    return (
      <div className={[
        Styles.intro,
        ...(isMobileWidget ? [Styles.isMobileWidget] : [])
      ].join(' ')}>
        <div className={Styles.imgWrapper}>
          <div className={response?.data?.image ? Styles.referrerLogo : Styles.placeholder}>
            {isReferred && (
              <>
                <img
                  src={response?.data?.image ? response?.data?.image : Logo}
                  alt='Referrer'
                  height={response?.data?.image ? '95px' : '115px'}
                  width={response?.data?.image ? '95px' : '115px'}
                />
                <div className={Styles.invite}>{t('tReferrer')}</div>
              </>
            )}
          </div>
          <div className={Styles.angelLogo}>
            <img src={Logo} alt='' aria-hidden='true' />
          </div>
        </div>
        <h1 className={Styles.AngelAi}>AngelAi</h1> 
        <div className={[
          Styles.introContent
        ].join(' ')}>
          {response?.data?.fullName && <h3>{t('courtesyOf').replace('[name]', response?.data?.fullName)}</h3>}
          {!isWidget && <div className={Styles.consentIntroLine}>
            {t(isCreditRepair ? 'consentCreditRepairIntro' : 'consentIntro')}
            </div>}
          <div className={[
            Styles.mainContent,
            ...((isSmallHeight && !isWidget) || (isSmallHeight && isWidget && isMobileOrTablet()) ? [Styles.isSmallHeight] : []),
            ...(isSmallHeight && isMobileOrTablet() && !isWidget ? [Styles.isSmallHeightMobile] : []),
            ...(isBigHeight && !isWidget && isMobileOrTablet() ? [Styles.isBigHeightMobile] : [])
          ].join(' ')
            } ref={paragraph}>
            {!isWidget && <Carousel items={items}/>}
            {renderTerms()}
          </div>
        </div>
      </div>
    );
  }

  const renderTerms = () => {
    return (
      <div
        className={[
          Styles.terms,
          ...(isWidget ? [Styles.isWidgetTerms] : [])
        ].join(' ')}
      >
        <h1 className={[
          Styles.tagline
        ].join(' ')}>
          {t('tagline')}
        </h1>
        <p>
          <Trans i18nKey="tConsent" phone={phone} disclaimer={disclaimer}>
            terms
            {theme.system.companyName}
            {theme.system.NMLS}
            <a target="_blank" href={theme.termsAndConditions.termsConditionsURL} rel="noreferrer" tabIndex={consentState === ConsentType.None ? -1 : undefined}>
              Terms and Conditions
            </a>
            <a target="_blank" href={theme.termsAndConditions.termsPrivacyPolicyURL} rel="noreferrer" tabIndex={consentState === ConsentType.None ? -1 : undefined}>
              Privacy Policy
            </a>
          </Trans>
        </p>
      </div>
    );
  }

  const renderBottomContent = () => {
    return (
      <div className={[
        Styles.buttonContainer,
        ...(hasScrolled && !showScrollButton ? [Styles.scrolledButtonContainer] : [])
      ].join(' ')}>
          {showScrollButton ? (
            <button className={Styles.scrollButton} onClick={() => handleScroll(true)} tabIndex={consentState === ConsentType.None ? -1 : undefined}>
              {t('scrollDown')}
            </button>
          ) : (
            <div className={Styles.scrolledInnerButtonContainer}>
              {atBottom ? 
                <button className={[Styles.scrollButton, Styles.animateButton].join(' ')} onClick={handleUpButton} tabIndex={consentState === ConsentType.None ? -1 : undefined}>
                  {t('scrollUp')}
                </button>
                :
                <button className={[Styles.scrollButton, Styles.animateButton].join(' ')} onClick={() => handleScroll(true)} tabIndex={consentState === ConsentType.None ? -1 : undefined}>
                {t('scrollDown')}
              </button>
              }
              <div className={Styles.betweenButtons}/>
              <button className={[Styles.consentButton, Styles.animateButton].join(' ')} onClick={acceptConsent} tabIndex={consentState === ConsentType.None ? -1 : undefined}>
                {t('iConsent')}
              </button>
            </div>
          )}
      </div>
    );
  };

  const renderBottomContentWidget = () => {
    return (
      <div className={Styles.buttonContainerWidget}>
          {showScrollButton ? (
            <button className={Styles.scrollButton} onClick={() => handleScroll(true)} tabIndex={consentState === ConsentType.None ? -1 : undefined}>
              {t('scrollDown')}
            </button>
          ) : (
            <button className={Styles.consentButton} onClick={acceptConsent} tabIndex={consentState === ConsentType.None ? -1 : undefined}>
              {t('iConsent')}
            </button>
          )}
      </div>
    );
  };

  return (
    <section 
      className={[
        Styles.wrapper,
        Styles[`type${consentState}`],
        ...(hasClickedConsent ? [Styles.hasClickedConsent] : []),
        ...(isReferred ? [Styles.isReferred] : []),
      ].join(' ')}
      ref={consentContentRef}>
      <div className={Styles.content}>
        <div className={[
          Styles.consentTop,
          ...(hasScrolled && !showScrollButton && !isWidget ? [Styles.bottomBorder] : [])
          ].join(' ')}>
          {renderTopContent()}
          {isWidget ? renderBottomContentWidget() : !hasScrolled && renderBottomContentWidget()}

        </div>
        {!isWidget && hasScrolled &&
        <div className={Styles.buttonWrapper}>
          {renderBottomContent()}
        </div>}

      </div>
    </section>
  );
};
export default ConsentContent;