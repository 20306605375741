import React, { useCallback, useMemo } from 'react';

import ConversationBody from '@components/ConversationBody';
import { useErrandContext } from '@contexts/ErrandContext';
import Styles from '@styles/MorphPrivateChat.module.css';
import type { TConversationBodyProps } from '@components/ConversationBody';
import { useRootContext } from '@contexts/RootContext';
import { MorphType } from '@common/MorphType';
import TypingIndicator from '@components/TypingIndicator';
import { useUserContext } from '@contexts/user';
import { ResetFooterUserAction } from '@common/common';

const Shush = process.env.REACT_APP_MORGAN_CDN + '/Images/Shush.png';
const Bell = process.env.REACT_APP_MORGAN_CDN + '/Images/Bell.png';

const MorphPrivateChat = ({
  dispFilterMode, editMessageId, errand, isTyping, messageFilter, operatorData, setEditMessageId, setIsTyping, setPreviewUrl, setValue, showSentiment, 
}: TConversationBodyProps) => {
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const { isOperator } = useUserContext();

  const title = useMemo(() => {
    const participants = errand?.participants.filter((p) => p?.active && errand.recipients.indexOf(p?.userData?._id) !== -1);
    const names = participants.map((p) => {
      if (!p || !p.userData || !p.userData.firstname) return '';
      if (isOperator) {
        return `${p.userData.firstname} ${p.userData.lastname || ''}`.trim();
      }
      if (p.userType === 'Operator') return '';
      return `${p.userData.firstname} ${p.userData.lastname || ''}`.trim();
    }).filter((v, i, a) => v && a.indexOf(v) === i);
    console.error({names, participants})
    if (names.length === 1) {
      names.unshift('AngelAi');
    }

    return names.join(' / ');
  }, [errand.recipients, isOperator]);

  const handleClose = useCallback(() => {
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errand._id);
      
      if (chatObj) {
        ResetFooterUserAction(chatObj);

        chatObj.recipients = [];
      }

      // combine all re-render triggers
      errandContext.setIsMorphedFooterCloseButtonOnLeft(false);
      errandContext.setMorphType(MorphType.None);
      return [...prev];
    });
  }, [])

  return (
    <>
      <section className={Styles.wrapper}>
        <button className={Styles.button} onClick={handleClose}></button>
        <div aria-hidden="true" className={Styles.header}>
          <img src={Shush} alt="Angel with index finger on lips asking you to keep her secrets" />
          <div>
            <img src={Bell} alt="notification bell ringing" />
          </div>
        </div>
        <p className={Styles.title}>{title}</p>
        <div className={Styles.content}>
          <ConversationBody
            dispFilterMode={dispFilterMode}
            editMessageId={editMessageId}
            errand={errand}
            isPrivate={true}
            messageFilter={messageFilter}
            operatorData={operatorData}
            setEditMessageId={setEditMessageId}
            setPreviewUrl={setPreviewUrl}
            setValue={setValue}
            showBouncyRope={false}
            showSentiment={showSentiment}
          />
        </div>
        <TypingIndicator
          errand={errand}
          isTyping={isTyping}
          setIsTyping={setIsTyping}
          operatorData={operatorData}
          isPrivate={true}
        />
      </section>
    </>
  );
};

export default MorphPrivateChat;
