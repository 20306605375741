import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '@common/hooks/useWindowDimensions';

/*
 *  This component renders the title of the errands box on the right side of the page
 *
 *  This component currently does not take in any props
 *
 */

const ErrandsTitle: FC = () => {
  const { innerWidth } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <div
      style={{
        padding: 10,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '56px',
        height: '56px',
        marginTop: '-1px',
        backgroundColor: 'var(--peach700)',
        borderTopRightRadius: innerWidth > 900 ? '8px' : '0',
        borderTopLeftRadius: innerWidth > 900 ? '8px' : '0',
        border: '1px solid var(--peach700)',
        borderBottom: innerWidth > 900 ? 'none' : `3px solid var(--peach700)`,
      }}
    >
      <Typography
        color="var(--blue999)"
        sx={{
          fontSize: innerWidth > 900 ? '14px' : '12px',
          fontWeight: '600',
          letterSpacing: '1px',
        }}
      >
        {t('conversationErrandsTitle')}
      </Typography>
    </div>
  );
};

export default ErrandsTitle;
