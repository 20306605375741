import { Admin as AdminIcon, Monitoring as MonitoringIcon } from '../Assets/Icons';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState, Suspense, lazy, memo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Avatar from './Avatar';
import AdminBreadcrumbNav from './AdminBreadcrumbNav';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import GridViewIcon from '@mui/icons-material/GridView';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LangPicker from './LanguagePickerSelect';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import OnlineOperatorList from './OnlineOperatorList';
import StatusPicker from './StatusPicker';
import TuneIcon from '@mui/icons-material/Tune';
import getImageSource from '../Common/getImageSource';
import packageData  from '../../package.json';
import { useTranslation } from 'react-i18next';
import SnackbarComponent from '@components/SnackbarComponent';
import { RootContext } from '../Contexts/RootContext';
import getUnreadNotifications from '../Common/getUnreadNotifications';
import { UserWayAccessibilityMenu } from '@components/UserWayAccessibilityMenu';
import SystemNavigationMenu from './SystemNavigationMenu';
import AINavigationMenu from './AINavigationMenu';

import { sectionNameTranslationKeys } from '../Constants/consoleConstants';
import { useSocketContext } from '@contexts/socket';
import { useUserContext } from '@contexts/user';
import axiosCall from '@services/axios';
import { getOperatorId } from '@storage/operatorStorage';

const Dashboard = lazy(() => import('@components/Dashboard'));
const ConsoleConversationWrapper = lazy(() => import('@components/ConsoleConversationWrapper'));
const ProfileSettings = lazy(() => import('@components/ProfileSettings'));
const Monitoring = lazy(() => import('@components/Monitoring'));
const Admin = lazy(() => import('@components/Admin'));
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';

/* Custom colored nav elements */
const CustomListItem = styled(ListItem)(({ theme }) => ({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: 'transparent',
    '&, & .MuiListItemIcon-root': {
      color: theme.palette.blue['200'],
    },
  },
}));

/**
 * A Box with a customized scrollbar (like that of the SMS/Messages component)
 */
const CustomScrollBox = styled(Box)(() => ({
  overflow: 'hidden',
  paddingRight: '0.4em',
  '&:hover': {
    overflowY: 'scroll',
    paddingRight: 0,
  },
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px var(--shadow000)',
    webkitBoxShadow: 'inset 0 0 6px var(--shadow000)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'var(--shadow110)',
    outline: '1px solid slategrey',
    borderRadius: '0.2em',
  },
}));

/** 
 * A link to a submodule (e.g., tAdmin/tSystem) that is slightly shorter in height than a main link
 */
const ModuleLink = ({ baseModule, subModulePath, tabLinkText, location, theme, navigate, subMenu }) => {
  const listItemButtonRef = useRef(null);
  const [subMenuOpen, setSubMenuOpen] = useState(null);
  const currentModuleSelected = location.state?.module.startsWith(`${baseModule}/${subModulePath}`);

  /***********************************************************
   * Functions for opening and closing sub menu if one exists
   ***********************************************************/

  const handleToggle = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleClose = () => {
    setSubMenuOpen(false);
  };

  return (
    <CustomListItem
      sx={{ paddingLeft: '0.5rem', marginY: '-0.5rem' }} 
      theme={theme} 
      disablePadding={true}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <ListItemButton
          ref={listItemButtonRef}
          onClick={() => {
            if (subMenu) {
              handleToggle();
            } else if (!currentModuleSelected) { // Don't re-navigate to this page if the user is already on it
              navigate('/console', {
                state: {
                  module: `${baseModule}/${subModulePath}`,
                },
              });
            }
          }}
          selected={currentModuleSelected}
        >
          <ListItemIcon sx={{ visibility: 'hidden' }}></ListItemIcon>
          <ListItemText primary={tabLinkText} />
          {subMenu ? (subMenuOpen ?
              <ChevronLeftIcon onClick={handleToggle} sx={{ cursor: 'pointer', ml: 1 }}/> : 
              <ChevronRightIcon onClick={handleToggle} sx={{ cursor: 'pointer', ml: 1 }}/>
            ) :
            null
          }
        </ListItemButton>
      </Box>
      {subMenu && (
        <Popover
          open={subMenuOpen}
          anchorEl={listItemButtonRef.current}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        >
          {React.cloneElement(subMenu, { closeMenu: handleToggle })}
        </Popover>
      )}
    </CustomListItem>
  );
};

const ConsoleWrapperComponent = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { hashkey } = useParams();

  const { firstname, lastname, accessLevel, avatar, _id, setUser, logout, login } = useUserContext();
  const { isEventsConnected, isMessagesConnected, eventsSocket } = useSocketContext();

  /*
   *  Events and Messages data for all components within the dashboard.
   */
  const [drawerState, setDrawerState] = useState('closed');
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [onlineOperators, setOnlineOperators] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  // Fixes race condition between snackbar and conversation that results in operators recieving unread message notifications for thier current chat
  const [isSnackbarReady, setIsSnackbarReady] = useState(false);
  const [childErrands, setChildErrands] = useState([]);
  const [errands, setErrands] = useState([]);
  // reference to user side element to prevent crashes on operator side
  const drawerRef = useRef(null);

   // Determine if messages in conversation are loading or not.
  // If loading has ended, set the status to false to stop rendering skeletons.
  const [messagesAreLoading, setMessagesAreLoading] = useState(true);
  // For the AI tab
  const [selectedAI, setSelectedAI] = useState('');

  useEffect(() => {
    login();
  }, [login]);

  // If the operator lands in the /console after closing the tab or browser this will ensure
  // that if the session is valid then land on the default console section.
  useEffect(() => {
    if (location.state === null && !hashkey) {
      return navigate('/console', {
        state: {
          module: 'dashboard',
          tab: 'msg',
        },
      });
    } else if (hashkey &&
      (!location.state || location.state.module === "dashboard")) {
        (async () => {
          try {
            const key = await axiosCall({ url: `hashkey/${hashkey}` });
            if (key?.parameters?.chatId) {
              await axiosCall({ url: `chat/${key?.parameters?.chatId}`, method: 'PUT', data: { status: 'active' } });
              await axiosCall({ url: `chat/${key?.parameters?.chatId}/participant`, method: 'POST', data: { userType: 'Operator', userId: getOperatorId() } });

              // Route operator to the chat where the needed data is extracted
              return navigate('/console/' + hashkey, {
                state: {
                  module: 'conversations',
                  tab: 'mine',
                  chat: key?.parameters?.chatId
                },
              });
            }
          } catch (e) {
            console.error(`Could not get chatId from hashkey ${hashkey}`);
          }
        })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    //#startonconnectlogic
    if (!_id || !isEventsConnected) return;

    getUnreadNotifications(_id, setNotifications);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected, _id]);

  useEffect(() => {
    if (isEventsConnected) {
      props.setProgress((prev) => {
        if (prev > 100) {
          return 100;
        }
        else {
          return prev + 50;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected]);

  useEffect(() => {
    if (isMessagesConnected) {
      props.setProgress((prev) => {
        if (prev > 100) {
          return 100;
        }
        else {
          return prev + 50;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected]);

  /**
   * Handle eventsSocket core events
   */
  useEffect(() => {
    if (!isEventsConnected) return;

    // #eventssocketfunctions Each function corresponds to the socket event's name in cammelCase
    const onSessionStatusUpdate = async (payload) => {
      console.log(`Events Socket - (session-status-update) ${payload?.message}`, payload?.data);
      setUser((prev) => ({ ...prev, ...payload?.data }));
    }

    const onUserStatusUpdate = (payload) => {
      console.log(`Events Socket - ConsoleWrapper - (user-status-update)`, payload);

      if (payload?.data?.status === 'offline') {
        setOnlineUsers((prev) =>
          prev.filter((e) => e?._id?.toString() !== payload.data?._id?.toString())
        );
      } else {
        setOnlineUsers((prev) => {
          let updated = false;
          let updatedUsers = prev.map((user) => {
            // if the user id is equal to the new user's id
            if (user?._id?.toString() === payload?.data?._id?.toString()) {
              // set updated to true so that we can properly set state
              updated = true;
              // return the updated user.
              return payload?.data;
            }
            // keep all other users in the map
            return user;
          });
          if (!updated) {
            // add user to the list
            return [...prev, payload.data];
          }
          // set the users to the final return value
          return updatedUsers;
        });
      }
    }

    const onOperatorStatusUpdate = (payload) => {
      console.log(`Events Socket - ConsoleWrapper - (operator-status-update)`, payload);

      if (payload?.data?._id.toString() === _id) {
        setUser((prev) => ({ ...prev, ...payload?.data }));
      } else if (payload?.data?.status === 'offline') {
        setOnlineOperators((prev) =>
          prev.filter((e) => e?._id?.toString() !== payload.data?._id?.toString())
        );
      } else {
        setOnlineOperators((prev) => {
          let updated = false;
          let updatedOperators = prev.map((operator) => {
            // if the operator id is equal to the new operator's id
            if (operator?._id?.toString() === payload?.data?._id?.toString()) {
              // set updated to true so that we can properly set state
              updated = true;
              // return the updated operator.
              return payload?.data;
            }
            // keep all other operators in the map
            return operator;
          });
          if (!updated) {
            // add operator to the list
            return [...prev, payload.data];
          }
          // set the operators to the final return value
          return updatedOperators;
        });
      }
    }

    const onMonitorUserSession = (payload) => {
      console.log(`Events Socket - ConsoleWrapper - (monitor-user-session)`, payload);
      logout();
    }

    const onNotificationsUpdate = async (payload) => {
      console.log(`Events Socket - ConsoleWrapper - (notifications-update)`, payload);

      const data = payload?.data;

      setNotifications((prev) => {
        // guards against crashes due to prev not being iterable
        if (!Array.isArray(prev)) return [data];
        return [...prev, data]
      });
    }
    // #endeventssocketfunctions

    // This one is the array of online users
    eventsSocket.current?.emit('monitor-user-events', (payload) => {
      console.log(`Events Socket - (monitor-user-events) ${payload?.message}`, payload?.data);
      setOnlineUsers(payload?.data);
    });
    // This one is the array of online operators
    eventsSocket.current?.emit('monitor-operator-events', (payload) => {
      console.log(`Events Socket - (monitor-operator-events) ${payload?.message}`, payload?.data);
      console.log(payload?.data);

      setOnlineOperators(payload?.data);
    });
    //#endonconnectlogic

    console.log('Events Socket - ConsoleWrapper - (on)');
    eventsSocket.current?.on('session-status-update', onSessionStatusUpdate);
    eventsSocket.current?.on('user-status-update', onUserStatusUpdate);
    eventsSocket.current?.on('operator-status-update', onOperatorStatusUpdate);
    eventsSocket.current?.on('monitor-user-session', onMonitorUserSession);
    eventsSocket.current?.on('notifications-update', onNotificationsUpdate);
    return () => {
      console.log('Events Socket - ConsoleWrapper - (off)');
      eventsSocket.current?.off('session-status-update', onSessionStatusUpdate);
      eventsSocket.current?.off('user-status-update', onUserStatusUpdate);
      eventsSocket.current?.off('operator-status-update', onOperatorStatusUpdate);
      eventsSocket.current?.off('monitor-user-session', onMonitorUserSession);
      eventsSocket.current?.off('notifications-update', onNotificationsUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected]);

  return (<>
    {firstname && (
    <RootContext.Provider value={{ messagesAreLoading, setMessagesAreLoading, childErrands, setChildErrands, errands, setErrands, drawerRef }}>
    <Box sx={{ padding: '0px 20px 0px 0px' }}>

      <SnackbarComponent
        setIsSnackbarReady={setIsSnackbarReady}
      />
      {isSnackbarReady && (
        <Box sx={{ display: 'flex', overflow: 'hidden', height: '100vh', width: '100vw' }}>
          {/* Navigation Menu */}
          <CustomScrollBox 
            sx={{ 
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              borderRight: '1px solid var(--shadow112)', 
              minWidth: 'fit-content',
              position: 'relative'
            }}
          >
            <Box sx={{ height: 200, display: 'flex' }}>
              {drawerState === 'open' ? (
                <img
                  src={AngelAi}
                  aria-hidden='true'
                  style={{display: 'block', margin: 'auto'}}
                />
              ) : (
                <img
                  src={AngelAi}
                  aria-hidden='true'
                  style={{display: 'block', margin: 'auto', width: '65px', height: '65px'}}
                />
              )}
            </Box>
            <List>
              {/* Main navigation options */}
              <CustomListItem theme={theme} disablePadding={true}>
                <ListItemButton
                  onClick={() => {
                    navigate('/console', {
                      state: {
                        module: 'dashboard',
                        tab: 'msg',
                      },
                    });
                  }}
                  selected={location.state?.module === 'dashboard'}>
                  <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GridViewIcon />
                  </ListItemIcon>
                  {drawerState === 'open' && <ListItemText primary={t('consoleDashboardButton')} />}
                </ListItemButton>
              </CustomListItem>

              <CustomListItem theme={theme} disablePadding={true}>
                <ListItemButton
                  onClick={() => {
                    navigate('/console', {
                      state: {
                        module: 'conversations',
                        tab: 'mine',
                      },
                    });
                  }}
                  selected={location.state?.module === 'conversations'}>
                  <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ElectricBoltIcon />
                  </ListItemIcon>
                  {drawerState === 'open' && <ListItemText primary={t('consoleMorganButton')} />}
                </ListItemButton>
              </CustomListItem>

              <CustomListItem theme={theme} disablePadding={true}>
                <ListItemButton
                  onClick={() => {
                    navigate('/console', {
                      state: {
                        module: 'settings',
                      },
                    });
                  }}
                  selected={location.state?.module === 'settings'}>
                  <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TuneIcon sx={{ transform: 'rotate(90deg)' }} />
                  </ListItemIcon>
                  {drawerState === 'open' && <ListItemText primary={t('consoleSettingsButton')} />}
                </ListItemButton>
              </CustomListItem>

              {accessLevel === 'Admin' && (
                <>
                  <CustomListItem theme={theme} disablePadding={true}>
                    <ListItemButton
                      onClick={() => {
                        navigate('/console', {
                          state: {
                            module: 'monitoring',
                          },
                        });
                      }}
                      selected={location.state?.module === 'monitoring'}>
                      <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                        <MonitoringIcon />
                      </ListItemIcon>
                      {drawerState === 'open' && (
                        <ListItemText primary={t('consoleMonitoringButton')} />
                      )}
                    </ListItemButton>
                  </CustomListItem>

                  { /* Dropdown for links to the Admin component tabs */ }
                  <CustomListItem theme={theme} disablePadding={true}>
                    <ListItemButton
                      onClick={() => {
                        if (drawerState !== 'open') {
                          setDrawerState('open')
                          setAdminDropdownOpen(true)
                        } else {
                          setAdminDropdownOpen(prev => !prev);
                        }
                      }}
                    >
                      <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                        <AdminIcon />
                      </ListItemIcon>
                      {drawerState === 'open' && (
                        <>
                          <ListItemText primary={t(sectionNameTranslationKeys['Admin'])} /> 
                          {adminDropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </>
                      )}
                    </ListItemButton>
                  </CustomListItem>
                  {/* Generate and display the admin links if the admin dropdown is open */}
                  {(drawerState === 'open' && adminDropdownOpen) && (
                    Object.entries(sectionNameTranslationKeys).map(([key, value]) => {
                      // 'Admin' itself is not a tab, skip generating a link for it
                      if (key === 'Admin') {
                        return null;
                      }

                      const baseModule = sectionNameTranslationKeys['Admin'];
                      let subMenu;
                      // Determine submenu to display, if any
                      if (key === 'System') {
                        subMenu = <SystemNavigationMenu />;
                      } else if (key === 'AI') {
                        subMenu = <AINavigationMenu selectedAI={selectedAI} setSelectedAI={setSelectedAI} />;
                      }

                      // Create the link to the module
                      return <ModuleLink location={location} navigate={navigate} theme={theme} baseModule={baseModule}
                              subModulePath={value} tabLinkText={t(value)} subMenu={subMenu} key={key} />;
                    })
                  )}
                </>
              )}
            </List>

            <ListItem disablePadding sx={{ marginTop: 'auto' }}>
              <ListItemButton
                onClick={() => {
                  setDrawerState(drawerState === 'open' ? 'closed' : 'open');
                }}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ListItemIcon sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {drawerState === 'open' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </CustomScrollBox>

          <Box sx={{ flexGrow: 1, overflowY: 'auto', paddingRight: 2 }}>
          {/* Header bar: icon, notifications button, messages button, and user icon */}
            <Stack
              alignItems="center"
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                flexDirection: 'row',
                height: 95,
                width: '100%',
                paddingLeft: 2
              }}>
              <Box
                width="100%"
                sx={{ overflow: 'hidden' }}>
                <OnlineOperatorList
                  onlineOperators={onlineOperators}
                />
              </Box>
              <Stack direction="row" alignItems="center" spacing={2} sx={{ width: 'fit-content' }}>
                <StatusPicker />
                <LangPicker
                  color={theme.palette.gray['230']}
                  simple
                />

                <Tooltip title={t('appBarSMS')}>
                  <Badge
                    badgeContent={notifications?.filter((notification)=> notification?.smsId !== undefined).length}
                    color="error">
                    <IconButton
                      onClick={() => {
                        navigate('/console', {
                          state: {
                            module: 'dashboard',
                            tab: 'sms',
                          },
                        });
                      }}>
                      <NotificationsIcon sx={{ color: theme.palette.gray['230'] }} />
                    </IconButton>
                  </Badge>
                </Tooltip>

                <Tooltip title={t('appBarMessages')}>
                  <Badge
                    badgeContent={notifications?.filter((notification)=> notification?.messageId !== undefined).length}
                    color="error">
                    <IconButton
                      onClick={() => {
                        navigate('/console', {
                          state: {
                            module: 'dashboard',
                            tab: 'msg',
                          },
                        });
                      }}>
                      <MailIcon sx={{ color: theme.palette.gray['230'] }} />
                    </IconButton>
                  </Badge>
                </Tooltip>

                <Button
                  sx={{ width: 'max-content' }}
                  onClick={(event) => {
                    setMenuAnchor(event.currentTarget);
                    setMenuState(true);
                  }}>
                  <Avatar
                    alt={firstname}
                    img={getImageSource(avatar)}
                    sx={{
                      width: 60,
                      height: 60,
                      fontSize: '40px',
                    }}
                  />
                  <Typography
                    color={theme.palette.gray['230']}
                    sx={{ marginLeft: '10px', whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
                    {(firstname + ' ' + lastname).toLowerCase()}
                  </Typography>
                </Button>
                <UserWayAccessibilityMenu color="gray" height={30} />
                <Menu
                  anchorEl={menuAnchor}
                  open={menuState}
                  onClose={() => {
                    setMenuState(false);
                  }}>
                  <MenuItem
                    onClick={() => {
                      navigate('/console', {
                        state: {
                          module: 'settings',
                        },
                      });
                      setMenuState(false);
                    }}>
                    {t('consoleDropdownProfile')}
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      logout();
                    }}>
                    {t('consoleDropdownLogout')}
                  </MenuItem>
                  <MenuItem>
                    {t('appVersion')}: {packageData.version}
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>

            <Divider sx={{ marginBottom: 2 }} />

            {/* Main content area */}
            <Box
              id="mainbox"
              sx={{
                width: '100%',
                // height: '90%',
                paddingLeft: 2
              }}>
              <Box
                sx={{
                  maxHeight: location.state?.module !== 'dashboard' ? 0 : 'unset',
                  overflow: location.state?.module !== 'dashboard' ? 'hidden' : 'unset',
                }}>
                {location.state?.module === 'dashboard' && (<Suspense fallback={<></>}><Dashboard
                  notifications={notifications}
                  setNotifications={setNotifications}                  
                  onlineUsers={onlineUsers}
                  onlineOperators={onlineOperators}
                /></Suspense> )}
              </Box>
              <Box
                sx={{
                  maxHeight: location.state?.module !== 'conversations' ? 0 : 'unset',
                  overflow: location.state?.module !== 'conversations' ? 'hidden' : 'unset',
                }}>
                {location.state?.module === 'conversations' && (
                  <Suspense fallback={<></>}>
                    <ConsoleConversationWrapper
                      notifications={notifications}
                      setNotifications={setNotifications}
                      onlineUsers={onlineUsers}
                      onlineOperators={onlineOperators}
                    />
                  </Suspense>
                )}
              </Box>
              <Box
                sx={{
                  maxHeight: location.state?.module !== 'settings' ? 0 : 'unset',
                  overflow: location.state?.module !== 'settings' ? 'hidden' : 'unset',
                }}>
                {location.state?.module === 'settings' && (<Suspense fallback={<></>}><ProfileSettings /></Suspense>)}
              </Box>
              {/* Logic to show the Monitoring and Admin panels if the user is an Admin. */}
              {accessLevel === 'Admin' && (
                <div id="monitoring-admin-panel">
                  <AdminBreadcrumbNav baseModuleName={sectionNameTranslationKeys['Admin']} enableClicks={false} />
                  <Box
                    sx={{
                      maxHeight: location.state?.module !== 'monitoring' ? 0 : 'unset',
                      overflow: location.state?.module !== 'monitoring' ? 'hidden' : 'unset',
                    }}
                  >
                    {location.state?.module === 'monitoring' && (
                      <Suspense fallback={<></>}>
                        <Monitoring />
                      </Suspense>
                    )}
                  </Box>
                  <Box
                    sx={{
                      maxHeight: !location.state?.module.split('/')[0] === sectionNameTranslationKeys['Admin'] ? 0 : 'unset',
                      overflow: !location.state?.module.split('/')[0] === sectionNameTranslationKeys['Admin'] ? 'hidden' : 'unset',
                    }}
                  >
                    {location.state?.module.split('/')[0] === sectionNameTranslationKeys['Admin'] && (
                      <Suspense fallback={<></>}>
                        <Admin selectedAI={selectedAI} setSelectedAI={setSelectedAI} />
                      </Suspense>
                    )}
                  </Box>
                </div>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
    </RootContext.Provider>
    )}  </>
  );
};

export default memo(ConsoleWrapperComponent);
