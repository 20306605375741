import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { PropsWithChildren, useContext, useState } from 'react';

import { getUserConsent, getUserConsentIntro } from '../Storage/userStorage';
import { Styles } from '../Styles/EmojiSelectorStyles';
import eventBus from '../Common/eventBus';
import { IFooterContext, FooterContext } from '@contexts/FooterContext';
import SentimentEmoji from '@components/SentimentEmoji';
import EmojiPickerReactSkeleton from './Skeletons/EmojiPickerReactSkeleton';
import { IEmojiPickerProps } from 'emoji-picker-react';

import useLazyComponent from '@common/hooks/useLazyComponent';

// jsx chunks - separate component preferred

const EmojiSelector: React.FC<PropsWithChildren<any>> = (props) => {
  // external utils like useTranslation, useNavigation

  // react state managment - context, state, ref
  const footerContext = useContext<IFooterContext>(FooterContext);
  const [anchorEl, setAnchorEl] = useState(null);

  // derrived state, useMemo

  // functions / useCallbacks
  const handleEmojiClose = () => setAnchorEl(null);
  const handleEmojiClick = (event) => {
    // show consent notification if needed
    if (getUserConsentIntro() !== 'true' && props.operatorData === undefined) {
      return eventBus.dispatch('showConsentIntro');
    }
    if (getUserConsent() !== 'true' && props.operatorData === undefined) {
      return eventBus.dispatch('showConsentContent');
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // useEffects
  // lazy components
  const EmojiPickerProps: IEmojiPickerProps = {
    onEmojiClick: props.handleEmoji,
    disableSearchBar: true
  }
  const EmojiPicker = useLazyComponent(
    import('emoji-picker-react'), 
    <EmojiPickerReactSkeleton />, 
    `emoji-picker-react in EmojiSelector: ${JSON.stringify(EmojiPickerProps)}`
  )

  return (
    <Styles>
      <IconButton
        className="ESIconButton"
        aria-controls={anchorEl ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={anchorEl ? 'true' : undefined}
        onClick={handleEmojiClick}>
        {anchorEl 
          ? <HighlightOffOutlinedIcon  className='ESColor' /> 
          : <SentimentEmoji sentiment={footerContext.sentimentPreview} />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl === null ? false : true}
        onClose={handleEmojiClose}
        keepMounted={true}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className='mui-transparent-background'
        style={{
          zIndex: '1050',
        }}>
        <MenuItem style={{ background: 'none' }}>
          {anchorEl && (
            <EmojiPicker {...EmojiPickerProps} />
          )}
        </MenuItem>
      </Menu>
    </Styles>
  );
};

export default EmojiSelector;
