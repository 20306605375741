import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import Styles from '@styles/AnalyzingAngel.module.css';
import tStyles from '@styles/Typography.module.css';
import Allign from '@styles/Allign';
import MessageTextStyle from '@styles/MessageTextStyle';
import Sanitized from '@components/Sanitized';
import BaseSkeletonStyles from '@styles/Skeletons/Skeletons.module.css';

const AnalyzingAngelFallback = () => {
  return <div className={BaseSkeletonStyles.loader + ' ' + Styles.fallback} />;
};

/**
 * The purpose of this component is to render the chat bubble that is displayed
 * to the user(s) when Angel is analyzing (either from an AI event or an
 * operator with the nickname 'AngelAi' typing)
 */
export default function AnalyzingAngel() {
  const { t } = useTranslation();
  const [gif, setGif] = useState<JSX.Element | null>(null);
  const gifIsLoaded = gif !== null;

  // This useEffect loads the gif asynchronously so we can detect when the
  // image is loading and display a fallback during that time
  useEffect(() => {
    const MeditatingMorgan = process.env.REACT_APP_MORGAN_CDN + '/Images/MorganMeditatingV2.gif';
    const img = new Image();
    img.src = MeditatingMorgan;
    img.onload = () => {
      setGif(<img src={MeditatingMorgan} alt="meditating angel" className={Styles.gifImg} />);
    };
  }, []);

  // If the gif is stil loading, display the fallback component
  return !gifIsLoaded ? (
    <AnalyzingAngelFallback />
  ) : (
    // Split into two parts: the message sender on top and the message bubble below
    <div
      className={Styles.outerWrapper}
    >
      {/* Message Sender */}
      <Sanitized
        className={[
          tStyles.base,
          tStyles.cMessageAuthor,
          tStyles.fwBold,
          tStyles.mbSmallest,
          tStyles.ttCapitalize,
          tStyles.alignByCurrentUser,
        ].join(' ')}
        html={`<span class="sr-only">${t('sentBy')}:</span> AngelAi`}
        visible={true}
        tag="p"
      />
      {/* Message Bubble */}
      <Allign>
        <ChatBubbleStyle className={Styles.chatBubble}>
          {/* Gif */}
          <div className={Styles.gifWrapper}>{gif}</div>
          {/* Message Text */}
          <div className={Styles.messageWrapper}>
            <MessageTextStyle>{t('morganIsAnalyzing')}</MessageTextStyle>
          </div>
        </ChatBubbleStyle>
      </Allign>
    </div>
  );
}
